import React, { useState, useEffect } from 'react'
import { Button, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import "react-datepicker/dist/react-datepicker.css";
import { userService } from '../services';
import Loader from './common/Loader'
import Header from './common/Header'
import Footer from './common/Footer'
import { config } from '../config/config';
import Swal from 'sweetalert2';


const Subscriptions = () => {

    const [subscriptionDetail, setSubscriptionDetail] = useState({})
    const [cancelId, setCancelId] = useState('')
    let id = localStorage.getItem('user_id');
    useEffect(() => {
        subscriptionDetails()
    }, []);

    const subscriptionDetails = () => {
        if (id) {
            userService.userSubsciptions(id).then(response => {
                let status = response.data.status;
                if (status == 200) {
                    const temp = response.data.data
                    setSubscriptionDetail(temp)


                }

            }).catch((error) => {
            });

        }
    }

    const cancelSub = (index) => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'You want to cancel this subscription.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, cancel it!'
        }).then((result) => {
            if (result.isConfirmed) {
                let params = { order_id: subscriptionDetail[index]?.order }
                userService.cancelSubscription(params).then(res => {
                    if (res.status == 200) {
                        subscriptionDetails()
                    }
                })

            }
        });
    };

    return (
        <>
            <div className="container py-4 subcon">
                <div className="row">
                    {(subscriptionDetail.length > 0) ?
                        subscriptionDetail.map((item, index) => {
                            return (

                                <div className="col-md-6">
                                    <div
                                        key={item._id} className="subs_secondbox subscribed-product">
                                        <div className="sub-card">
                                            <h5 style={{ fontSize: "16px" }} className="mb-3">YOU HAVE SUBSCRIBED FOR <span>"{item?.subscription_type?.name}"</span></h5>
                                            <div className="product-box">
                                                <img
                                                    src={
                                                        require("../images/update/Ing-product.png").default
                                                    }
                                                    alt="img"
                                                />
                                            </div>

                                            <h4 style={{ fontSize: "19px" }} className="mb-3">{item?.product?.name}</h4>
                                            <h5 style={{ fontSize: "15px" }}>PRICE: ${item?.product?.price}</h5>


                                            <div className="cancelsubs_btn">
                                                <button onClick={(e) => cancelSub(index)} className="btn text-white mb-1">Cancel Subscription</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        }) :
                        <div className="subs_secondbox subscribe_list P-3 text-center px-0">
                            <h5 style={{ fontSize: "16px", marginTop: "20px" }} className="mb-3 px-5">YOU HAVE NOT SUBSCRIBED TO ANYTHING YET.</h5>
                        </div>
                    }
                </div>
            </div>



        </>
    )


}
export default Subscriptions;