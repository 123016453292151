import AWS from 'aws-sdk';
import { accessAwsDetail } from './config';
function configureAWS() {
  AWS.config.update({
    accessKeyId: accessAwsDetail.awsAccessKey,
    secretAccessKey: accessAwsDetail.awsSecretKey,
    region: accessAwsDetail.awsRegion,
  });
}

configureAWS();

const S3 = new AWS.S3();
async function uploadFileToS3(file,key) {
  const params = {
    Bucket: accessAwsDetail.awsBucketName,
    Key: `${key ? key :"profile"}/${Date.now()}_${file.name}`,
    Body: file,
    ACL: 'public-read'
  };

  try {
    const data = await S3.upload(params).promise();
    return data;
  } catch (err) {
  }
}

async function removeFileToS3(key) {
  const params = {
    Bucket: accessAwsDetail.awsBucketName,
    Key: `posts/${key}`,
    // Body: file,
    // ACL: 'public-read'
  };

  try {
    const data = await S3.deleteObject(params);
    return data;
  } catch (err) {
  }
}

async function uploadFilesToS3(files) {
  const uploadedFileLocations = [];
  try {
    // Use Promise.all to upload all files concurrently
    const uploadPromises = files.map(async (file) => {
      const params = {
        Bucket: accessAwsDetail.awsBucketName,
        Key: `posts/${Date.now()}_${file.name}`,
        Body: file,
        ACL: 'public-read'
      };

      const data = await S3.upload(params).promise();
      uploadedFileLocations.push(data.Location); // Store the uploaded file location
    });

    await Promise.all(uploadPromises); // Wait for all uploads to complete

    return uploadedFileLocations;
  } catch (err) {
  }
}

const generatePresignedURL = async (objectKey, expirationInSeconds = 3600) => {

  const params = {
    Bucket: accessAwsDetail.awsBucketName,
    Key: objectKey,
    Expires: expirationInSeconds,
  };

  return new Promise((resolve, reject) => {
    S3.getSignedUrl('getObject', params, (error, url) => {
      if (error) {
        reject(error);
      } else {
        resolve(url);
      }
    });
  });
};

export { S3, uploadFileToS3, uploadFilesToS3, generatePresignedURL, removeFileToS3 };
