import { io } from "socket.io-client";
var QuickBlox = require('quickblox/quickblox.min');

// OR to create many QB instances
var QuickBlox = require('quickblox/quickblox.min').QuickBlox;
export const QB = new QuickBlox();

export const APPLICATION_ID = "101838";
export const AUTH_KEY = "tFGdsJ4VmDyCsGE";
export const AUTH_SECRET = "QqT6snBzs7CY7UY";
export const ACCOUNT_KEY = "SF8Hs5CR1xeCWvTg7gDv";
// var CONFIG = {
//   // other settings
//   webrtc: {
//     disconnectTimeInterval: 30,
//   }

export const CONFIG = {
  debug: false,
  pingTimeout: 5,
  pingLocalhostTimeInterval: 5,
  webrtc: {
    answerTimeInterval: 30,
    dialingTimeInterval: 5,
    disconnectTimeInterval: 30,
    statsReportTimeInterval: false,
  },
}

// const socketUrl ="http://192.168.2.56:5001" 
const socketUrl = "https://big4health-api.ecomempire.in/socket.io"
// const socketUrl = "https://staging-api.big4healthapp.com"
// const socketUrl ="http://138.197.86.118:5001"

let token = localStorage.getItem("token")

const socket = io.connect(socketUrl, {
  query: { token }
});

socket.on("connect", () => {
  console.log("Socket is connected!");
});

socket.on("disconnect", () => {
  console.log("Socket is disconnected!");
});
export default socket;