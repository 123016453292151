import React, { useState, useEffect } from 'react'
import Paginate from './common/Pagination';
import { userService } from '../services';
import Loader from './common/Loader'
import { toast } from 'react-toastify';
import { config } from '../config/config'
import Header from './common/Header'
import Footer from './common/Footer'
import ShareOnSocialMedia from './common/ShareOnSocialMedia'
import Download from './common/Download';
import moment from "moment";
import { Button } from 'react-bootstrap';

const Blog = () => {

    const [pageNo, setPageNo] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [totalCount, setTotalCount] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [latestBlogs, setLatestBlogs] = useState([]);
    const [popularBlogs, setPopularBlogs] = useState([]);
    const [categoryList, setcategoryList] = useState()
    const [ID, setBlogID] = useState('');
    const [activeTab, setActiveTab] = useState('all'); // Set an initial active tab

    const handleTabClick = (tab) => {
        setActiveTab(tab ? tab : "all");
        getBlogs(0, tab);
    };



    useEffect(() => {
        getBlogs(0, activeTab == "all" ? "" : activeTab);
    }, []);



    function getBlogs(page, activeTab) {
        setIsLoading(true);
        userService.getBlogs(page, activeTab).then((response) => {
            setIsLoading(false);
            if (response.data.status == 200) {
                let data = response.data
                setLatestBlogs(data.latestBlogs);
                setPopularBlogs(data.popularBlogs);
                setTotalCount(data.totalPages);
                setIsLoading(false);
            } else {
                setLatestBlogs([]);
                setPopularBlogs([]);
                setTotalCount(0);
                setIsLoading(false);
                toast.error("Some Error Occur");
            }
        }).catch((error) => {
            setIsLoading(false);
            setLatestBlogs([]);
            setPopularBlogs([]);
            setTotalCount(0);
        });
    }

    function handlePageChange(page) {
        setPageNo(page);
        getBlogs(page);
    }




    return (
        <>
            <Header />
            {isLoading && <Loader />}
            <section className="blog_section py-5">

                <div className="container">
                    <div className="back-btn-sec mb-3">
                        <Button className="btn back-btn" href="/"><i className="fa fa-angle-left mr-2"></i>Back to Home</Button>
                    </div>
                    <div className="row">
                        <div className="col-md-12 mb-4">
                            <div className="blog_head">
                                <div className="blog-heading">
                                    <h5 className="latest">Latest Blogs</h5>
                                </div>
                                <ShareOnSocialMedia url={window.location.href} text="Big4Health - Blog" />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="popular">
                                <h4>Popular Blogs</h4>
                                <ul className="p-0 mt-4">
                                    {popularBlogs.length > 0 ? popularBlogs.map((blog, i) => {
                                        const momentJsDateFormat = "MMM DD, YYYY";
                                        return (<li >
                                            <a href={"/blog_details?id=" + blog._id}>
                                                <span>{(i < 10 ? '0' : '') + (i + 1)}</span>
                                                <div><h6>{blog.title}</h6></div></a>
                                        </li>)
                                    }) :
                                        <li className="no-blog">No Popular Blogs Found</li>
                                    }
                                </ul>
                            </div>
                            <div className="blog-category-section">
                                <button
                                    className={`cat-list mb-3 ${activeTab == "all" ? "active" : ""}`}
                                    onClick={() => handleTabClick("")}
                                >
                                    All
                                </button>
                                <button
                                    className={`cat-list mb-3 ${activeTab == "Diabetes" ? "active" : ""}`}
                                    onClick={() => handleTabClick("Diabetes")}
                                >
                                    Diabetes
                                </button>
                                <button
                                    className={`cat-list mb-3 ${activeTab == "Hypertension" ? "active" : ""}`}
                                    onClick={() => handleTabClick("Hypertension")}
                                >
                                    Hypertension
                                </button>
                                <button
                                    className={`cat-list mb-3 ${activeTab == "High Cholesterol" ? "active" : ""}`}
                                    onClick={() => handleTabClick("High Cholesterol")}
                                >
                                    High Cholesterol
                                </button>
                                <button
                                    className={`cat-list mb-3 ${activeTab == "Weight Management" ? "active" : ""}`}
                                    onClick={() => handleTabClick("Weight Management")}
                                >
                                    Weight Management
                                </button>
                            </div>
                        </div>

                        <div className="col-lg-8">
                            <div className="row">
                                {latestBlogs.length > 0 ? latestBlogs.map((blog) => {
                                    return (<div className="col-lg-6 mb-4">
                                        <div className='blogs-content-area'>
                                            <a href={"/blog_details?id=" + blog._id}>

                                                <div className="img_row shadow-sm blog-thumb-image">
                                                    <img src={blog.image} alt="" />
                                                </div>
                                                <p className="blog_paragraph mt-2">{blog?.blogCategory}</p>
                                                <h3 className="blog_title">{blog.title}</h3>
                                                <p className="blog_paragraph mt-2">{moment(blog?.createdOn)?.format("MMM DD, YYYY")} {"-"} by {blog?.author}</p>

                                                <p className="blog_paragraph mt-2">{blog.short_description}</p>
                                            </a>
                                        </div>
                                    </div>)
                                }) : <p className="" >  <div className="no-data-box">
                                    <img src={require("../images/update/no-data.png").default} alt="img" className="mr-2" />
                                    <h3>No Blogs found</h3>
                                </div></p>}
                            </div>
                            <div style={{ width: '100%' }}>
                                <Paginate count={totalCount} activePage={pageNo} handlePageChange={(page) => handlePageChange(page)} perPageEntries={perPage} />
                            </div>
                        </div>

                    </div>
                </div>
            </section>
          
            <Download />
            <Footer />
        </>
    )
}
export default Blog;