import React, { useEffect, useState } from 'react';
import Header from './common/Header';
import Footer from './common/Footer';
import { userService } from '../services';

import Loader from './common/Loader'
import { config } from '../config/config';



const Offer = () => {

    const [isLoading, setIsLoading] = useState(false);
    const [offers, setOffers] = useState([]);

    useEffect(() => {
        getOffers();
    }, [])

    function getOffers() {
        setIsLoading(true);
        userService.getOffers().then((res) => {
            if (res.status === 200) {
                setIsLoading(false);
                setOffers(res.data.offers);
            }
        }).catch((err) => {

        });
    }

    function goToOffer(id) {
        window.location.href = "/ingredient";
    }

    return (
        <>
            <Header />
            {isLoading && <Loader />}
            <section className="offer_sction py-4">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-5 col-md-12">
                            <div><h5>Offers</h5></div>
                        </div>
                    </div>
                    <div className="row mt-4 justify-content-center">
                        {offers && offers?.length > 0 ?
                            offers.map((offer) => {
                                { console.log("offer=====", offer) }
                                return (
                                    <div className="col-lg-5 col-md-12 mb-3" onClick={() => goToOffer(offer?.product_id?._id)}>
                                        <div className='offer-box-area'>
                                            <img src={offer?.image ? offer?.image : ''} alt="img" height="250px" />
                                        </div>
                                        <div className='offer-content-box-area'>
                                        <p className='mb-0'>Product Name: <span>{offer?.product_id?.name}</span></p>
                                        <p className='mb-0'>Offer: <span>{offer?.off_percent}% Off</span></p>
                                        {offer?.off_percent && <h6 className='mb-0'>The offer is Already appiled to the product.</h6>}
                                        </div>
                                    
                                    </div>
                                )
                            })
                            :
                            <div className="profile-right-side no-data-profile no-upper-main-data mb-5">
                                <div className="no-data-box">
                                    <img src={require("../images/update/no-data.png").default} alt="img" className="mr-2" />
                                    <h3>No data found</h3>
                                </div>
                            </div>

                        }




                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default Offer;