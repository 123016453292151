export const countryLanguageMapping = {
    // United States -> English
    US: 'en',
    // Canada -> English/French
    CA: 'en',
    // France -> French
    FR: 'fr',
    // Germany -> German
    DE: 'de',
    // United Kingdom -> English
    GB: 'en',
    // Spain -> Spanish
    ES: 'es',
    // Italy -> Italian
    IT: 'it',
    // Japan -> Japanese
    JP: 'ja',
    // China -> Chinese
    CN: 'zh',
    // South Korea -> Korean
    KR: 'ko',
    // Brazil -> Portuguese
    BR: 'pt',
    // India -> Hindi/English
    IN: 'en',
    // Australia -> English
    AU: 'en',
    // Russia -> Russian
    RU: 'ru',
    // Mexico -> Spanish
    MX: 'es',
    // South Africa -> English/Afrikaans
    ZA: 'en',
    // Argentina -> Spanish
    AR: 'es',
    // Saudi Arabia -> Arabic
    SA: 'ar',
    // United Arab Emirates -> Arabic
    AE: 'ar',
    // Singapore -> English/Malay/Chinese/Tamil
    SG: 'en',
    // Malaysia -> Malay/English
    MY: 'ms',
    // Thailand -> Thai
    TH: 'th',
    // Sweden -> Swedish
    SE: 'sv',
    // Norway -> Norwegian
    NO: 'no',
    // Denmark -> Danish
    DK: 'da',
    // Netherlands -> Dutch
    NL: 'nl',
    // Belgium -> Dutch/French
    BE: 'nl',
    // Switzerland -> German/French/Italian
    CH: 'de',
    // Austria -> German
    AT: 'de',
    // Greece -> Greek
    GR: 'el',
    // Turkey -> Turkish
    TR: 'tr',
    // Egypt -> Arabic
    EG: 'ar',
    // Nigeria -> English
    NG: 'en',
    // Kenya -> Swahili/English
    KE: 'en',
    // South Korea -> Korean
    KR: 'ko',
    // Japan -> Japanese
    JP: 'ja',
    // China -> Chinese
    CN: 'zh',
    // Taiwan -> Chinese
    TW: 'zh',
    // Hong Kong -> Chinese
    HK: 'zh',
    // Macau -> Chinese
    MO: 'zh',
    // Indonesia -> Indonesian
    ID: 'id',
    // Vietnam -> Vietnamese
    VN: 'vi',
    // Philippines -> Filipino/English
    PH: 'en',
    // New Zealand -> English
    NZ: 'en',
    // Israel -> Hebrew
    IL: 'he',
    // Saudi Arabia -> Arabic
    SA: 'ar',
    // Qatar -> Arabic
    QA: 'ar',
    // United Arab Emirates -> Arabic
    AE: 'ar',
    // Kuwait -> Arabic
    KW: 'ar',
    // Oman -> Arabic
    OM: 'ar',
    // Bahrain -> Arabic
    BH: 'ar',
    // Jordan -> Arabic
    JO: 'ar',
    // Lebanon -> Arabic
    LB: 'ar',
    // Iraq -> Arabic
    IQ: 'ar',
    // Iran -> Persian
    IR: 'fa',
    // Pakistan -> Urdu/English
    PK: 'en',
    // Bangladesh -> Bengali
    BD: 'bn',
    // Sri Lanka -> Sinhala/Tamil/English
    LK: 'en',
    // Nepal -> Nepali
    NP: 'ne',
    // Bhutan -> Dzongkha
    BT: 'dz',
    // Myanmar (Burma) -> Burmese
    MM: 'my',
    // Cambodia -> Khmer
    KH: 'km',
    // Laos -> Lao
    LA: 'lo',
    // Philippines -> Filipino/English
    PH: 'en',
    // Singapore -> English/Malay/Chinese/Tamil
    SG: 'en',
    // Malaysia -> Malay/English
    MY: 'ms',
    // Brunei -> Malay
    BN: 'ms',
    // Papua New Guinea -> English
    PG: 'en',
    // Fiji -> English/Fijian/Hindi
    FJ: 'en',
    // Solomon Islands -> English
    SB: 'en',
    // Vanuatu -> Bislama/English/French
    VU: 'en',
    // New Caledonia -> French
    NC: 'fr',
    // French Polynesia -> French
    PF: 'fr',
    // Wallis and Futuna -> French
    WF: 'fr',
    // Fiji -> English/Fijian/Hindi
    FJ: 'en',
    // Solomon Islands -> English
    SB: 'en',
    // Vanuatu -> Bislama/English/French
    VU: 'en',
    // New Caledonia -> French
    NC: 'fr',
    // French Polynesia -> French
    PF: 'fr',
    // Wallis and Futuna -> French
    WF: 'fr',
    // Samoa -> Samoan/English
    WS: 'en',
    // Tonga -> Tongan/English
    TO: 'en',
    // Tuvalu -> Tuvaluan/English
    TV: 'en',
    // Kiribati -> English/Gilbertese
    KI: 'en',
    // Marshall Islands -> English/Marshallese
    MH: 'en',
    // Federated States of Micronesia -> English
    FM: 'en',
    // Palau -> English/Palauan
    PW: 'en',
    // Nauru -> Nauruan/English
    NR: 'en',
    // Cook Islands -> English/Rarotongan
    CK: 'en',
    // Niue -> Niuean/English
    NU: 'en',
    // Tokelau -> Tokelauan/English
    TK: 'en',
    // American Samoa -> Samoan/English
    AS: 'en',
    // Guam -> English/Chamorro
    GU: 'en',
    // Northern Mariana Islands -> English/Chamorro
    MP: 'en',
    // Puerto Rico -> Spanish/English
    PR: 'es',
    // U.S. Virgin Islands -> English
    VI: 'en',
    // British Virgin Islands -> English
    VG: 'en',
    // Bermuda -> English
    BM: 'en',
    // Cayman Islands -> English
    KY: 'en',
    // Bahamas -> English
    BS: 'en',
    // Jamaica -> English
    JM: 'en',
    // Haiti -> Haitian Creole/French
    HT: 'ht',
    // Dominican Republic -> Spanish
    DO: 'es',
    // Cuba -> Spanish
    CU: 'es',
    // Jamaica -> English
    JM: 'en',
    // Haiti -> Haitian Creole/French
    HT: 'ht',
    // Dominican Republic -> Spanish
    DO: 'es',
    // Cuba -> Spanish
    CU: 'es',
    // Puerto Rico -> Spanish/English
    PR: 'es',
    // U.S. Virgin Islands -> English
    VI: 'en',
    // British Virgin Islands -> English
    VG: 'en',
    // Bermuda -> English
    BM: 'en',
    // Cayman Islands -> English
    KY: 'en',
    // Bahamas -> English
    BS: 'en',
    // Turks and Caicos Islands -> English
    TC: 'en',
    // Aruba -> Dutch/Papiamento
    AW: 'nl',
    // Curaçao -> Dutch/Papiamento
    CW: 'nl',
    // Bonaire, Sint Eustatius, and Saba -> Dutch
    BQ: 'nl',
    // Sint Maarten -> Dutch/English
    SX: 'nl',
    // Sint Maarten -> Dutch/English
    SX: 'nl',
    // Montserrat -> English
    MS: 'en',
    // Anguilla -> English
    AI: 'en',
    // Saint Kitts and Nevis -> English
    KN: 'en',
    // Antigua and Barbuda -> English
    AG: 'en',
    // Saint Lucia -> English
    LC: 'en',
    // Saint Vincent and the Grenadines -> English
    VC: 'en',
    // Grenada -> English
    GD: 'en',
    // Barbados -> English
    BB: 'en',
    // Saint Kitts and Nevis -> English
    KN: 'en',
    // Antigua and Barbuda -> English
    AG: 'en',
    // Saint Lucia -> English
    LC: 'en',
    // Saint Vincent and the Grenadines -> English
    VC: 'en',
    // Grenada -> English
    GD: 'en',
    // Barbados -> English
    BB: 'en',
    // Dominica -> English
    DM: 'en',
    // Martinique -> French
    MQ: 'fr',
    // Guadeloupe -> French
    GP: 'fr',
    // Saint Martin -> French
    MF: 'fr',
    // Saint Barthélemy -> French
    BL: 'fr',
    // Trinidad and Tobago -> English
    TT: 'en',
    // Guyana -> English
    GY: 'en',
    // Suriname -> Dutch
    SR: 'nl',
    // French Guiana -> French
    GF: 'fr',
    // Belize -> English
    BZ: 'en',
    // Guatemala -> Spanish
    GT: 'es',
    // Honduras -> Spanish
    HN: 'es',
    // El Salvador -> Spanish
    SV: 'es',
    // Nicaragua -> Spanish
    NI: 'es',
    // Costa Rica -> Spanish
    CR: 'es',
    // Panama -> Spanish
    PA: 'es',
    // Colombia -> Spanish
    CO: 'es',
    // Venezuela -> Spanish
    VE: 'es',
    // Ecuador -> Spanish
    EC: 'es',
    // Peru -> Spanish
    PE: 'es',
    // Bolivia -> Spanish
    BO: 'es',
    // Paraguay -> Spanish/Guarani
    PY: 'es',
    // Uruguay -> Spanish
    UY: 'es',
    // Chile -> Spanish
    CL: 'es',
    // Maldives -> Dhivehi
    MV: 'dv',
    // Seychelles -> English/French
    SC: 'en',
    // Comoros -> Comorian/French/Arabic
    KM: 'fr',
    // Mauritius -> English/French
    MU: 'en',
    // Madagascar -> Malagasy/French
    MG: 'mg',
    // Mayotte -> French
    YT: 'fr',
    // Réunion -> French
    RE: 'fr',
    // Mozambique -> Portuguese
    MZ: 'pt',
    // Angola -> Portuguese
    AO: 'pt',
    // Zimbabwe -> English
    ZW: 'en',
    // Zambia -> English
    ZM: 'en',
    // Malawi -> English/Chichewa
    MW: 'en',
    // Tanzania -> Swahili/English
    TZ: 'en',
    // Uganda -> English/Swahili
    UG: 'en',
    // Rwanda -> Kinyarwanda/French/English
    RW: 'rw',
    // Burundi -> Kirundi/French/English
    BI: 'fr',
    // Seychelles -> English/French
    SC: 'en',
    // Comoros -> Comorian/French/Arabic
    KM: 'fr',
    // Mauritius -> English/French
    MU: 'en',
    // Madagascar -> Malagasy/French
    MG: 'mg',
    // Mayotte -> French
    YT: 'fr',
    // Réunion -> French
    RE: 'fr',
    // Mozambique -> Portuguese
    MZ: 'pt',
    // Angola -> Portuguese
    AO: 'pt',
    // Zimbabwe -> English
    ZW: 'en',
    // Zambia -> English
    ZM: 'en',
    // Malawi -> English/Chichewa
    MW: 'en',
    // Tanzania -> Swahili/English
    TZ: 'en',
    // Uganda -> English/Swahili
    UG: 'en',
    // Rwanda -> Kinyarwanda/French/English
    RW: 'rw',
    // Burundi -> Kirundi/French/English
    BI: 'fr',
    // Seychelles -> English/French
    SC: 'en',
    // Comoros -> Comorian/French/Arabic
    KM: 'fr',
    // Mauritius -> English/French
    MU: 'en',
    // Madagascar -> Malagasy/French
    MG: 'mg',
    // Mayotte -> French
    YT: 'fr',
    // Réunion -> French
    RE: 'fr',
    // Mozambique -> Portuguese
    MZ: 'pt',
    // Angola -> Portuguese
    AO: 'pt',
    // Zimbabwe -> English
    ZW: 'en',
    // Zambia -> English
    ZM: 'en',
    // Malawi -> English/Chichewa
    MW: 'en',
    // Tanzania -> Swahili/English
    TZ: 'en',
    // Uganda -> English/Swahili
    UG: 'en',
    // Rwanda -> Kinyarwanda/French/English
    RW: 'rw',
    // Burundi -> Kirundi/French/English
    BI: 'fr',
};


