import React, { useEffect } from 'react';
import Header from './common/Header'
import Footer from './common/Footer'
import Download from './common/Download';
import { Col, Row } from 'react-bootstrap';

const About = () => {

    useEffect(() =>{
        localStorage.removeItem("selectedTabAppointment");
        localStorage.removeItem("selectedTab");
    },[])

    return (
        <>
            <Header />
            <section className="home-banner about-top-section">
                <div className="container">
                    <Row>
                        
                        <Col md={12} lg={12}>
                            <div className="banner-text pt-5">
                                <h1>Revolutionizing Global Healthcare Through Lifestyle Medicine</h1>
                                <p>In February 2014, a pioneering physician, revered as a "Champion of Health and Wellness," initiated a healthcare revolution. Over four months, 3,075 individuals and healthcare professionals were screened, resulting in a paradigm shift in the approach to heart disease, high blood pressure, diabetes, and cancer. Lifestyle medicine became the cornerstone of change, transforming our dietary habits, prevention of tobacco use and excessive alcohol consumption, exercise routines, stress management, and social interactions. Under this visionary's leadership, BIG4 Health engaged 200 mental health clinics in crafting physical health policies, securing increased funding for wellness initiatives, and successfully implementing wellness committees in community health facilities.</p>
                            </div>
                        </Col>
                    </Row>

                </div>
            </section>
            <section className="global-solution-section about-second-section">
                <div className="container">
                    <div className="global-solution-text">
                        <h5>Community Involvement and Achievements</h5>
                        <p>At BIG4 Health, our co-founder has been deeply committed to fostering health and wellness in diverse communities and states, tackling the significant health challenges that affect us all. This dedication extends to participation in radio and television talk shows, as well as meaningful collaborations with faith-based organizations. In 2019, our co-founder was honored with the prestigious American College of Healthcare Executive Regent Award, acknowledging his outstanding contributions to the field. Additionally, he received a state Best Award for Innovation and Quality Improvement, recognizing the transformative impact of lifestyle medicine-based initiatives on healthcare advancement. These recognitions underscore our commitment to creating positive change in the healthcare landscape.</p>
                    </div>
                </div>
            </section>
            <section className="global-solution-section about-third-section">
                <div className="container">
                    <div className="global-solution-text">
                        <h5>A Remarkable Evolution and Vision</h5>
                        <p>Today, BIG4 Health has evolved into a global healthcare ecosystem, revolutionizing the conventional healthcare approach. It serves as a beacon of hope for those seeking solutions to unmet medical needs, empowering individuals through lifestyle medicine to take charge of their well-being. The company envisions a future where the application of lifestyle medicine principles will significantly contribute to eradicating the BIG4—Diabetes, Hypertension, Cholesterol, and Obesity—globally.</p>
                    </div>
                </div>
            </section>
            <section className="global-solution-section about-fourth-section">
                <div className="container">
                    <div className="global-solution-text">
                        <h5>Collaborative Global Initiatives and Impact</h5>
                        <p>Collaborating with leading hospitals, health insurance companies, governments, and public and private businesses worldwide, BIG4 Health aims to save time and resources while striving to prevent and reverse chronic diseases. Through the application of lifestyle medicine principles, quantifiable positive impacts have been observed, including improved treatment adherence, reduced health risks, and enhanced overall health outcomes.</p>
                    </div>
                </div>
            </section>
            <section className="global-solution-section about-fiveth-section">
                <div className="container">
                    <div className="global-solution-text">
                        <h5>Our new world</h5>
                        <p>We believe that "Life is short, so we focus on preventing and reversing chronic diseases." Beyond treating ailments, BIG4 Health aspires to create a world where chronic diseases are minimized, if not eradicated, through lifestyle-based interventions and policies. The company envisions a future where lifestyle medicine plays a pivotal role in reshaping the narrative of human well-being by eradicating the BIG4 worldwide.</p>
                    </div>
                </div>
            </section>
          
            <Footer />
        </>
    )
}

export default About;