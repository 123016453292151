import React from 'react';
import Header from './common/Header'
import Footer from './common/Footer'

const ReturnPolicy = () => {
    return (
        <>
            <Header />
            <section className="py-5">
                <div className="container">
                    <b>RETURN AND REFUND POLICY</b>
                    <br />
                    Last updated January 18, 2022
                    <br />
                    ________________________________________
                    <br />
                    <br />
                    We stand by our products. If you are not completely satisfied with your purchase for any reason, you may return it to us for a store credit or an exchange.
                    <br />
                    <br />

                    <b>RETURNS</b>
                    <br />
                    You may return the most eligible items up to 7 days from the date of purchase date. All returned items must be in new and unused condition.
                    Perishable goods such as food or magazines cannot be returned.
                    Additional non-returnable items:
                    Gift cards
                    Downloadable software products
                    Discounts
                    Some health and personal care items
                    <br />
                    <br />


                    <b>RETURN PROCESS</b>
                    <br />
                    To return an item, please email customer service at info@big4healthapp.com to obtain a Return Merchandise Authorization (RMA) number. After receiving a RMA number, place the item securely in its original packaging and include your proof of purchase, and mail your return the following address:
                    CollConnect Attn:
                    Returns RMA
                    # 416 6th Street SW
                    Willmar MN 56201

                    <br />
                    <br />

                    Please note, you will be responsible for all return shipping charges. We strongly recommend that you use a trackable method to mail your return.
                    <br />
                    <br />


                    <b>REFUNDS</b>
                    <br />
                    After receiving your return and inspecting the condition of your item, we will process your store credit or an exchange. Please allow at least 15 days to process your returns from the day we receive your item. Refunds may take 1-2 billing cycles to appear on your wallet (account)
                    <br />
                    <br />


                    Please Note
                    <br />
                    ● A 15 % restocking fee will be charged for all returns
                    <br />

                    ● Shipping is not refundable on items returned
                    <br />
                    ● ALL BIG4 HEALTH APP SALE ARE FINAL AND CANNOT BE REIMBURSED.
                    <br />

                </div>
            </section>
            <Footer />
        </>
    )
}

export default ReturnPolicy;