import { Button, Dropdown, DropdownButton, Form, InputGroup, Modal, Row, Tab, Tabs } from "react-bootstrap";
import { useEffect, useRef, useState } from 'react';
import Footer from "./common/Footer";
import Header from "./common/Header";
import { userService } from "../services";
import { config } from "../config/config";
import Swal from "sweetalert2";
import { useHistory, useLocation, useParams } from "react-router-dom/cjs/react-router-dom.min";
import MedUserData from "../pages/MedUserdata";
import { Swiper, SwiperSlide, Autoplay } from 'swiper/react';
import Pagination from "react-js-pagination";
import WorldUserdata from "../pages/WorldUserdata";
import { toast } from 'react-toastify'
import { use } from "i18next";
import Loader from "./common/Loader";
import Availability from "../pages/Availability";
import ScheduleListing from "./ScheduleListing";
import AppointmentListing from "./AppointmentListing";
import App from "../App";
import MyOrder from "../pages/MyOrder";
import Subscriptions from "./Subscriptions";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Skeleton from 'react-loading-skeleton';
import VideoCall from "../pages/VideoCall";


export const DATE_OPTIONS = {
    month: 'long',
    day: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
}

const ProfileUpdated = () => {
   
    const [isLoadingImage, setIsLoadingImage]=useState(true)
    const [addcardModel, setaddcardModel] = useState(false)
    const handleCloseAddCardModel = () => setaddcardModel(false);
    const [cardNo, setCardNo] = useState('');
    const [cardHolderName, setCardHolderName] = useState('');
    const [expiryDate, setExpiryDate] = useState('');
    const [cvv, setCvv] = useState('');
    const [cartId, setCartId] = useState('');
    const [selectedMenu, setSelectedMenu] = useState("profile")
    let editStatusRef = useRef(false)
    const [cards, setCards] = useState()
    const [isLoading, setIsLoading] = useState(false);
    const [cancellationReason, setCancellationReason] = useState("")
    const [isChecked, setIsChecked] = useState(false);
    const [editModal, setEditModal] = useState(false)
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const [temp, setTemp] = useState('aa')
    const [totalRecordSubscriber, setTotalSubscriberRecord] = useState()
    const [perPage, setPerPage] = useState()
    const [user, setUser] = useState(JSON.parse(window.localStorage.getItem('user')))
    const regExPositiveInt = /^\d+$/;
    const [values, setValues] = useState({ weightType: "kg", height: 0, glucose: 0, cholesterol: 0, bloodPressureMax: 0, bloodPressureMin: 0, weight: 0 })
    const heightOptions = ["4", "4'1", "4'2", "4'3", "4'4", "4'5", "4'6", "4'7", "4'8", "4'9", "4'10", "4'11", "5", "5'1", "5'2", "5'3", "5'4", "5'5", "5'6", "5'7", "5'8", "5'9", "5'10", "5'11", "6", "6'1", "6'2", "6'3", "6'4", "6'5", "6'6", "6'7", "6'8", "6'9", "6'10", "6'11", "7"]
    const [submitting, setSubmitting] = useState(false)
    const [isModified, setIsModified] = useState(false);
    var emailRegrex = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
    const [webnairdata, setWebnairData] = useState()
    const [userId, setUserId] = useState(localStorage?.getItem("user_id") ? localStorage?.getItem("user_id") : "");
    const [userDetail, setUserDetail] = useState("");
    const [medUserList, setMedUserList] = useState()
    const [userType, setUserType] = useState(localStorage?.getItem("userType") ? localStorage?.getItem("userType") : "");
    const [categoryList, setcategoryList] = useState()
    const [activeTab, setActiveTab] = useState(localStorage.getItem("profilewebnairType") || 'live');
    const [activeTabM, setActiveTabM] = useState("");
    const [key, setKey] = useState('home');
    var activeTabRef = useRef("")
    const [show, setShow] = useState(false);
    const handleClose = () => {
        setShow(false);
        window.location.href = "/profilenew";
    }
    const [email, setEmail] = useState("")
    const [totalpage, settotalpage] = useState();
    const [totalCount, setTotalcount] = useState()
    const [showed, setShowed] = useState(false);
    const handleClosed = () => setShowed(false);
    const [subscriber, setSubscriber] = useState("")
    const [page, setPage] = useState(1)
    const [pageW, setPageW] = useState(0)
    const [pageS, setPageS] = useState(0)
    const [pageSub, setPageSub] = useState(1)
    const [updatepassword, setupdatepassword] = useState({ "oldpassword": "", "newPassword": "" })
    const [subscriberdata, setSubscriberdata] = useState()
    const [profile, setProfile] = useState("yes")
    const [categoryStatus, setcategoryStatus] = useState("upcoming")
    const [pageAppointment, setpageAppointment] = useState(1)
    const [AppointmentData, setAppointmentData] = useState("")
    const [AppointmentCancel, setAppointmentCancel] = useState(false)
    const [categoryStatusSchedule, setcategoryStatusSchedule] = useState("pending")
    const [pageSchedule, setpageSchedule] = useState(1)
    const [scheduleData, setscheduleData] = useState("")
    const [rejectAppointment, setRejectAppointment] = useState(false)
    const [cardId, setCardId] = useState("")
    const urlLocation = useLocation();
    const queryParams = new URLSearchParams(urlLocation.search);
    const slug = queryParams.get('slug')
    const activeMenu = queryParams.get('activeMenu');
    const appointmentdata =queryParams.get('appointmentStatus')
    const scheduledata= queryParams.get('scheduleStatus')
    const [appointmentStatus, setAppointmentStatus]=useState(appointmentdata ? appointmentdata :"false")
    const [scheduleStatus, setScheduleStatus]=useState(scheduledata ?scheduledata :"false")
    let limit = 3;

    useEffect(() => {
        getProfileList()
        getCotagery()
        subscriberList(pageSub)
        getCards()
        if (selectedMenu == "profile") {
            getAppontmentList(pageAppointment, categoryStatus, limit ? limit : "")
            getMySchedule(pageSchedule, categoryStatusSchedule, limit ? limit : "")
        }
        if (!localStorage.getItem("token") || !localStorage.getItem("user_id")) {
            window.location.href = "/signin";
        }
    }, [])

    const handleCloseAppointmentCancel = () => setAppointmentCancel(false);
    const RejectedRef = useRef()
    const handleShow = (selectid) => {
        setAppointmentCancel(true);
        RejectedRef.current = selectid;
    }

    const selectedRejectRef = useRef()
    const handleRejectAppointment = (selectid) => {
        setRejectAppointment(true);
        selectedRejectRef.current = selectid;
    }

    const handleExpiryDate = (date) => {
        setExpiryDate(date);
    }

    // add card
    function validateForm() {
        if (!/^[0-9]{12,16}$/.test(cardNo)) {
            toast.error("Invalid Card No", {
                toastId: 'success_draft_1',
            });
        } else if (!/^[a-zA-Z-_ ]+$/.test(cardHolderName)) {
            toast.error("Invalid Card Holder Name", {
                toastId: 'success_draft_1',
            });
        } else if (!expiryDate) {
            toast.error("Please Select Expiry Date", {
                toastId: 'success_draft_1',
            });
        } else if (!/^[0-9]{3,4}$/.test(cvv)) {
            toast.error("Invalid CVV Number", {
                toastId: 'success_draft_1',
            });
        } else {
            setIsLoading(true);
            let params = { user: userId, card_number: cardNo, exp_month: expiryDate.getMonth() + 1, exp_year: expiryDate.getFullYear(), cvv: cvv, card_holder_name: cardHolderName }
            userService.addCard(params).then((response) => {
                setIsLoading();
                if (response.data.status == 200) {
                    handleClose();
                    setaddcardModel(false)
                    getCards();
                    toast.success("Card Added Successfully", {
                        toastId: 'success_draft_1',
                    });
                } else {
                    toast.error(response.data.message, {
                        toastId: 'success_draft_1',
                    });
                }
            }).catch((error) => {
                setIsLoading(false);
            });
        }
    }

    // get cards
    function getCards() {
        setIsLoading(true);
        userService.getCards().then((response) => {
            setIsLoading(false);
            if (response.data.status == 200) {
                setCards(response?.data?.data);
                if (response?.data?.data?.length > 0)
                    setCardId(response?.data?.data[0]?._id)
            }
            else {
                toast.error("Some Error Occur", {
                    toastId: 'success_draft_1',
                });
            }
        }).catch((error) => {
            setIsLoading(false);
        });
    }

    /// cancel
    function RejectAppointment(id, status, cancellationReason) {
        const params = {
            status: status,
            id: id,
            cancellationReason: cancellationReason
        };
        setIsLoading(true)

        userService.rejectAppointment(params)
            .then((res) => {
                if (res?.data?.status === 200) {
                    Swal.fire("Success", "Appointment Cancelled successfully.", "success").then((res) => {
                        window.location.reload()
                        getAppontmentList(pageAppointment, categoryStatus, limit)
                        setAppointmentCancel(false)
                        setRejectAppointment(false)

                    })
                    setIsLoading(false)
                }
            })
            .catch((error) => {
                setIsLoading(false)
            });
    }

    ///Accept
    function AcceptAppointment(id, status) {
        const params = {
            status: status,
            id: id
        };
        setIsLoading(true)
        userService.acceptAppointment(params)
            .then((res) => {
                if (res?.data?.status === 200) {
                    Swal.fire("Success", "Appointment Request accepted successfully.", "success").then((e) => {
                        getMySchedule(pageSchedule, categoryStatusSchedule, limit)
                        window.location.href = "/profilenew";
                    })
                    setIsLoading(false)
                }
            })
            .catch((error) => {
                setIsLoading(false)
            });
    }

    const [showModal, setShowModal] = useState(false);
    const [fullDescription, setFullDescription] = useState('');
    const fullDescriptionRef = useRef()

    /// Appointment
    function getAppontmentList(pageAppointment, categoryStatus, limit) {
        setIsLoading(true)
        userService.get_Appointment_list(pageAppointment - 1, categoryStatus, limit).then((res) => {
            if (res?.data?.status === 200) {
                setAppointmentData(res?.data?.data);
                setIsLoading(false)
            }
        })
            .catch((error) => {
                setIsLoading(false)
                Swal.fire("Warning",error?.response?.data,"warning").then((e) =>{
                    localStorage.clear()
                    window.location.href="/";
                })
            })
    }

    // MySchedule
    function getMySchedule(pageSchedule, categoryStatusSchedule, limit) {
        setIsLoading(true)
        userService.getScheduleList(pageSchedule - 1, categoryStatusSchedule, limit).then((res) => {
            if (res?.data?.status === 200) {
                window.scrollTo({ top: 0, behavior: 'smooth' });
                setscheduleData(res?.data?.data);
                setIsLoading(false)
            }
        })
            .catch((error) => {
                setIsLoading(false)
                Swal.fire("Warning",error?.response?.data,"warning").then((e) =>{
                    localStorage.clear()
                    window.location.href="/";
                })
            })
    }

    const handleReadMoreClick = (description) => {
        fullDescriptionRef.current = description;
        setFullDescription(description);
        setShowModal(true);
    };

    useEffect(() => {
        const storedTab = localStorage.getItem("profilewebnairType");
        if (storedTab) {
            setActiveTab(storedTab);
        }
    }, [])

    useEffect(() => {
        if (!localStorage.getItem("token")) {
            window.location.href = "/signin";
        }

        if (activeMenu && activeMenu != "") {
            setSelectedMenu(activeMenu)
        }

    }, [])

    // subscriber list

    function subscriberList(pageSub) {
        setIsLoading(true)
        userService.subscribe_management(pageSub - 1).then((res) => {
            if (res?.data?.status === 200) {
                setTotalSubscriberRecord(res?.data?.count)
                setPerPage(res?.data?.perPage)
                setSubscriberdata(res?.data?.data)
                setIsLoading(false)
            }
        })
            .catch((error) => {
                setIsLoading(false)
                if(error?.response.status ==401 || error?.response.status ==403){
                    Swal.fire("Warning",error?.response?.data,"warning").then((e) =>{
                        localStorage.clear()
                        window.location.href="/";
                    })
                }
            })
    }

    const history = useHistory()
    function inputChange(e) {
        let { name, value } = e.target;
        setupdatepassword({ ...updatepassword, [name]: value });
    }
    function userAvailability() {
        const params = {
            status: !isChecked ? "enabled" : "disabled"
        }
        userService.userAvailability_status(params).then((res) => {
            if (res?.data?.status === 200) {
            }
        })
            .catch((error) => {
            })
    }

    function ShareingProfile() {
        const params = {
            email: email,
            userId: localStorage.getItem("user_id")
        }
        setIsLoading(true)
        userService.share_profile(params).then((res) => {
            if (res?.data?.status === 200) {
                setIsLoading(false)
                Swal.fire("Success", "Profile has been shared successfully.", "success").then((res) => {
                    setShowed(false)
                })
            }
        })
            .catch((error) => {
                setIsLoading(false)
                Swal.fire("Warning",error?.response?.data,"warning").then((e) =>{
                    localStorage.clear()
                    window.location.href="/";
                })
            })
    }

    const handleScheduleLinkClick = () => {
        setScheduleStatus("true")
        setAppointmentStatus("false")
        SetEditBtnStatus(false);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const handleAppointmentLinkClick = () => {
        setAppointmentStatus("true")
        setScheduleStatus("false")
        SetEditBtnStatus(false)
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const handleWebinarLinkClick = () => {
        setAppointmentStatus("false")
        setScheduleStatus("false")
        WebinarList(page, activeTab, userId, profile);
        SetEditBtnStatus(false)
        history.push(`/profilenew`);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const handleMedAndMeLinkClick = () => {
        setAppointmentStatus("false")
        setScheduleStatus("false")
        medicalUserList(page, userType, activeTabM, userId);
        SetEditBtnStatus(false)
        { userType == "Professional" && history.push(`/profilenew`) }
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const handlesubscriberListLinkClick = () => {
        setAppointmentStatus("false")
        setScheduleStatus("false")
        subscriberList(pageSub); SetEditBtnStatus(false)
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const handleCardListLinkClick = () => {
        setAppointmentStatus("false")
        setScheduleStatus("false")
        SetEditBtnStatus(false)
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const handleOrderListLinkClick = () => {
        setAppointmentStatus("false")
        setScheduleStatus("false")
        SetEditBtnStatus(false)
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const handleproductSubscriptionLinkClick = () => {
        setAppointmentStatus("false")
        setScheduleStatus("false")
        SetEditBtnStatus(false)
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const handleManageAvailabilityLinkClick = () => {
        setAppointmentStatus("false")
        setScheduleStatus("false")
        SetEditBtnStatus(false)
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };





    const handleTabClick = (tab) => {
        localStorage.setItem('activeTab', tab._id);
        activeTabRef.current = tab._id;
        setActiveTabM(tab._id)
        medicalUserList(page, userType, activeTabRef.current, userId)
    };

    /// userdetails
    function getProfileList() {
        let user_id = localStorage.getItem('user_id');
        let token =localStorage.getItem("token")
        if (user_id) setUserId(user_id);
        let params = { userId: user_id, token:token }
        userService.getProfile(params).then(res => {
            if (res.data.status == 200) {
                let userData = res.data.data
                if (userData.userType == "Professional" && userData?.admin_status != "Approved") {
                    window.location.href = "/create-profile";
                }
                if (res?.data?.data?.ProfileCreated == 0) {
                    Swal.fire("Warning", "Your profile is incomplete. Please take a moment to fill in the required details.", "warning").then((e) => {
                        window.location.href = "/create-profile";
                    })
                }
                editStatusRef.current = false;
                SetEditBtnStatus(true)
                setSubscriber(res?.data?.subscribeCount)
                setUserDetail(userData)
                setIsLoadingImage(false)
                setIsChecked(userData?.medicalChatStatus)
                var height = userData?.height != "" ? Math.floor(userData?.height / 12) : 0;
                var heightDiff = parseFloat(userData?.height) - (height * 12);
                height = height + (heightDiff > 0 ? "'" + heightDiff : '');
                setValues({ ...values, weightType: userData?.weightType, height: height, glucose: userData?.glucose, cholesterol: userData?.cholesterol, bloodPressureMax: userData?.bloodPressureMax, bloodPressureMin: userData?.bloodPressureMin, weight: userData?.weight })
            }
        }).catch((error) => {
            console.log("error: ", error?.response);
        });
    }

    // userlist
    function medicalUserList(page, userType, activeTabM, userId) {
        setIsLoading(true)
        userService.med_list((page - 1), userType, activeTabM, userId).then((res) => {
            setMedUserList(res?.data?.postsWithViewsAndDaysAgo)
            setTotalcount(res?.data?.totalCount)
            setIsLoading(false)
        })
            .catch((Error) => {
                setIsLoading(false)
                Swal.fire("Warning",Error?.response?.data,"warning").then((e) =>{
                    localStorage.clear()
                    window.location.href="/";
                })
            })
    }

    // get category 
    function getCotagery() {
        userService.get_category().then((res) => {
            setcategoryList([{ name: "All", _id: "" }, ...res?.data?.data])

        })
            .catch((Error) => {
            })
    }


    const onVideoPlay = (item) => {
        setMedUserList(prev =>
            prev.map((value) =>
                value._id == item._id ? { ...value, videoPlay: true } : { ...value, videoPlay: false }
            )
        )
    }
    const onVideoPause = (item) => {
        setMedUserList(prev =>
            prev.map((value) =>
                value._id == item._id ? { ...value, videoPlay: false } : { ...value, videoPlay: false }
            )
        )
    }

    function WebinarList(pageW, activeTab, userId, profile) {
        setIsLoading(true)
        userService.webnair_list((pageW - 1), activeTab, userId, profile).then((res) => {
            setWebnairData(res?.data?.data)
            settotalpage(res?.data?.count)
            setIsLoading(false)
        })
            .catch((Error) => {
                setIsLoading(false)
                Swal.fire("Warning",Error?.response?.data,"warning").then((e) =>{
                    localStorage.clear()
                    window.location.href="/";
                })
                
            })
    }

    const handleTabClickforWorld = (tab) => {
        setActiveTab(tab);
        setPageW(1)
        localStorage.setItem("profilewebnairType", tab)
        if (tab === "live") {
            WebinarList(1, tab, userId, profile)
        }
        else if (tab === "upcoming") {
            WebinarList(1, tab, userId, profile)
        }
        else if (tab === "past") {
            WebinarList(1, tab, userId, profile)
        }
    };

    function handlePageChange(pageNumber, tabType) {
        if (activeTab == "live") {
            setPageW(pageNumber);
            WebinarList(pageNumber, activeTab, userId, profile)
        }
        if (activeTab == "upcoming") {
            pageW(pageNumber);
            WebinarList(pageNumber, activeTab, userId, profile)
        }
        if (activeTab == "past") {
            pageW(pageNumber)
            WebinarList(pageNumber, activeTab, userId, profile)
        }

        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 1000);
    }

    function handlePageChangeforMed(pageNumber) {

        medicalUserList(pageNumber, userType, activeTabM, userId)
        setPage(pageNumber);
    }

    function handlePageChangeSubscriber(pageNumber) {
        setPageSub(pageNumber)
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 1000);
    }


    // update password
    function handleChangePassword(e) {
        e.preventDefault()
        if (updatepassword.oldpassword == "") {
            Swal.fire("Warning", "Old password field can't be empty", "warning");
        }
        else if (updatepassword.newPassword == "") {
            Swal.fire("Warning", "New password field can't be empty", "warning");
        }
        else if (updatepassword.newPassword == updatepassword.oldpassword) {
            Swal.fire("Warning", "New password can't be same as old password", "warning");
        }
        else {
            let params = { userId: localStorage.getItem("user_id"), oldPassword: updatepassword?.oldpassword, newPassword: updatepassword?.newPassword }
            setIsLoading(true)
            userService.updatePassword(params).then((response) => {
                if (response.data.status == 200) {
                    handleClose();
                    Swal.fire("Success", "Password has been changed successfully", "success");
                    setIsLoading(false)
                }
                else if (response.data.status == 400) {
                    Swal.fire("Warning", response?.data?.message, "warning");
                    setIsLoading(false)
                }
                else {
                    Swal.fire("Error", response.data.message, "Error");
                    setIsLoading(false)
                }

            }).catch((error) => {
                setIsLoading(false)
                Swal.fire("Error", error?.response?.data.message, "error");
            });
        }
    }

    /// detele api from list data
    function deletedListData(id) {
        setIsLoading(true)
        userService.deleted_med_data(id).then((res) => {
            Swal.fire("Success", "Deleted Successfully.", "success");
            setIsLoading(false)
            if (page >= 1 && (medUserList && medUserList?.length <= 1)) {
                medicalUserList((page - 1), userType, activeTabM, userId)
            }
            else {
                medicalUserList(page, userType, activeTabM, userId)
            }
        })
            .catch((Error) => {
                setIsLoading(false)
            })
    }

    function deleteWebinarData(webinarId) {
        setIsLoading(true)
        userService.delete_webinar_data(webinarId).then((res) => {
            Swal.fire("Success", "Deleted Successfully.", "success");
            setIsLoading(false)
            if (pageW >= 1 && (webnairdata && webnairdata?.length <= 1)) {
                WebinarList(pageW, activeTab, userId, 'yes')
            }
            else {
                WebinarList(pageW, activeTab, userId, 'yes')
            }
        })
            .catch((Error) => {
                setIsLoading(false)
            })
    }

    function calculateBMI(h, w) {
        let arry = h.split("'");
        let params = new URLSearchParams({
            'foot': arry[0],
            'inch': arry[1] ? arry[1] : 0,
            'weight': w,
            'weightType': values.weightType
        });
        userService.calculateBMI(params).then((response) => {
            setUser((val) => ({ ...val, bmi: response.data.data }))
        }).catch((error) => {
        });
    }

    function subscribe(id) {
        const params = {
            postid: id
        }
        setIsLoading(true)
        userService.subscribe_user(params).then((res) => {
            Swal.fire("Success", "Subscribed successfully.", "success")
            medicalUserList(page, userType, activeTabM, userId)
            setIsLoading(false)
        })
            .catch((error) => {
                setIsLoading(false)
            })
    }

    // 

    function unsubscribe(id) {
        setIsLoading(true)
        userService.un_subscribe_user(id).then((res) => {
            Swal.fire("Success", "Subscribed deleted Successfully.", "success")
            medicalUserList(page, userType, activeTabM, userId)
            setIsLoading(false)
        })
            .catch((error) => {
                setIsLoading(false)
            })
    }
    let location = window.location.search?.split("=")[1]
    const combinedDatamedAndMe = {
        data: location,
        userId: localStorage.getItem("user_id")

    }

    const combinedData = {
        data: slug,
        userId: localStorage.getItem("user_id")
    };
    const play = useRef("")
    const [onplaying, setOnPlaying] = useState(false)

    function handleSubmitShare(e) {
        const EMAILREGEX = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
        if (!email || email.trim() == "") {
            Swal.fire("Warning", "Please enter email.", "warning");
        }
        else if (!EMAILREGEX.test(email)) {
            Swal.fire("Warning", "Please enter valid email address", "warning");
        }
        else {
            ShareingProfile()
        }
    }

    function validateHeight(inputValue) {
        const heightRegex = /^[0-9]+'[0-9]+$/;
        if (!heightRegex.test(inputValue)) {
            Swal("Warning", "Please select Height", "warning");
            return false;
        }
    }

    function validateWeight(inputValue) {
        const positiveNumberRegex = /^[+]?\d+(\.\d+)?$/;
        if (!positiveNumberRegex.test(inputValue)) {
            Swal.fire("Warning", "Please enter Weight", "warning");
            return false;
        }
        const weightValue = parseFloat(inputValue);
        if (weightValue < 0 || weightValue >= 1000) {
            Swal.fire("Warning", "Please enter Weight maximum 1000.", "warning");
            return false;
        }
    }

    // manage availability
    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
        userAvailability()
    };

    function handleContextMenu(event) {
        event.preventDefault();
    }

    const openLinkedInProfile = (url) => {
        window.open(url, '_blank');
      }
    const [editBtnStatus, SetEditBtnStatus] = useState(false)
    return (
        <>
            <Header />
            {isLoading && <Loader />}
            <section className="updated-profile-section">
                <div className="container">
                    <div className="profile-header">
                        <h3>My Profile</h3>

                    </div>
                    <div className="row">
                        <aside className="col-lg-4">
                            <div className="profile-area-left">
                                <div className="profile-image-area">
                                    <div className="inner-profile-main">
                                    {isLoadingImage ? (
                                      <Skeleton  height={245} width={300}  className="profile-image"/> // Shimmer effect while loading
                                    ) : (
                                      <img
                                        src={
                                          userDetail && userDetail.profilePicture && userDetail?.profilePicture !== "undefined"
                                            ? userDetail.profilePicture
                                            : require('../../src/images/update/dummy-profile-img.jpg').default
                                        }
                                        alt="img"
                                        className="profile-image"
                                      />
                                    )}
                                        <div>
                                            {localStorage.getItem("userType") == "Professional" && <h4> {userDetail?.name}</h4>}
                                            {localStorage.getItem("userType") == "Professional" && <p className="blue-text m-0"><b>{subscriber ? subscriber : "0"} Subscriber</b></p>}
                                            {localStorage.getItem("userType") == "Professional" && <p className="blue-text m-0"><b>Premium Account</b></p>}
                                            {localStorage.getItem("userType") == "Client" && <h4>{userDetail?.name}</h4>}
                                            {localStorage.getItem("userType") == "Client" && <span> Pseudo Name : {userDetail?.pseudoName ? userDetail?.pseudoName : "N/A"}</span>}
                                            {localStorage.getItem("userType") == "Client" && <a href="#" className="d-flex"><img src={require("../images/update/email.svg").default} alt="img" className="mr-2" />{userDetail?.email}</a>}
                                        </div>
                                    </div>
                                    <div className="profile-top-cons-area">
                                        {userDetail && userDetail?.linkedInUrl && userDetail?.linkedInUrl !=="null" && <div className="social-btn">
                                            <button className="btn btn-sm btn-facebook" data-toggle="tooltip" title={userDetail?.linkedInUrl}
                                                onClick={(e) => window.open(userDetail.linkedInUrl, '_blank')}>
                                                <i class="fa fa-linkedin" aria-hidden="true"></i>
                                            </button>
                                        </div>}
                                        {userDetail && userDetail?.websiteUrl && userDetail?.websiteUrl !== "null" && (
                                            <div className="social-btn">
                                                <a
                                                    className="btn btn-sm twt-btn"
                                                    href={userDetail.websiteUrl}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    title={userDetail?.websiteUrl}
                                                >
                                                    <img src={require("../images/update/web-icon.png").default} alt="Website Icon" />
                                                </a>
                                            </div>
                                        )}


                                    </div>
                                    <div className="wallet-box walled-issue">
                                        <div className="d-flex align-itmes-center">
                                            <img src={require("../images/update/wallet.svg").default} alt="img" className="wallet" />
                                            <span><b>Wallet</b></span>
                                        </div>
                                        <p className="p-0 m-0"><b>${userDetail?.wallet ? userDetail?.wallet.toFixed(2) : 0}</b></p>
                                    </div>
                                </div>
                                <ul class="nav nav-tabs">
                                    <li class="nav-item">
                                        <span class={`nav-link ${selectedMenu == "profile" ? "active" : ""}`} data-toggle="tab"
                                            onClick={(e) => { window.location.href = "/profilenew"; localStorage.removeItem("profilewebnairType"); localStorage.removeItem("selectedTab"); localStorage.removeItem("selectedTabAppointment"); }}
                                        ><img src={require("../images/update/profile.svg").default} alt="img" className="wallet" /> My Profile</span>
                                    </li>
                                    {localStorage.getItem("userType") == "Professional" && <li class="nav-item">
                                        <a class={`nav-link ${selectedMenu == "Availability" ? "active" : ""}`} data-toggle="tab" href="#menu1"
                                            onClick={(e) => {
                                                handleManageAvailabilityLinkClick()
                                            }}
                                        >
                                            <img src={require("../images/update/avail.svg").default} alt="img" className="" /> Manage Availability
                                        </a>
                                    </li>}

                                    <li class="nav-item">
                                        <a class={`nav-link ${selectedMenu == "productSubscription" ? "active" : ""}`} data-toggle="tab" href="#menu12"
                                            onClick={(e) => {
                                                handleproductSubscriptionLinkClick()
                                            }}
                                        >
                                            <img src={require("../images/update/subscription-icon.png").default} alt="img" className="subscribe-icon" /> My Subscription
                                        </a>
                                    </li>

                                    <li class="nav-item">
                                        <a class={`nav-link ${selectedMenu == "orders" ? "active" : ""}`} data-toggle="tab" href="#menu2"
                                            onClick={(e) => {
                                                handleOrderListLinkClick()
                                            }}
                                        >
                                            <img src={require("../images/update/order.svg").default} alt="img" className="" /> My Orders
                                        </a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" data-toggle="tab" onClick={(e) => { window.location.href = "/chat"; SetEditBtnStatus(false) }}>
                                            <img src={require("../images/update/message.svg").default} alt="img" className="" /> Message
                                        </a>
                                    </li>
                                    <li class="nav-item">
                                        <a class={`nav-link ${selectedMenu == "card" ? "active" : ""}`} data-toggle="tab" href="#menu3"
                                            onClick={(e) => { handleCardListLinkClick() }}
                                        >
                                            <img src={require("../images/update/card.svg").default} alt="img" className="" /> My Card
                                        </a>
                                    </li>
                                    {localStorage.getItem("userType") == "Professional" && <li class="nav-item">
                                        <a class={`nav-link ${selectedMenu == "subscriberManagement" ? "active" : ""}`} data-toggle="tab" href="#menu4"
                                            onClick={(e) => { handlesubscriberListLinkClick() }}
                                        >
                                            <img src={require("../images/update/subscribe.svg").default} alt="img" className="" /> Subscriber Management
                                        </a>
                                    </li>}

                                    {localStorage.getItem("userType") == "Client" &&
                                        <li class="nav-item">
                                            <a class={`nav-link ${selectedMenu == "bi4healthmedandme" ? "active" : ""}`} data-toggle="tab" href="#menu5"
                                                onClick={(e) => {
                                                    handleMedAndMeLinkClick()
                                                }}
                                            >
                                                <img src={require("../images/update/me.svg").default} alt="img" className="" />  BIG4 Health Me
                                            </a>
                                        </li>
                                    }
                                    {localStorage.getItem("userType") == "Professional" &&
                                        <li class="nav-item">
                                            <a class={`nav-link ${selectedMenu == "bi4healthmedandme" ? "active" : ""}`} data-toggle="tab" href="#menu5"
                                                onClick={(e) => {
                                                    handleMedAndMeLinkClick()
                                                }}
                                            >
                                                <img src={require("../images/update/manage.svg").default} alt="img" className="" /> BIG4 Health Med
                                            </a>
                                        </li>
                                    }

                                    {localStorage.getItem("userType") == "Professional" && <li class="nav-item">
                                        <a class={`nav-link ${selectedMenu == "big4healthworld" ? "active" : ""}`} data-toggle="tab" href="#menu6"
                                            onClick={(e) => {
                                                handleWebinarLinkClick()
                                            }}>
                                            <img src={require("../images/update/world.svg").default} alt="img" className="" /> BIG4 Health World
                                        </a>
                                    </li>}
                                    {localStorage.getItem("userType") == "Professional" && <li class="nav-item">
                                        <a class={`nav-link ${selectedMenu == "schedule" ? "active" : ""}`} data-toggle="tab" href="#menu9"
                                            onClick={
                                                (e) => {
                                                    handleScheduleLinkClick()
                                                }
                                            }
                                        >
                                            <img src={require("../images/update/schedule.svg").default} alt="img" className="" /> My Booked Medical Chat
                                        </a>
                                    </li>}

                                    <li class="nav-item">
                                        <a class={`nav-link ${selectedMenu == "appointment" ? "active" : ""}`} data-toggle="tab" href="#menu10"
                                            onClick={
                                                (e) => {
                                                    handleAppointmentLinkClick()
                                                }
                                            }
                                        >
                                            <img src={require("../images/update/booking.svg").default} alt="img" className="" /> My Appointments
                                        </a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link border-0" data-toggle="tab" onClick={(e) => { setShow(true); SetEditBtnStatus(false) }}>
                                            <img src={require("../images/update/password.svg").default} alt="img" className="" /> Change Password
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </aside>
                        <aside className="col-lg-8">
                            <div class="tab-content">
                                <div class={`tab-pane ${selectedMenu == "profile" ? "active" : "fade"}`} id="home">
                                    <div className="profile-right-side">
                                        <div className="profile-right-header edit-top-btn-area">
                                            <h4>information</h4>
                                            <div className="profile-header">
                                                {editBtnStatus == true && <a href="/edit-profile" data-placement="top" title="Edit Profile"><img src={require("../images/update/edit.svg").default} alt="img" />Edit Profile</a>}
                                            </div>
                                        </div>
                                        <div className="profile-right-body">
                                            <a href="#"><img src={require("../images/update/email.svg").default} alt="img" className="mr-2" />{userDetail?.email}</a>
                                            {localStorage.getItem("userType") == "Client" && <a href="#"><i class="fa fa-language mr-2" aria-hidden="true"></i>{userDetail?.languages}</a>}
                                            {localStorage.getItem("userType") == "Client" && <a href="#"><i class="fa fa-file-archive-o mr-2" aria-hidden="true"></i>{userDetail?.zipCode}</a>}

                                            {localStorage.getItem("userType") == "Professional" && <a href="#"><img src={require("../images/update/call.svg").default} alt="img" className="mr-2" />{userDetail?.mobileNumber}</a>}
                                            <a href="#"><img src={require("../images/update/location.svg").default} alt="img" className="mr-2" />{userDetail?.city}</a>
                                        </div>
                                        {localStorage.getItem("userType") == "Professional" && <div className="profile-right-body">
                                            <div className="user-info-mid-content-area">
                                                <div>
                                                    <h4>Speciality</h4>
                                                    <p className="blue-text">{userDetail?.speciality}</p>
                                                </div>
                                                <div>
                                                    <h4>Experience</h4>
                                                    <p className="blue-text">{userDetail?.experience}</p>
                                                </div>
                                                <div>
                                                    <h4>Language</h4>
                                                    <p className="blue-text">{userDetail?.languages}</p>
                                                </div>
                                            </div>
                                            <h4>Bio</h4>
                                            <p className="blue-text mb-0">{userDetail?.bio}</p>
                                        </div>}

                                        {userDetail && userDetail?.userType == "Client" &&
                                            <div className="profile-right-body">
                                                <h4 className="d-flex justify-content-between align-items-center flex-wrap">Share with Healthcare Provider:
                                                    <span className="d-flex align-items-center flex-wrap"><span onClick={(e) => setEditModal(true)} data-toggle="tooltip" data-placement="top" title={"Edit Metric"} className="edit-metric"><img src={require("../images/update/edit.svg").default} alt="img" className="" /></span>
                                                        <span data-toggle="tooltip" data-placement="top" title={"Share with Healthcare Provider"} className="share-btn" onClick={(e) => {
                                                            if (!userDetail?.weight || !userDetail?.height) {
                                                                Swal.fire("Warning", "It appears that you haven't provided any health metric data. To share this information with your healthcare provider, please update the data accordingly.", "warning");
                                                            }
                                                            else {
                                                                setShowed(true)
                                                            }

                                                        }}><img src={require("../images/update/share.svg").default} alt="img" className=" " /></span></span></h4>
                                                <div className="share-box">
                                                    <div className="row mt-3">
                                                        <aside className="col-lg-3 col-md-6 col-sm-12">
                                                            <div className="share-inner-box yellow">
                                                                <img src={require("../images/update/bp.svg").default} alt="img" className="mr-2" />
                                                                <h4>

                                                                    <h4>
                                                                        {userDetail?.bloodPressureMin !== null && userDetail?.bloodPressureMax !== null
                                                                            ? `${userDetail.bloodPressureMax}/${userDetail.bloodPressureMin} mm Hg`
                                                                            : userDetail?.bloodPressureMax !== null && userDetail?.bloodPressureMin !== null
                                                                                ? `${userDetail.bloodPressureMax}/${userDetail.bloodPressureMin} mm Hg`
                                                                                : '0/0 mm Hg'}
                                                                    </h4>
                                                                </h4>
                                                                <p>BLOOD PRESSURE</p>
                                                            </div>
                                                        </aside>
                                                        <aside className="col-lg-3 col-md-6 col-sm-12">
                                                            <div className="share-inner-box light">
                                                                <img src={require("../images/update/glucose.svg").default} alt="img" className="mr-2" />
                                                                <h4>{userDetail && userDetail?.glucose} mg/dl</h4>
                                                                <p>GLUCOSE</p>
                                                            </div>
                                                        </aside>
                                                        <aside className="col-lg-3 col-md-6 col-sm-12">
                                                            <div className="share-inner-box sky-blue">
                                                                <img src={require("../images/update/cholestrol.svg").default} alt="img" className="mr-2" />
                                                                <h4>{userDetail && userDetail?.cholesterol} mg/dl</h4>
                                                                <p>CHOLESTROL</p>
                                                            </div>
                                                        </aside>
                                                        <aside className="col-lg-3 col-md-6 col-sm-12">
                                                            <div className="share-inner-box green">
                                                                <img src={require("../images/update/bmi.svg").default} alt="img" className="mr-2" />
                                                                <h4>{userDetail?.bmi?.toFixed(2)} mg/dl</h4>
                                                                <p>BMI</p>
                                                            </div>
                                                        </aside>
                                                    </div>
                                                </div>
                                                <div className="disclaimer-area">
                                                    <p className="blue-text">   <b> Disclaimer:</b></p>
                                                    <p> Your health data syncs with BIG4 Health App. Download it on <a href="https://play.google.com/store/apps/details?id=com.big4healthapp" target="_blank">Google Play</a> </p>
                                                    <span>OR</span>
                                                    <p><a href="https://apps.apple.com/us/app/big4health/id1517973481" target="_blank">Apple App Store</a> for easy access and management. Stay connected to monitor your well-being!</p>
                                                </div>
                                            </div>}


                                        <div className="profile-right-body">
                                            <h4 className="d-flex justify-content-between align-items-center flex-wrap">My Appointment
                                            </h4>

                                            <div className="share-box">
                                                <div className="row mt-3">
                                                    {AppointmentData.length > 0 ? AppointmentData?.map((item, key) => {
                                                        const isPending = item?.status === "PENDING";
                                                        const isAccepted = item?.status === "ACCEPTED";
                                                        const bookingDate = new Date(item?.bookingDate);
                                                        const bookingDateEnd = new Date(item?.bookingEndDate);
                                                        const options = {
                                                            weekday: 'long',
                                                            month: 'short',
                                                            day: 'numeric',
                                                            hour: 'numeric',
                                                            minute: 'numeric',
                                                        };
                                                        const formattedDate = bookingDate.toLocaleString('en-US', options);
                                                        const formattedDateEnd = bookingDateEnd.toLocaleString('en-US', options);
                                                        var currentDate = new Date().getTime();
                                                        var givenDate = bookingDate.getTime();
                                                        var timeDifference = givenDate - currentDate;
                                                        var joinNowTime = (timeDifference / 60000)
                                                        var hours = Math.floor(joinNowTime / 60);
                                                        var minutes = joinNowTime % 60;
                                                        return (
                                                            <>

                                                                <aside className="col-lg-4 col-md-6 col-sm-12">
                                                                    <div className="webinar-inner-box cursor-issue">
                                                                        <img src={item?.meduserDetail?._id == localStorage?.getItem("user_id") ?
                                                                            (item?.userDetail?.profilePicture && item?.userDetail?.profilePicture != "undefined" ? item?.userDetail?.profilePicture :
                                                                                require('../images/update/dummy-profile-img.jpg').default) :
                                                                            (item?.meduserDetail?.profilePicture && item?.meduserDetail?.profilePicture != "undefined" ?
                                                                                item?.meduserDetail?.profilePicture :
                                                                                require('../images/update/dummy-profile-img.jpg').default)} alt="img" className="mr-2 schedule-img" />
                                                                        <div>
                                                                            <h4>{item?.meduserDetail?.name}</h4>
                                                                            <p>{item?.category?.name}</p>
                                                                            <p className="mb-0 blue-text"><img src={require('../images/update/date-blue.svg').default} alt="img" className="mr-2" />{formattedDate}{" "}{userTimezone}</p>
                                                                            <span className='description-view'>
                                                                                {item?.notes?.length > 30 ? (
                                                                                    <div>
                                                                                        {item?.notes?.slice(0, 30) + '...'}
                                                                                        <a
                                                                                            className='description-view-btn' data-toggle="modal" data-target="#exampleModalCenter11"
                                                                                            onClick={(e) => handleReadMoreClick(item?.notes)}
                                                                                        >
                                                                                            Read More
                                                                                        </a>{' '}
                                                                                    </div>
                                                                                ) : (
                                                                                    item?.notes
                                                                                )}
                                                                            </span>
                                                                            {item.status == "ACCEPTED" && <p>
                                                                                {joinNowTime > 10 ?
                                                                                    `Start in ${hours > 0 ? `${hours} hours` : ''} ${minutes > 0 ? `${minutes.toFixed(0)} minutes` : ''}` :
                                                                                    "Started"
                                                                                }
                                                                            </p>}
                                                                            <div className='appointment-btns'>
                                                                                {item?.status == "PENDING" ?
                                                                                    <p className="join-button pending-btn mr-2 common-btn-spacing">{"Pending"}</p>
                                                                                    : item?.status === "ACCEPTED" && joinNowTime < 10 ? 
                                                                                     <> <VideoCall isFromHeader="true" isUserCalling="true" item={item} /></> 
                                                                                    :
                                                                                        <button className={`join-button mr-2 common-btn-spacing ${joinNowTime < 10 ? " " : "disabled"}`}>Join Now</button>}
                                                                                <button className="join-button red-btn common-btn-spacing" onClick={(e) => handleShow(item?._id)}>Cancel</button>
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </aside>

                                                            </>
                                                        )
                                                    })
                                                        :
                                                        <div className="profile-right-side no-data-profile no-upper-main-data mb-3 height-spacing-area">
                                                            <div className="no-data-box">
                                                                <img src={require("../images/update/no-data.png").default} alt="img" className="mr-2" />
                                                                <h5>No Appointment here</h5>
                                                            </div>
                                                        </div>}
                                                </div>
                                            </div >
                                        </div>
                                        {/* } */}
                                        {userDetail && userDetail?.userType == "Professional" && <div className="profile-right-body">
                                            <h4 className="d-flex justify-content-between align-items-center flex-wrap">My Recent Schedule Request
                                            </h4>

                                            <div className="share-box">
                                                <div className="row mt-3">
                                                    {scheduleData?.length > 0 ? scheduleData?.map((item, key) => {
                                                        const bookingDate = new Date(item?.bookingDate);
                                                        const bookingDateEnd = new Date(item?.bookingEndDate);
                                                        const options = {
                                                            weekday: 'long',
                                                            month: 'short',
                                                            day: 'numeric',
                                                            hour: 'numeric',
                                                            minute: 'numeric',
                                                        };
                                                        const formattedDate = bookingDate.toLocaleString('en-US', options);
                                                        const formattedDateEnd = bookingDateEnd.toLocaleString('en-US', options);
                                                        return (
                                                            <>

                                                                <aside className="col-lg-4 col-md-6 col-sm-12">
                                                                    <div className="webinar-inner-box cursor-issue">
                                                                        <img src={item?.meduserDetail?._id == localStorage?.getItem("user_id") ?
                                                                            (item?.userDetail?.profilePicture && item?.userDetail?.profilePicture != "undefined" ? item?.userDetail?.profilePicture :
                                                                                require('../images/update/dummy-profile-img.jpg').default) :
                                                                            (item?.meduserDetail?.profilePicture && item?.meduserDetail?.profilePicture != "undefined" ?
                                                                                item?.meduserDetail?.profilePicture :
                                                                                require('../images/update/dummy-profile-img.jpg').default)} alt="img" className="mr-2 schedule-img" />
                                                                        <div>
                                                                            <h4>{item?.userDetail?.name}</h4>
                                                                            <p>{item?.category?.name}</p>
                                                                            <p className="mb-0 blue-text"><img src={require('../images/update/date-blue.svg').default} alt="img" className="mr-2" />{formattedDate}{" "}{userTimezone}</p>
                                                                            <span className='description-view'>
                                                                                {item?.notes?.length > 30 ? (
                                                                                    <div>
                                                                                        {item?.notes?.slice(0, 30) + '...'}
                                                                                        <a
                                                                                            className='description-view-btn' data-toggle="modal" data-target="#exampleModalCenter11"
                                                                                            onClick={(e) => handleReadMoreClick(item?.notes)}
                                                                                        >
                                                                                            Read More
                                                                                        </a>{' '}
                                                                                    </div>
                                                                                ) : (
                                                                                    item?.notes
                                                                                )}
                                                                            </span>
                                                                            <div className='appointment-btns'>
                                                                                {item?.status == "PENDING" &&
                                                                                    <button className="join-button mr-2" onClick={(e) => AcceptAppointment(item?._id, "ACCEPTED")}>
                                                                                        Accept
                                                                                    </button>}
                                                                                {item?.status == "PENDING" && <button className="join-button red-btn" onClick={(e) => handleRejectAppointment(item?._id)}>Reject</button>}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </aside>

                                                            </>
                                                        )
                                                    }) :
                                                        <div className="profile-right-side no-data-profile no-upper-main-data mb-3 height-spacing-area">
                                                            <div className="no-data-box">
                                                                <img src={require("../images/update/no-data.png").default} alt="img" className="mr-2" />
                                                                <h5>No Recent Schedule Request here</h5>
                                                            </div>
                                                        </div>}
                                                </div>
                                            </div >
                                        </div>}

                                    </div>
                                </div>
                                <div class={`tab-pane ${selectedMenu == "Availability" ? "active" : "fade"}`} id="menu1">
                                    <div className="profile-right-sidee no-upper-main-data">
                                        <div className="profile-right-side ">
                                            <div class="profile-right-header d-flex align-items-center justify-content-between"><h4>Manage Availability</h4>
                                                <div className="d-flex align-items-center chat-toggle">
                                                    <span className="text-light  mr-2">Medical Chat</span>
                                                    <label className="switch">
                                                        <input className="switch-input" type="checkbox" checked={isChecked} onChange={handleCheckboxChange} />
                                                        <span className="switch-label" data-on="On" data-off="Off"></span>
                                                        <span className="switch-handle"></span>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="manage-management profile-right-body row">

                                                {isChecked && <Availability />}

                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class={`tab-pane ${selectedMenu == "schedule" ? "active" : "fade"}`} id="menu9">
                                    <div className="profile-right-sidee no-upper-main-data">
                                        {scheduleStatus =="true" && 
                                        <div className="profile-right-side ">
                                            <ScheduleListing />
                                        </div>
                                        }
                                    </div>
                                </div>

                                <div class={`tab-pane ${selectedMenu == "appointment" ? "active" : "fade"}`} id="menu10">
                                    <div className="profile-right-sidee no-upper-main-data">
                                       {appointmentStatus =="true" && 
                                       <div className="profile-right-side ">
                                            <AppointmentListing />
                                        </div>
                                         } 
                                    </div>
                                </div>

                                <div class={`tab-pane ${selectedMenu == "orders" ? "active" : "fade"}`} id="menu2">
                                    <div className="profile-right-side">
                                        <div className="profile-right-header">
                                            <h4>My Orders</h4>

                                        </div>
                                        <MyOrder />
                                    </div>
                                </div>

                                <div class={`tab-pane ${selectedMenu == "productSubscription" ? "active" : "fade"}`} id="menu12">
                                    <div className="profile-right-side">
                                        <div className="profile-right-header">
                                            <h4>My Subscription</h4>

                                        </div>
                                        <Subscriptions />
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="menu2">
                                    <div className="profile-right-side no-upper-main-data">
                                        <div className="no-data-box">
                                            <img src={require("../images/update/no-data.png").default} alt="img" className="mr-2" />
                                            <h3>No data found</h3>
                                        </div>
                                    </div>
                                </div>
                                <div class={`tab-pane ${selectedMenu == "card" ? "active" : "fade"}`} id="menu3">
                                    <div className="profile-right-side">
                                        <div class="profile-right-header"><h4>My Card List</h4></div>
                                        <div className="new-card-add-btn">
                                            <button type="button" variant="unset" onClick={(e) => setaddcardModel(true)} >+ Add New Card</button>
                                        </div>
                                        <div className="row p-3">

                                            {cards?.length > 0 ? cards?.map((card, index) => {
                                                return (
                                                    <aside className='col-md-6 mb-3'>
                                                        <div className="cardbox">
                                                            <div className="cardbox_header mb-3">
                                                                <input onChange={() => setCardId(card._id)} type="radio" checked={card._id == cardId} />
                                                                {card?.brand === "Visa" && <img src={require(`../../src/images/update/visa.png`).default} alt="Visa Card" />}
                                                  {card?.brand === "MasterCard" && <img src={require(`../../src/images/update/master.png`).default} alt="MasterCard" />}
                                                  {!card?.brand && <p>No card brand specified</p>}
                                                            </div>
                                                            <h5>XXXX  XXXX  XXXX <span> {card.last4}</span></h5>
                                                        </div>
                                                    </aside>

                                                )
                                            }) :

                                                <div className="no-data-box">
                                                    <img src={require("../images/update/no-data.png").default} alt="img" className="mr-2" />
                                                    <h3>No data found</h3>
                                                </div>}
                                        </div>
                                    </div>
                                </div>

                                <div class={`tab-pane ${selectedMenu == "subscriberManagement" ? "active" : "fade"}`} id="menu4">
                                    <div className="profile-right-side ">
                                        <div class="profile-right-header"><h4>subscribers List</h4></div>
                                        <div className="subscribe-management profile-right-body row">
                                            {subscriberdata?.length > 0 ?
                                                subscriberdata?.map((item, key) => {
                                                    return (
                                                        <>
                                                            <div className=" col-lg-4 col-sm-6">
                                                                <div className="management-user-image">
                                                                    <img onClick={(e) => window.location.href = `/profile/${item?.userDetail?.userSlug}`} src={item && item?.userDetail && item?.userDetail?.profilePicture && item?.userDetail?.profilePicture !== "undefined" ? item?.userDetail?.profilePicture : require("../images/update/dummy-profile-img.jpg").default} alt="img" className="mr-2" />
                                                                    <h5>{item?.userDetail?.firstName}</h5>
                                                                    <p>{item?.userDetail?.email}</p>
                                                                </div>
                                                            </div>
                                                        </>
                                                    )
                                                })
                                                :
                                                <div className="no-data-box no-data-subscriber">
                                                    <img src={require("../images/update/no-data.png").default} alt="img" className="mr-2" />
                                                    <h3>No data found</h3>
                                                </div>
                                            }

                                            {totalRecordSubscriber > perPage && (
                                                <div className='col-md-12 text-center'>
                                                    <div className="pagination1">
                                                        <Pagination
                                                            activePage={pageSub}
                                                            itemsCountPerPage={perPage}
                                                            totalItemsCount={totalRecordSubscriber}
                                                            pageRangeDisplayed={5}
                                                            onChange={(e) => handlePageChangeSubscriber(e)}
                                                            hideDisabled={true}
                                                            prevPageText={"Previous"}
                                                            nextPageText={"Next"}
                                                        />
                                                    </div>
                                                </div>
                                            )}

                                        </div>
                                    </div>
                                </div>
                                <div class={`tab-pane ${selectedMenu == "bi4healthmedandme" ? "active" : "fade"}`} id="menu5">
                                    <div className="profile-right-side">

                                        <div className="profile-right-header">
                                            {localStorage.getItem("userType") == "Professional" && <h4> BIG4 Health Med</h4>}
                                            {localStorage.getItem("userType") == "Client" && <h4> BIG4 Health Me</h4>}
                                        </div>

                                        <div className="profile-right-body">
                                            {!location && <div className="tab-container">
                                                {categoryList?.map(tab => (
                                                    <button
                                                        key={tab._id}
                                                        className={`cat-list mb-3 ${activeTabM === tab._id ? 'active' : ''}`}
                                                        onClick={() => handleTabClick(tab)}
                                                    >
                                                        {tab?.name}
                                                    </button>
                                                ))}
                                            </div>}


                                            <div className="row" >
                                                {medUserList?.length > 0 ?
                                                    medUserList?.map((item, index) => {
                                                        return (
                                                            <>
                                                                {!location && <div className="col-md-6" >
                                                                    <div className="big-med-wrap notranslate"
                                                                    >
                                                                        {item?.media_type == "image" &&
                                                                            <div className="big-med-main-img profile-big-me"
                                                                                onClick={(e) => {
                                                                                    history.push(`/profilenew/?id=${item?._id}`)
                                                                                }}>
                                                                                {item?.media?.length > 1 ? ( // Check if there is more than one image
                                                                                    <Swiper
                                                                                        spaceBetween={50}
                                                                                        slidesPerView={1}
                                                                                        speed={700}
                                                                                        loop={true}
                                                                                        pagination={{ clickable: true }}
                                                                                    >
                                                                                        {item?.media?.map((image, key) => (
                                                                                            <SwiperSlide key={key}>
                                                                                                <a href="#">
                                                                                                    <img className="" src={image} alt="img" />
                                                                                                </a>
                                                                                            </SwiperSlide>
                                                                                        ))}
                                                                                    </Swiper>
                                                                                ) : (
                                                                                    <a href="#">
                                                                                        <img className="" src={item?.media[0]} alt="img" />
                                                                                    </a>
                                                                                )}
                                                                            </div>}

                                                                        <div className="big-med-main-img "
                                                                            onMouseOver={event => onVideoPlay(item)}
                                                                            onMouseOut={event => onVideoPause(item)} >
                                                                            {item?.media_type == "video" &&
                                                                                <div className="big-med-main-img big-med-main-vid" >
                                                                                    <span onClick={(e) => {
                                                                                        history.push(`/profilenew/?id=${item?._id}`)
                                                                                    }}>
                                                                                        {item?.videoPlay ? <video
                                                                                            onContextMenu={handleContextMenu}
                                                                                            controlsList="nodownload"
                                                                                            width="100%"
                                                                                            height="100%"
                                                                                            autoPlay={item?.videoPlay}
                                                                                            muted
                                                                                            loop
                                                                                            preload='auto'
                                                                                            playsinline
                                                                                            poster={item?.thumbnail_icon}

                                                                                        >
                                                                                            <source
                                                                                                src={item?.media[0]}
                                                                                                type="video/mp4"
                                                                                            />
                                                                                            <source
                                                                                                src={item?.media[0]}
                                                                                                type="video/ogg"
                                                                                            />
                                                                                            <source
                                                                                                src={item?.media[0]}
                                                                                                type="video/webm"
                                                                                            />
                                                                                        </video>

                                                                                            :
                                                                                            <div className="big-med-main-img">
                                                                                                <a href="#"><img src={item?.thumbnail_icon} alt="img" /></a>
                                                                                            </div>}
                                                                                    </span>
                                                                                    {!item?.videoPlay &&
                                                                                        <img className="video-btn" onClick={(e) => onVideoPlay(item)} src={require("../images/video-play.svg").default} alt="img" />}
                                                                                </div>}

                                                                            {item?.media_type == "pdf" && <div className="big-med-main-img" onClick={(e) => {
                                                                                history.push(`/profilenew/?id=${item?._id}`)
                                                                            }}>
                                                                                <span><img src={item?.media_type == "pdf" && item?.thumbnail_icon && item?.thumbnail_icon != "undefined" ? item?.thumbnail_icon : require("../images/big-med-pdf.jpg").default} alt="img" /></span>
                                                                            </div>}

                                                                            {item?.media_type == "voice" &&
                                                                                <div className="big-med-main-img"
                                                                                    onClick={(e) => {
                                                                                        history.push(`/profilenew/?id=${item?._id}`)
                                                                                    }}
                                                                                >
                                                                                    <span><img src={item?.media_type == "voice" && item?.thumbnail_icon && item?.thumbnail_icon != "undefined" ? item?.thumbnail_icon : require("../images/big-med-audio.jpg").default} alt="img" /></span>
                                                                                </div>}

                                                                            <div className="big-med-dot-section">
                                                                                <div className="big-med-icon-text d-flex">
                                                                                    <div className="big-med-thumbnail" onClick={(e) => {
                                                                                        window.location.href = `/profile/${item?.user?._id}`;
                                                                                    }}>
                                                                                        {item && item?.user?.profilePicture !== "undefined" ? <img src={item && item?.user?.profilePicture ? item?.user?.profilePicture : require("../../src/images/update/dummy-profile-img.jpg").default} alt="img" /> : <img src={require("../../src/images/update/dummy-profile-img.jpg").default} alt="img" />}
                                                                                    </div>
                                                                                    <div className="big-med-text">
                                                                                        <h4>{item?.title}</h4>
                                                                                        <p className="channel-name">{item?.categoryName?.name}</p>
                                                                                        <p className="channel-name">{item?.user?.firstName}</p>
                                                                                        <p><span className="channel-views">{item?.views ? item?.views : 0}</span>  <span>|</span> <span className="channel-time"> {item?.timeAgo}</span></p>

                                                                                    </div>
                                                                                </div>
                                                                                <Dropdown className="drop-down">
                                                                                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                                                                                        <img src={require("../images/update/three-dot.svg").default} alt="img" className="dot-image" />
                                                                                    </Dropdown.Toggle>
                                                                                    <Dropdown.Menu className="delete-wrap">
                                                                                        <Dropdown.Item className="delet-icon-txt" onClick={(e) => {
                                                                                            let confirmBox = window.confirm("Are you sure you want to delete it")
                                                                                            if (confirmBox) {
                                                                                                deletedListData(item?._id);
                                                                                            }
                                                                                        }}><img src={require("../images/update/delete.svg").default} classNam="mr-3 " alt="img" className="dot-image delete-red" />Delete</Dropdown.Item>
                                                                                    </Dropdown.Menu>
                                                                                </Dropdown>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                                }
                                                            </>
                                                        )
                                                    })
                                                    :
                                                    <div className="profile-right-side no-upper-main-data">
                                                        <div className="no-data-box">
                                                            <img src={require("../images/update/no-data.png").default} alt="img" className="mr-2" />
                                                            <h3>No data found</h3>
                                                        </div>
                                                    </div>
                                                }
                                                {!location && totalCount > 15 && (
                                                    <div className='col-md-12 text-center'>
                                                        <div className="pagination1">
                                                            <Pagination
                                                                activePage={page}
                                                                itemsCountPerPage={15}
                                                                totalItemsCount={totalCount}
                                                                pageRangeDisplayed={15}
                                                                onChange={(e) => handlePageChangeforMed(e)}
                                                                hideDisabled={true}
                                                                prevPageText={"Previous"}
                                                                nextPageText={"Next"}
                                                            />
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        {location && activeMenu !== "big4healthworld" && <MedUserData {...combinedDatamedAndMe} />}

                                    </div>
                                </div>

                                <div class={`tab-pane ${selectedMenu == "big4healthworld" ? "active" : "fade"}`} id="menu6">
                                    <div className="profile-right-side">

                                        <div className="profile-right-header d-flex align-items-center justify-content-between">
                                            {localStorage.getItem("userType") == "Professional" && <h4> BIG4 Health World</h4>}
                                            <button className="create-btn-light create-highlighted" onClick={(e) => window.location.href = "/create-world"}>Create</button>
                                        </div>

                                        <div className="profile-right-body">
                                            {!slug &&
                                                <div className="tab-container">
                                                    <ul class="nav nav-tabs">
                                                        <li class="nav-item">
                                                            <a
                                                                className={`nav-link ${activeTab === 'live' ? 'active' : ''}`}
                                                                onClick={(e) => { handleTabClickforWorld('live'); localStorage.setItem("profilewebnairType", "live") }} data-toggle="tab" href="#world-1">Live  webinars</a>
                                                        </li>
                                                        <li class="nav-item">
                                                            <a
                                                                className={`nav-link ${activeTab === 'upcoming' ? 'active' : ''}`}
                                                                onClick={(e) => { handleTabClickforWorld('upcoming'); localStorage.setItem("profilewebnairType", "upcoming") }} data-toggle="tab" href="#world-1">Upcoming  webinars</a>
                                                        </li>
                                                        <li class="nav-item">
                                                            <a
                                                                className={`nav-link ${activeTab === 'past' ? 'active' : ''}`}
                                                                onClick={(e) => { handleTabClickforWorld('past'); localStorage.setItem("profilewebnairType", "past") }} data-toggle="tab" href="#world-1">Past  webinars</a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            }
                                            {!slug && <div className=" mt-6" >
                                                <div class="tab-content">
                                                    <div class="tab-pane container active" id="menu6">
                                                        <div className="webinar-outer-box">
                                                            <div className="row">
                                                                {webnairdata?.length > 0 ? webnairdata?.map((item, key) => {
                                                                    const webinarDate = new Date(item?.date);
                                                                    const currentTime = new Date();
                                                                    const timeDifference = webinarDate - currentTime;
                                                                    const isJoinable = timeDifference <= 3600000;
                                                                    return (<>
                                                                        {!slug &&
                                                                            <aside className="col-md-6">
                                                                                <div className="webinar-inner-box" >
                                                                                    <h4
                                                                                        onClick={(e) => {
                                                                                            history.push(`/profilenew?activeMenu=big4healthworld&slug=${item?.webinarSlug}`)

                                                                                        }}
                                                                                    >{item?.title}</h4>
                                                                                    <p className="mb-0" onClick={(e) => {
                                                                                        history.push(`/profilenew?activeMenu=big4healthworld&slug=${item?.webinarSlug}`)
                                                                                    }}><b>{item?.big_categories?.name}</b></p>
                                                                                    <p className="mb-0" onClick={(e) => {
                                                                                        history.push(`/profilenew?activeMenu=big4healthworld&slug=${item?.webinarSlug}`)
                                                                                    }}><img src={require("../images/update/date.svg").default} alt="img" className="mr-2" />{new Intl.DateTimeFormat('en', DATE_OPTIONS).format(new Date(webinarDate))}</p>
                                                                                    <p className="blue-text mb-0" onClick={(e) => {
                                                                                        history.push(`/profilenew?activeMenu=big4healthworld&slug=${item?.webinarSlug}`)
                                                                                    }}><img src={require("../images/update/language.svg").default} alt="img" className="mr-2" />{item?.language}</p>

                                                                                    {(activeTab === "live" || activeTab === "upcoming") && !isJoinable ? (
                                                                                        <></>
                                                                                    ) : (activeTab === "live" || activeTab === "upcoming") && (
                                                                                        <a
                                                                                            href={item?.webinar_url}
                                                                                            target="_blank"
                                                                                            className="join-button"
                                                                                        >
                                                                                            Join Now
                                                                                        </a>
                                                                                    )}
                                                                                    <Dropdown className="drop-down">
                                                                                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                                                                                            <img src={require("../images/update/three-dot.svg").default} alt="img" className="dot-image" />
                                                                                        </Dropdown.Toggle>
                                                                                        <Dropdown.Menu className="delete-wrap">
                                                                                            <Dropdown.Item className="delet-icon-txt" onClick={(e) => {
                                                                                                let confirmBox = window.confirm("Are you sure you want to delete it")
                                                                                                if (confirmBox) {
                                                                                                    deleteWebinarData(item?._id);
                                                                                                }
                                                                                            }}><img src={require("../images/update/delete.svg").default} classNam="mr-3 " alt="img" className="dot-image delete-red" />Delete</Dropdown.Item>
                                                                                        </Dropdown.Menu>
                                                                                    </Dropdown>
                                                                                </div>
                                                                            </aside>}
                                                                    </>)
                                                                }) :
                                                                    (<>
                                                                        <div className="profile-right-side no-data-profile no-upper-main-data mb-5">
                                                                            <div className="no-data-box">
                                                                                <img src={require("../images/update/no-data.png").default} alt="img" className="mr-2" />
                                                                                <h3>No data found</h3>
                                                                            </div>
                                                                        </div></>)}</div>
                                                            {!location && totalpage > 15 && (
                                                                <div className='col-md-12 text-center'>
                                                                    <div className="pagination1">
                                                                        <Pagination
                                                                            activePage={page}
                                                                            itemsCountPerPage={15}
                                                                            totalItemsCount={totalpage}
                                                                            pageRangeDisplayed={5}
                                                                            onChange={(e) => handlePageChange(e, "NFT")}
                                                                            hideDisabled={true}
                                                                            prevPageText={"Previous"}
                                                                            nextPageText={"Next"}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>}
                                        </div>
                                        {(activeMenu == "big4healthworld" && slug) && <WorldUserdata {...combinedData} />}

                                    </div>

                                </div>
                                <div class={`tab-pane ${selectedMenu == "profile " ? "active" : "fade"}`} id="menu8">
                                    <div className="profile-right-side">
                                        <div className="profile-right-header">
                                            <h4> Subscriber Management</h4>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </aside>
                    </div>
                </div>
            </section>


            <Modal show={show} onHide={handleClose} centered className="change-password-pop">
                <Modal.Header closeButton>
                    <Modal.Title >Change Password</Modal.Title>

                </Modal.Header>
                <Modal.Body>
                    <div className="email-verfication">
                        <h5>Old Password</h5>
                        <input type="password" placeholder="Enter old password" name="oldpassword" onChange={(e) => inputChange(e)} />
                        <h5>New Password</h5>
                        <input type="password" placeholder="Enter new password" name="newPassword" onChange={(e) => inputChange(e)} />
                        <br />
                        <div className="text-center">
                            <button type="button" onClick={handleChangePassword} className="text-center">Update</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal show={showed} onHide={handleClosed} centered className="shared-profile">
                <Modal.Header closeButton>
                    <Modal.Title className="mt-3 share-profile-txt">Share with Healthcare Provider</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="share-profile">
                        <input type="email" placeholder="Enter email" name="email" onChange={(e) => setEmail(e.target.value)} />
                        <div className="share-btn-box">
                            <button type="button" className=""
                                onClick={handleSubmitShare}
                            >Send</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal show={editModal} onHide={() => setEditModal(false)} backdrop="static" className="edit-profile-pop">
                <Modal.Header closeButton><h5>Edit Metric</h5></Modal.Header>
                <Modal.Body>
                    <div>

                        <Form.Group>
                            <label>Height</label>
                            <Form.Control
                                as="select"
                                value={values?.height}
                                onChange={(e) => {
                                    setValues((val) => ({ ...val, height: e.target.value }));
                                    setIsModified(true);
                                }}
                            >
                                <option value="">Select Height</option> Placeholder option
                                {heightOptions.map((op) => (
                                    <option key={op} value={op}>
                                        {op}
                                    </option>
                                ))}
                            </Form.Control>
                        </Form.Group>

                        <div>
                            <label>Weight</label>
                            <InputGroup>
                                {/* <InputGroup> */}
                                <Form.Control
                                    type='number'
                                    placeholder="Weight"
                                    min={1}
                                    className="input103"
                                    value={values?.weight}
                                    onChange={(e) => {
                                        const inputValue = e.target.value;
                                        if (inputValue === '' || (parseInt(inputValue) >= 1)) {
                                            setValues((val) => ({ ...val, weight: inputValue }));
                                            setIsModified(true);
                                        }
                                    }}
                                />
                                {/* </InputGroup> */}
                                <DropdownButton title={values.weightType} align="end" variant="outline-primary" id="weight-unit">
                                    <Dropdown.Item href="#" onClick={() => { setValues((val) => ({ ...val, weightType: "kg" })); setIsModified(true) }}
                                    >kg</Dropdown.Item>
                                    <Dropdown.Item href="#" onClick={() => setValues((val) => ({ ...val, weightType: "lbs" }))}>lbs</Dropdown.Item>
                                </DropdownButton>
                            </InputGroup>
                        </div>
                        <Form.Group className='mt-3'>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <label>Blood Pressure top number</label>
                                    <Form.Control placeholder="" value={values.bloodPressureMax} onChange={(e) => {
                                        if (regExPositiveInt.test(e.target.value) || e.target.value == '') {
                                            setValues((val) => ({ ...val, bloodPressureMax: e.target.value }))
                                            setIsModified(true)
                                        }
                                    }} />
                                </div>
                                <div className='col-md-6'>
                                    <label>Blood Pressure bottom number</label>
                                    <Form.Control placeholder="" value={values.bloodPressureMin} onChange={(e) => {
                                        if (regExPositiveInt.test(e.target.value) || e.target.value == '') {
                                            setValues((val) => ({ ...val, bloodPressureMin: e.target.value }))
                                            setIsModified(true)
                                        }
                                    }} />
                                </div>
                            </div>
                        </Form.Group>
                        <Form.Group>
                            <label>Glucose</label>
                            <InputGroup>
                                <Form.Control type='number' className="input103 " value={values?.glucose == 0 ? "" : values?.glucose} onChange={(e) => {
                                    if (regExPositiveInt.test(e.target.value) || e.target.value == '') {
                                        setValues((val) => ({ ...val, glucose: e.target.value }))
                                        setIsModified(true)
                                    }
                                }} />
                                <Button variant="outline-primary" id="button-addon1">
                                    mg/dl
                                </Button>
                            </InputGroup>
                        </Form.Group>
                        <Form.Group>
                            <label>Cholesterol</label>
                            <InputGroup>
                                <Form.Control type='number' className="input103 " value={values?.cholesterol == 0 ? "" : values?.cholesterol} onChange={(e) => {
                                    if (regExPositiveInt.test(e.target.value) || e.target.value == '') {
                                        setValues((val) => ({ ...val, cholesterol: e.target.value }))
                                        setIsModified(true)
                                    }
                                }} />
                                <Button variant="outline-primary" id="button-addon1">
                                    mg/dl
                                </Button>
                            </InputGroup>
                        </Form.Group>
                        <Form.Group>
                            <div className='text-center'>
                                <button
                                    // disabled={submitting || !isModified}
                                    disabled={submitting}
                                    className='btn btn-primary px-4'
                                    onClick={(e) => {
                                        if (!values?.height) {
                                            Swal.fire("Warning", "Please select height", "warning");
                                        } else if (!values?.weight) {
                                            Swal.fire("Warning", "Please enter Weight", "warning");
                                        } else {
                                            setSubmitting(true);

                                            var parts = values?.height?.split("'");
                                            var feet = parseFloat(parts[0]);
                                            var inches = parts[1] ? parseFloat(parts[1]?.replace('"', '')) : 0;
                                            // Convert feet to inches and add the inches part
                                            var totalInches = (feet * 12) + inches;

                                            userService
                                                .editProfile({
                                                    ...values,
                                                    'height': totalInches,
                                                    "cholesterol": Number(values?.cholesterol),
                                                    "glucose": Number(values?.glucose),

                                                    userId: localStorage.getItem('user_id'),
                                                    'foot': Number(values?.height?.split("'")[0]),
                                                    'inch': values?.height?.split("'")[1] ? Number(values?.height?.split("'")[1]) : 0,
                                                    'weightType': values.weightType,
                                                    'weight': Number(values?.weight),
                                                })
                                                .then((res) => {
                                                    setSubmitting(false);
                                                    setEditModal(false);
                                                    setUser(res.data.data[0]);
                                                    toast.dismiss();
                                                    window.location.reload()
                                                    getProfileList();
                                                    toast.success("Data updated successfully", {
                                                        toastId: 'success_draft_1',
                                                    });
                                                    localStorage.setItem('user', JSON.stringify(res.data.data[0]));
                                                    let data = res.data.data[0].profilePicture;
                                                    setTemp(data);
                                                })
                                                .catch((err) => {
                                                    setSubmitting(false);
                                                    toast.dismiss();
                                                    toast.error("An error occurred", {
                                                        toastId: 'error_draft_1',
                                                    });
                                                });
                                        }
                                    }}
                                >
                                    Update
                                </button>
                            </div>
                        </Form.Group>
                    </div>
                </Modal.Body>
            </Modal>
            <Footer />
            <div class="modal fade description-popup" id="exampleModalCenter11" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLongTitle">Description</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            {fullDescriptionRef.current}     </div>
                        <div class="modal-footer description-btn">
                            <button type="button" class="btn btn-primary " data-dismiss="modal" aria-label="Close">Ok</button>
                        </div>
                    </div>
                </div>
            </div>

            <Modal show={AppointmentCancel} onHide={handleCloseAppointmentCancel}
                aria-labelledby="contained-modal-title-vcenter"
                centered className="quiz-pop quiz-close"
            >
                <Modal.Header className="m-auto q-mark">
                    <Modal.Title>?
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='start-the-quiz text-center p-0'>

                    <p>Are you sure you want to Cancel this appointment?</p>
                    <div className="Signup_box text-center contact-main-box">
                        <Form className="text-left">
                            <label className='text-left'>
                                Reason<span className="mandatory"></span>
                            </label>
                            <textarea
                                className=" w-100 select-main"
                                placeholder="Enter Message"
                                name="message"
                                onChange={(e) => setCancellationReason(e.target.value)}
                                onKeyDown={(e) => {
                                    if (!e.target.value && e.code === "Space") {
                                        e.preventDefault();
                                    }
                                }}

                                maxLength={500}
                            ></textarea>
                        </Form>
                    </div>
                </Modal.Body>
                <Modal.Footer className="border-none m-auto">
                    <Button className='btn notnowbtn' onClick={handleCloseAppointmentCancel}>NO</Button>
                    <Button className='btn quiznow-btn' onClick={(e) => RejectAppointment(RejectedRef.current, "CANCELLED", cancellationReason)}>YES</Button>
                </Modal.Footer>
            </Modal>

            <Modal show={rejectAppointment} onHide={handleRejectAppointment}
                aria-labelledby="contained-modal-title-vcenter"
                centered className="quiz-pop quiz-close"
            >
                <Modal.Header className="m-auto q-mark">
                    <Modal.Title>?
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='start-the-quiz text-center p-0'>

                    <p>Are you sure you want to Reject this appointment?</p>
                    <div className="Signup_box text-center contact-main-box">
                        <Form className="text-left">
                            <label className='text-left'>
                                Reason<span className="mandatory"></span>
                            </label>
                            <textarea
                                className=" w-100 select-main"
                                placeholder="Enter Message"
                                name="message"
                                onChange={(e) => setCancellationReason(e.target.value)}
                                onKeyDown={(e) => {
                                    if (!e.target.value && e.code === "Space") {
                                        e.preventDefault();
                                    }
                                }}

                                maxLength={500}
                            ></textarea>
                        </Form>
                    </div>
                </Modal.Body>
                <Modal.Footer className="border-none m-auto">
                    <Button className='btn notnowbtn' onClick={(e) => setRejectAppointment(false)}>NO</Button>
                    <Button className='btn quiznow-btn' onClick={(e) => RejectAppointment(selectedRejectRef.current, "REJECTED", cancellationReason)}>YES</Button>
                </Modal.Footer>
            </Modal>


            {/* add card model */}
            <Modal show={addcardModel} onHide={handleCloseAddCardModel} centered>
                <Modal.Header closeButton>
                    <Modal.Title><b>Add Card</b></Modal.Title>
                </Modal.Header>
                <Modal.Body className="pt-0">
                    <div className="billing-box-address signin-bg">
                        <div className="delivery-head mt-3">
                        </div>
                        <div className="delivery-body biling-box Signup_box p-0">
                            <form>
                                <div className="row">
                                    <aside class="col-md-6 mb-3">
                                        <div class="input_row">
                                            <label>Card Number</label>
                                            <input type="" placeholder="Card Number" class="input103 w-100" value={cardNo} maxLength={16} onChange={(e) => setCardNo(e.target.value)} />
                                        </div>
                                    </aside>
                                    <aside class="col-md-6 mb-3">
                                        <div class="input_row">
                                            <label>Card Holder Name</label>
                                            <input type="" placeholder="Card Holder Name" class="input103 w-100" value={cardHolderName} onChange={(e) => setCardHolderName(e.target.value)} />
                                        </div>
                                    </aside>
                                    <aside class="col-md-6 mb-3">
                                        <div class="input_row payment-expiry">
                                            <label>Expiry</label>
                                            <DatePicker
                                                selected={expiryDate}
                                                minDate={new Date()}
                                                showMonthYearPicker
                                                dateFormat={"MM/yyyy"}
                                                placeholderText="MM/YYYY"
                                                class="input103 w-100"
                                                onChange={handleExpiryDate}
                                            />
                                        </div>
                                    </aside>
                                    <aside class="col-md-6 mb-3">
                                        <div class="input_row">
                                            <label>CVV</label>
                                            <input type="" placeholder="Enter CVV" class="input103 w-100" value={cvv} maxLength={4} onChange={(e) => setCvv(e.target.value)} />
                                        </div>
                                    </aside>
                                </div>
                                <div className="next_btn text-center mt-4">
                                    <Button
                                        variant="secondary"
                                        type="button"
                                        className="btn w-25"
                                        onClick={() => validateForm()}
                                    >
                                        Add
                                    </Button>
                                </div>
                            </form>
                        </div>
                    </div>
                </Modal.Body>

            </Modal>
        </>
    );
};
export default ProfileUpdated;