
import React, { useState, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { Form, fieldset } from 'react-bootstrap';
import Timer from './common/Timer'
import { userService } from '../services'
import { toast } from 'react-toastify';
import Loader from './common/Loader'
import { config } from '../config/config'
import ShareOnSocialMedia from './common/ShareOnSocialMedia'
import Header from './common/Header'
import Footer from './common/Footer'
import { useHistory } from 'react-router';
import Download from './common/Download';
import Swal from 'sweetalert2';

const Quiz = (props) => {

    const [currentStep, setCurrentStep] = useState(1);
    const [showHide, setShowHide] = useState(false);
    const [quizId, setQuizId] = useState({});
    const [quizData, setQuizData] = useState({});
    const [quizTime, setQuizTime] = useState('');
    const [totalQuestions, setTotalQuestions] = useState(0);
    const [collectedAnswer, setCollectedAnswer] = useState({});
    const [answerList, setAnswerList] = useState([]);
    const [rightAnswers, setRightAnswers] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [isAutoSubmit, setIsAutoSubmit] = useState(true);
    const [quizCompletionTime, setQuizCompletionTime] = useState('');
    const [maxQuizTime, setMaxQuizTime] = useState(120);
    const history = useHistory()
    const [status, setStatus] = useState({})



    useEffect(() => {
        if (localStorage.getItem('done') === "true") window.location.href = '/';
        localStorage.setItem('done', true);
        let query = new URLSearchParams(window.location.search);
        setQuizId(query.get('id'));
        getQuizData(query.get('id'));
    }, []);

    function handleQuit(e) {
        const quit = window.confirm("Are you sure, you want to quit? You won't be able to play it again.")
        if (quit) {
            history.push("/health-app")
        }
    }

    function getQuizData(id) {
        setIsLoading(true);
        userService.getQuiz(id).then((response) => {
            if (response.data.status === 200) {
                let data = response.data.data;
                let tmpArry = [];
                let tmpArry2 = [];
                setQuizData(data);
                setTotalQuestions(data.questions.length);
                for (let i = 0; i < data.questions.length; i++) {
                    tmpArry.push({ quesId: data.questions[i]._id, answer: 0 });
                    tmpArry2.push(false);
                }
                setAnswerList(tmpArry2);
                setCollectedAnswer({ quizId: id, questions: tmpArry });
                setIsLoading(false);
                setTimeout(() => {
                    let time = new Date();
                    setMaxQuizTime(120);
                    setQuizTime(time.setSeconds(time.getSeconds() + 120));
                }, 300);
            } else {
                setIsLoading(false);
                setQuizData({});
                setQuizTime(0);
                toast.error("Some Error Occur");
            }
        }).catch((error) => {
            setIsLoading(false);
            setQuizData({});
            setQuizTime(0);
            toast.error("Some Error Occur");
        });
    }

    function handleModalShowHide1() {
        setShowHide(true);
    }


    const _next = () => {
        if (collectedAnswer.questions[currentStep - 1].answer === 0) {
            toast.error("Please choose the answer")
        } else {
            setCurrentStep(currentStep >= totalQuestions - 1 ? totalQuestions : currentStep + 1);
        }
    }

    function nextButton() {
        if (currentStep < totalQuestions) {
            return (
                <div className="next_btn text-center my-4">
                    <a className="btn" type="button" onClick={_next}>Next</a>
                </div>
            )
        } else {
            return (
                <div className="next_btn text-center my-4">
                    <a className="btn" onClick={() => onSubmitBeforeTimeout()}>SUBMIT</a>
                </div>)
        }
    }


    function getFormatedTime(timeInSeconds) {
        let diff = maxQuizTime - timeInSeconds;
        let minutes = Math.floor(diff / 60);
        let seconds = diff % 60;

        // Add leading zero if needed
        let formattedMinutes = minutes < 10 ? '0' + minutes : minutes;
        let formattedSeconds = seconds < 10 ? '0' + seconds : seconds;
        let tmpTime = formattedMinutes + ':' + formattedSeconds;
        setQuizCompletionTime(tmpTime);
        return tmpTime;
    }


    function onTimeExpire() {

        Swal.fire("Warning", "Your quiz session has concluded. Unfortunately, no further attempts can be made.", "warning").then((res) => {
            window.location.href = '/health-app';
        })
    }

    function onSubmitBeforeTimeout() {
        if (collectedAnswer.questions[currentStep - 1].answer === 0) {
            toast.error("Please choose the answer")
        } else {
            document.getElementById("pause-timer").click();
            let leftSeconds = Number(document.getElementById('pause-timer').innerHTML);
            setIsAutoSubmit(false);
            calculateResult(getFormatedTime(leftSeconds));
        }
    }

    function calculateResult(completionTime) {
        setIsLoading(true);
        let tmpArry = [];
        answerList.map((item, i) => { if (item) tmpArry.push(true) });
        setRightAnswers(tmpArry.length);
        submitQuiz(tmpArry.length, completionTime);
    }

    function submitQuiz(score, timeToComplete) {
        collectedAnswer.score = score;
        collectedAnswer.timeToComplete = timeToComplete;
        collectedAnswer.userId = localStorage.getItem('user_id');
        userService.submitQuiz(collectedAnswer).then((response) => {
            setIsLoading(false);
            if (response.data.status === 200) {
                setStatus(response.data)
                handleModalShowHide1();
            } else {
                toast.error("Some Error Occur");
            }
        }).catch((error) => {
            setIsLoading(false);
            toast.error("Some Error Occur");
        });
    }

    function handleAnswerOptions(answerId, questionId, index, isRight) {
        let tmp = collectedAnswer
        let tmp2 = answerList;
        tmp.questions[index] = { quesId: questionId, answer: answerId };
        tmp2[index] = isRight;
        setCollectedAnswer(tmp);
        setAnswerList(tmp2);
    }

    const handleBackButtonClick = () => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'Would you like to return, as you won\'t be able to attempt this quiz again?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, return!',
            cancelButtonText: 'No, cancel',
        }).then((result) => {
            if (result.isConfirmed) {
                window.location.href = "/health-app";
            }
        });
    };

    return (
        <div>
            <Header />
            {isLoading && <Loader />}
            <React.Fragment>
                <section className="quiz_section py-4">
                    <div className="back-btn-sec mb-3">
                    </div>
                    <div className="container">
                        <div className="back-btn-sec mb-3">
                            <Button className="btn back-btn"
                                onClick={(e) => {
                                    e.preventDefault();

                                    Swal.fire({
                                        title: 'Warning',
                                        text: 'Would you like to return, as you won\'t be able to attempt this quiz again?',
                                        icon: 'warning',
                                        showCancelButton: true,
                                        confirmButtonText: 'Yes, return!',
                                        cancelButtonText: 'Cancel',
                                    }).then((result) => {
                                        if (result.isConfirmed) {
                                            window.location.href = `/health-app?quiz=${true}`;
                                        }
                                    });
                                }}
                            ><i className="fa fa-angle-left mr-2"></i>Back to Health App</Button>
                        </div>
                        {quizData.questions && quizData.questions.length > 0 &&
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="row mb-3 align-items-center">
                                        <div className="col-sm-6 mob_col1">

                                            <h1 className="mb-0">Question <span>{currentStep}</span> of {totalQuestions}</h1>
                                        </div>
                                        <div className="col-sm-6 mob_col">
                                            <div className="time_box">
                                                <p>Time Left</p>
                                                {quizTime && <Timer expiryTimestamp={quizTime} onTimeExpire={() => onTimeExpire()} />}
                                            </div>
                                            <div style={{ background: "red", borderRadius: "4px", margin: "5px 0 0 auto", width: "fit-content" }}>
                                                <p style={{ color: "white", cursor: "pointer", padding: "1px 7px" }} onClick={handleQuit}>
                                                    Close Quiz
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <form>
                                        {quizData.questions && quizData.questions.length > 0 && quizData.questions.map((question, i) => {
                                            return (<Steps questionInfo={question} step={i + 1} />)
                                        })}
                                        {nextButton()}

                                    </form>
                                </div>
                            </div>
                        }
                    </div>
                </section>

                <Download />
                <Modal show={showHide} className="quizmodal1"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header className="border-0 mb-1 pb-1">
                        <Modal.Title>
                            <div className="check_box">
                                <img src={require("../images/check2.png").default} alt="img" />
                            </div>
                            <h4 className="mt-3">Congratulations!</h4>
                            <p className="pb-0 mb-3">You have won ${status.amount} in your BIG4 Health wallet.</p>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="container">
                            <div className="row py-3">
                                <div className="col-md-6 result">
                                    <h6>Result</h6>
                                    <p>{rightAnswers} of {totalQuestions}</p>
                                </div>
                                <div className="col-md-6 result">
                                    <h6>Time Spent</h6>
                                    <p>{quizCompletionTime}</p>
                                </div>
                            </div>
                            <div className="row pb-2 mt-3">
                                <div className="col-md-6 mb-3 redeem_btn">
                                    <a className="btn" href="/offer">REDEEM</a>

                                </div>
                                <div className="col-md-6 mb-3 share_btn">
                                    <a className="btn" href="/health-app">GO TO HEALTH APP</a>
                                </div>
                                <div className="col-md-12 mb-2 share_btn">
                                    <a className="btn" href={"/result?id=" + quizId}>Check your Answers</a>
                                </div>
                                
                                <ShareOnSocialMedia url={window.location.href} text="Big4Health - Quiz" quizClass="share-Quiz" />
                            </div>
                        </div>

                    </Modal.Body>
                </Modal>

            </React.Fragment>
            <Footer />
        </div>

    );

    function Steps(props) {
        if (currentStep !== props.step) {
            return null
        }
        return (
            <div className="qstns_box">
                <h4>{props.questionInfo.title}</h4>
                <p className="mb-0 text-secondary mt-3">Choose Answer</p>
                {(props.questionInfo.answers[0].type === 'text') ?
                    <div className="answer_box mt-2">
                        <fieldset>
                            <Form.Group>
                                <Form.Label as="legend">
                                </Form.Label>
                                {props.questionInfo.answers.map((option, i) => {
                                    return (<Form.Check
                                        type="radio"
                                        label={option.value}
                                        name="formHorizontalRadios"
                                        id={`formHorizontalRadios${i}`}
                                        onClick={() => handleAnswerOptions(option._id, props.questionInfo._id, props.step - 1, option.isRight)}
                                    />)
                                })}
                            </Form.Group>
                        </fieldset>
                    </div>
                    :
                    <div className="answer_box mt-2 py-4">
                        <div className="row">
                            {props.questionInfo.answers.map((option, i) => {
                                return (<div className="col-sm-3 text-center">
                                    <div className="select_image">
                                        <img src={config.imageUrl + option.value} alt="img" style={{ objectFit: 'cover' }} />
                                        <span> <Form.Check
                                            type="radio"
                                            name="formHorizontalRadios"
                                            id={`formHorizontalRadios${i}`}
                                            onClick={() => handleAnswerOptions(option._id, props.questionInfo._id, props.step - 1, option.isRight)}
                                        /></span>
                                    </div>
                                </div>)
                            })}
                        </div>
                    </div>
                }
            </div>
        );
    }

}
export default Quiz;

