import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import About from "./Components/About";
import AboutApp from "./Components/AboutApp";
import Blog from "./Components/Blog";
import Blog_details from "./Components/Blog_details";
import Cancelled from "./Components/Cancelled";
import Cards from "./Components/Cards";
import Cart from "./Components/Cart";
import Checkout from "./Components/Checkout";
import Signup from "./Components/common//Signup";
import Signup2 from "./Components/common//Signup2";
import Copyright from "./Components/common/Copyright";
import Forgotpassword from "./Components/common/Forgotpassword";
import PrivacyPolicy from "./Components/common/PrivacyPolicy";
import PrivacyPolicyApp from "./Components/common/PrivacyPolicyApp";
import BillingTerms from "./Components/common/BillingTerms";
import SignIn from "./Components/common/Signin";
import Term from "./Components/common/Term";
import TermApp from "./Components/common/TermApp";
import Contact from "./Components/Contact";
import Details from "./Components/Details";
import FAQ from "./Components/FAQ";
import Foodoption from "./Components/Foodoption";
import Home from "./Components/Home";
import My_favorites from "./Components/My_favorites";
import My_orders from "./Components/My_orders";
import Notification from "./Components/Notification";
import Offer from "./Components/Offer";
import Packages from "./Components/Packages";
import Past_order from "./Components/Past_order";
import Payment from "./Components/Payment";
import Product from "./Components/Product";
import Product_details from "./Components/Product_details";
import Product_search from "./Components/Product_search";
import Profile from "./Components/Profile";
import Quiz from "./Components/Quiz";
import Redeem from "./Components/Redeem";
import Result from "./Components/Result";
import Reviews from "./Components/Reviews";
import Shipping from "./Components/Shipping";
import Shop from "./Components/Shop";
import Subscriptions from "./Components/Subscriptions";
import ReturnPolicy from "./Components/ReturnPolicy";
import ReturnPolicyApp from "./Components/ReturnPolicyApp";
import ReferralCode from "./Components/common/ReferralCode";
import SignupMain from "./Components/common/SignupMain";
import VerifyEmail from "./pages/VerifyEmail";
import Big_med from "./Components/Big_med";
import Create_post_form from "./Components/Create_post_form";
import Big_med_details from "./Components/Big_med_details";
import Reset from "./Components/common/Reset";
import ProfileUpdated from "./Components/ProfileUpdated";
import Home_new from "./Components/Home_new";
import Business from "./Components/Business";
import Health_app from "./Components/Health_app";
import Other_Profile from "./Components/Other_Profile";
import { useEffect, useRef } from "react";
import Edit_profile from "./Components/common/Edit_profile";
import Quiz_new from "./Components/Quiz_new";
import Ingredient from "./Components/Ingredient";
import ShoppingCart from "./Components/ShoppingCart";
import CheckoutUpdated from "./Components/CheckoutUpdated";
import CreateWorld from "./Components/CreateWorld";
import WorldListing from "./Components/WorldListing";
import WorldDetails from "./Components/WorldDetails";
import ELearning from "./Components/ELearning";
import ELearningDetail from "./Components/ELearningDetail";
import Exercise from "./Components/Exercise";
import ExerciseListing from "./Components/ExerciseListing";
import ExerciseDetails from "./Components/ExerciseDetails";
import Chat from "./Components/Chat";
import Wellness from "./Components/Wellness";
import MyExercise from "./Components/MyExercise";
import ExerciseCount from "./Components/ExerciseCount";
import ScheduleListing from "./Components/ScheduleListing";
import AppointmentListing from "./Components/AppointmentListing";
import HealthMD from "./Components/HealthMD";
import { countryLanguageMapping } from "./Components/common/LanguageList";
import OrderDetails from "./Components/OrderDetails";
import HealthMDDetails from "./Components/HealthMDDetails";
import VideoCall from "./pages/VideoCall";
function App() {
  useEffect(() => {
    makeAPICall();
  }, [])

  // Function to make an API call and update the page with the response
  function makeAPICall() {
    let userCountry = '';
    fetch('https://api.ipify.org?format=json')
      .then(response => response.json())
      .then(data => {

        fetch(`https://ipapi.co/${data?.ip}/json`)
          .then(response => response.json())
          .then(data => {
            userCountry = data.country_code;
            if (localStorage.getItem("userLaguage") == userCountry) {
              return;
            }
            localStorage.setItem("userLaguage", userCountry);
            const userLanguage = countryLanguageMapping[userCountry] || 'en'; // Default to English if not found
            setCookie('googtrans', `/en/${userLanguage}`, 1);
            window.google.translate.TranslateElement({ pageLanguage: 'ES', layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE }, 'google_translate_element');
          })
      })
      .catch(error => {
      });
  }






  function setCookie(key, value, expiry) {
    var expires = new Date();
    expires.setTime(expires.getTime() + (expiry * 24 * 60 * 60 * 1000));
    document.cookie = key + '=' + value + ';expires=' + expires.toUTCString();
  }

  useEffect(() => {
    // Function to initialize Google Translate Element
    window.googleTranslateElementInit = () => {
      new window.google.translate.TranslateElement(
        "google_translate_element"
      );
      const dropdown = document.querySelector(".goog-te-combo");
      dropdown.addEventListener("change", handleLanguageChange);
    };

    // Check if the script has already been added
    if (!window.google || !window.google.translate) {
      // Load the Google Translate API script
      const script = document.createElement("script");
      script.src = "https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit";
      script.async = true;
      document.head.appendChild(script);
    } else {
      // If the script is already present, manually call the initialization function
      window.googleTranslateElementInit();
    }
  }, []);


  // Handle language selection event
  const handleLanguageChange = (e) => {
    const selectedLanguage = e.target.value;
    // Do something with the selectedLanguage, like translating your content
    // // console.log(`Selected Language: ${selectedLanguage}`);
    setCookie('googtrans', `/en/${selectedLanguage}`, 1);
    window.google.translate.TranslateElement({ pageLanguage: 'ES', }, 'google_translate_element');

  };


  return (
    <>
      <ToastContainer />
      {/* <div id="google_translate_element"></div> */}
      <Router>
        <Switch>
          <Route path="/home-new" component={Home} />
          <Route exact path="/" component={Home_new} />
          <Route path="/signin" component={SignIn} />
          <Route path="/referralCode/:id" component={ReferralCode} />
          <Route path="/forgotpassword" component={Forgotpassword} />
          <Route path="/create-profile" component={Signup} />
          <Route path="/signup2" component={Signup2} />
          <Route path="/profle-old" component={Profile} />
          <Route path="/about" component={About} />
          <Route path="/about-app" component={AboutApp} />
          <Route path="/foodoption" component={Foodoption} />
          <Route path="/faq" component={FAQ} />
          <Route path="/contact" component={Contact} />
          <Route path="/quiz" component={Quiz} />
          <Route path="/result" component={Result} />
          <Route path="/redeem" component={Redeem} />
          <Route path="/Offer" component={Offer} />
          <Route path="/shop" component={Shop} />
          <Route path="/product" component={Product} />
          <Route path="/product_search" component={Product_search} />
          <Route path="/my_favorites" component={My_favorites} />
          <Route path="/Reviews" component={Reviews} />
          <Route path="/Product_details" component={Product_details} />
          <Route path="/blog" component={Blog} />
          <Route path="/packages" component={Packages} />
          <Route path="/cards" component={Cards} />
          <Route path="/Subscriptions" component={Subscriptions} />
          <Route path="/cart" component={Cart} />
          <Route path="/checkout" component={Checkout} />
          <Route path="/shipping" component={Shipping} />
          <Route path="/blog_details" component={Blog_details} />
          <Route path="/privacy-policy" component={PrivacyPolicy} />
          <Route path="/privacy-policy-app" component={PrivacyPolicyApp} />
          <Route path="/billing-terms" component={BillingTerms} />
          <Route path="/copyright" component={Copyright} />
          <Route path="/terms" component={Term} />
          <Route path="/terms-app" component={TermApp} />
          <Route path="/payment" component={Payment} />
          <Route path="/my_orders" component={My_orders} />
          <Route path="/details" component={Details} />
          <Route path="/past_order" component={Past_order} />
          <Route path="/cancelled" component={Cancelled} />
          <Route path="/notifications" component={Notification} />
          <Route path="/return-policy" component={ReturnPolicy} />
          <Route path="/return-policy-app" component={ReturnPolicyApp} />
          <Route path="/register" component={SignupMain} />
          <Route path="/emailverify" component={VerifyEmail} />
          <Route path="/sign-up-main" component={SignupMain} />
          <Route path="/big-med" component={Big_med} />
          <Route path="/big-me" component={Big_med} />
          <Route path="/create-post-form" component={Create_post_form} />
          <Route path="/big-med-details" component={Big_med_details} />
          <Route path="/big-me-details" component={Big_med_details} />
          <Route path="/reset" component={Reset} />
          <Route path="/profilenew" component={ProfileUpdated} />
          <Route path="/profile/:slug" component={Other_Profile} />
          <Route path="/business" component={Business} />
          <Route path="/health-app" component={Health_app} />
          <Route path="/edit-profile" component={Edit_profile} />
          <Route path="/quiz-new" component={Quiz_new} />
          <Route path="/ingredient" component={Ingredient} />
          <Route path="/shopping-cart" component={ShoppingCart} />
          <Route path="/checout-updated" component={CheckoutUpdated} />
          <Route path="/create-world" component={CreateWorld} />
          <Route path="/world-listing" component={WorldListing} />
          <Route path="/world-details" component={WorldDetails} />
          <Route path="/e-learning" component={ELearning} />
          <Route path="/e-learning-details" component={ELearningDetail} />
          <Route path="/exercise" component={Exercise} />
          <Route path="/exercise-listing" component={ExerciseListing} />
          <Route path="/exercise-details" component={ExerciseDetails} />
          <Route path="/chat" component={Chat} />
          <Route path="/wellness" component={Wellness} />
          <Route path="/my-exercise" component={MyExercise} />
          <Route path="/exercise-count" component={ExerciseCount} />
          <Route path="/schedule-listing" component={ScheduleListing} />
          <Route path="/appointment-listing" component={AppointmentListing} />
          <Route path="/health-md" component={HealthMD} />
          <Route path="/order-details" component={OrderDetails} />
          <Route path="/health-md-details" component={HealthMDDetails} />
          <Route path="/videocall" component={VideoCall} />

          <Redirect from="*" to="/" />
        </Switch>
      </Router>
    </>
  );
}

export default App;
