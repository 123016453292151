import React from "react";

const Footer = () => {
  return (
    <>
      <footer className="pt-5 redesign-footer">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-4 footer-logo-img">
              <img src={require("../../images/update/logo.png").default} alt="img" />
            </div>
            <div className="col-lg-6 col-md-5">
              <h6>Company</h6>
              <div className="row">
                <aside className="col-md-6">
                  <ul className="footer-tab">
                    <li>
                      <a target="_blank" href="/">
                        Home
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="/about">
                        About Us
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="/">
                        Benefits
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="/">
                        Foundations
                      </a>
                    </li>
                  </ul>
                </aside>
                <aside className="col-md-6">
                  <ul className="footer-tab">
                    <li>
                      <a target="_blank" href="/faq">
                        FAQ
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="/blog">
                        Blogs
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="/contact">
                        Contact Us
                      </a>
                    </li>
                  </ul>
                </aside>
              </div>
            </div>
            <div className="col-lg-2 col-md-3 footer-use-term">
              <h6>Use of Terms</h6>
              <ul className="footer-tab">
                <li>
                  <a target="_blank" href="/return-policy">
                  Return Policy
                  </a>
                </li>
                <li>
                  <a target="_blank" href="/privacy-policy">
                    Privacy Policy
                  </a>
                </li>
                <li>
                  <a target="_blank" href="/copyright">
                      Copyright Notice
                  </a>
                </li>
                <li>
                  <a target="_blank" href="/terms">
                  Terms of Use
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
      <footer className="bottom-footer">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-4">
              <ul className="m-0 p-0">
                <li>
                  <a target="_blank" href="https://fb.com">
                    <i className="fa fa-facebook"></i>
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://twitter.com">
                    <i className="fa fa-twitter"></i>
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://instagram.com">
                    <i className="fa fa-instagram"></i>
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://youtube.com">
                    <i className="fa fa-youtube"></i>
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-md-8">
              <p className="text-light p-0 m-0 text-center">
                Copyright © {new Date().getFullYear()} Health App. All Rights Reserved
              </p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
