import Header from "./common/Header";
import Footer from "./common/Footer";
import Swal from "sweetalert2";
import { useEffect, useState } from "react";
import Pagination from "react-js-pagination";
import { userService } from "../services";
import Loader from "./common/Loader";
export const DATE_OPTIONS = {
  month: 'long',
  day: '2-digit',
  year: 'numeric',
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
  timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
}
const WorldListing = () => {
  const [isLoading, setIsLoading] = useState(false);
  
  const [activeTab, setActiveTab] = useState(localStorage.getItem("webnairType") ?? 'live');
  const [webnairdata, setWebnairData] = useState()
  const [user_id, setUserId] = useState(localStorage.getItem("user_id") ? localStorage.getItem("user_id") : "")
  const [page, setPage] = useState(1);
  const [totalpage, settotalpage] = useState();
  const [userDetail, setUserDetail]=useState("")

  useEffect(() => {
    WebinarList(page, activeTab)
    getProfileList()
    const storedTab = localStorage.getItem("webnairType");
    if (storedTab) {
      setActiveTab(storedTab);
    }
  }, [])

  function WebinarList(page, activeTab) {
    setIsLoading(true)
    userService.webnair_list((page -1), activeTab).then((res) => {
      setWebnairData(res?.data?.data)
      settotalpage(res?.data?.count)
      setIsLoading(false)
    })
      .catch((Error) => {
        setIsLoading(false)
      })
  }

  const handleTabClick = (tab) => {
    if(activeTab == tab){
      return;
    }
    setActiveTab(tab);
    localStorage.setItem("webnairType", tab);
    setPage(1);
    if (tab === "live") {
      WebinarList(1, tab)
    }
    else if (tab === "upcoming") {
      WebinarList(1, tab)
    }
    else if (tab === "past") {
      WebinarList(1, tab)
    }
  };

  function handlePageChange(pageNumber) {
    if (activeTab == "live") {
      setPage(pageNumber);
      WebinarList(pageNumber, activeTab)
    }
    if (activeTab == "upcoming") {
      setPage(pageNumber);
      WebinarList(pageNumber, activeTab)
    }
    if (activeTab == "past") {
      setPage(pageNumber);
      WebinarList(pageNumber, activeTab)
    }

    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 1000);
  }

  function getProfileList() {
    let user_id = localStorage.getItem('user_id');
    let token =localStorage.getItem("token")
    let params = { userId: user_id, token:token }
    // let params = { userId: user_id }
    userService.getProfile(params).then(res => {
      if (res.data.status == 200) {
        let userData = res.data.data;
        setUserDetail(userData)
        localStorage.setItem("qb_user_id", userData?.qb_user_id)
      }
    }).catch((error) => {
    });
  }


  return (
    <>
      <Header />
      {isLoading && <Loader />}
      <div className="big-med world-big-med">
        <div className="container">
          <div class="big-med-heading d-flex align-items-start justify-content-between w-100">
            <h1> BIG4 Health World, a worldwide open healthcare provider & client platform - joining in webinars</h1>
            {localStorage.getItem("userType") == "Professional" && <a 
            onClick={(e) =>{
              if(userDetail?.ProfileCreated ==0){
                  Swal.fire("Warning","Your profile is incomplete. Please take a moment to fill in the required details.","warning").then((e) =>{
                      window.location.href ="/create-profile";
                      let redirectUri = window.location.pathname + window.location.search;
                      localStorage.setItem("redirectUri", redirectUri);
                  })
              }
              else{
                  window.location.href="/create-world";
              }
             }} 
             className="btn create"><img src={require("../images/update/create-vid-icon.svg").default} alt="img" />Create</a>}
          </div>
        </div>
      </div>
      <div className="world-box">
        <div className="container">
          <ul class="nav nav-tabs">
            <li class="nav-item">
              <a 
              className={`nav-link ${activeTab === 'live' ? 'active' : ''}`}
               onClick={(e) => {handleTabClick('live');  localStorage.setItem("webnairType", "live");}} data-toggle="tab" href="#world-1">Live  webinars</a>
            </li>
            <li class="nav-item">
              <a 
              className={`nav-link ${activeTab === 'upcoming' ? 'active' : ''}`}
              onClick={(e) =>{ handleTabClick('upcoming');  localStorage.setItem("webnairType", "upcoming") }} data-toggle="tab" href="#world-1">Upcoming  webinars</a>
            </li>
            <li class="nav-item">
              <a 
              className={`nav-link ${activeTab === 'past' ? 'active' : ''}`}
              onClick={(e) => {handleTabClick('past');  localStorage.setItem("webnairType", "past")}} data-toggle="tab" href="#world-1">Past  webinars</a>
            </li>
          </ul>

          <div class="tab-content">
            <div class="tab-pane container active" id="world-1">
              <div className="webinar-outer-box">
                <div className="row">
                  {webnairdata?.length > 0 ? webnairdata?.map((item, key) => {
                    const webinarDate = new Date(item?.date);
                    const currentTime = new Date();
                    const timeDifference = webinarDate - currentTime;
                    const isJoinable = timeDifference <= 3600000;
                    return (
                      <aside className="col-lg-4 col-md-6 col-sm-6 col-12">
                        <div className="webinar-inner-box" >
                          <div>
                          <h4 onClick={(e) => window.location.href = `/world-details/${item?.webinarSlug}`}>{item?.title}</h4>
                          <p className="mb-0" onClick={(e) => window.location.href = `/world-details/${item?.webinarSlug}`}><b>{item?.big_categories?.name}</b></p>
                          <p className="mb-0" onClick={(e) => window.location.href = `/world-details/${item?.webinarSlug}`}><img src={require("../images/update/date.svg").default} alt="img" className="mr-2" />{new Intl.DateTimeFormat('en', DATE_OPTIONS).format(new Date(webinarDate))}</p>
                          <p className="blue-text mb-0" onClick={(e) => window.location.href = `/world-details/${item?.webinarSlug}`}><img src={require("../images/update/language.svg").default} alt="img" className="mr-2" />{item?.language}</p>
                          </div>
                       
                          {(activeTab === "live" || activeTab === "upcoming") && !isJoinable ? (
                            <></>
                          ) : (activeTab === "live" || activeTab === "upcoming") && (
                            <a
                              href= "javascript:;"
                              onClick={(e) => {
                                if(localStorage?.getItem("user_id")) {
                                   window.open(item?.webinar_url,"_blank");
                                }else{
                                  Swal.fire('warning',"To perform this action login is required","warning").then((e)=>{
                                    window.location.href = '/signin';
                                  })
                                }
                            }}
                              className="join-button"
                            >
                              Join Now
                            </a>
                          )}
                        </div>
                      </aside>)
                  }) :
                    (<>
                      <div className="profile-right-side no-data-profile no-upper-main-data mb-5">
                        <div className="no-data-box">
                          <img src={require("../images/update/no-data.png").default} alt="img" className="mr-2" />
                          <h3>No data found</h3>
                        </div>
                      </div></>)}</div>
                  {totalpage > 15 && (
                    <div className='col-md-12 text-center'>
                    <div className="pagination1">
                      <Pagination
                        activePage={page}
                        itemsCountPerPage={15}
                        totalItemsCount={totalpage}
                        pageRangeDisplayed={5}
                        onChange={(e) => handlePageChange(e)}
                        hideDisabled={true}
                        prevPageText={"Previous"}
                        nextPageText={"Next"}
                      />
                    </div>
                    </div>
                  )}
              </div>
            </div>
          </div>
        </div>

      </div>
      {/* contact us section start */}

      <Footer />

    </>
  );
};
export default WorldListing;
