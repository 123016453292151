import React, { useState, useEffect } from 'react'

import { Swiper, SwiperSlide, Navigation, } from 'swiper/react';
import { Modal } from 'react-bootstrap';
import SwiperCore, { Pagination, Autoplay, } from 'swiper';
import { userService } from '../services';
import { toast } from 'react-toastify';
import Loader from './common/Loader'
import { config } from '../config/config'
import Header from './common/Header'
import Footer from './common/Footer'
import { useHistory } from 'react-router-dom';
import Magnifier from "react-magnifier";
import Download from './common/Download';
import ShareOnSocialMedia from './common/ShareOnSocialMedia';

const Product_details = () => {

    const history = useHistory()
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [productDetail, setProductDetail] = useState({});
    const [productId, setProductId] = useState('');
    const [previewImage, setPreviewImage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [quantity, setQuantity] = useState(1);
    const [isFavourite, setIsFavourite] = useState(false);
    const [userId, setUserId] = useState('');
    const [lineItemId, setLineItemId] = useState('');
    const [cartCount, setCartCount] = useState('');
    const [size, setSize] = useState('');
    const [color, setColor] = useState('');
    const [favCount, setFavCount] = useState('');
    const [proceedStatus, setProceedStatus] = useState('');
    const [isCheckout, setIsCheckout] = useState(false);
    const [subIndex, setSubIndex] = useState(0);
    const [subs, setSubs] = useState([]);
    const [btnStatus, setBtnStatus] = useState('');
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get('id');
    const [haveSubscription, setHaveSubscription] = useState('');

    function checkScrollTop() {
    }

    useEffect(() => {
        let user_id = localStorage.getItem('user_id');
        if (user_id) setUserId(user_id);

        if (id) {
            setIsLoading(true);
            setProductId(id);
            getProductDetail(id);
            getCartProducts(id);
            setTimeout(() => {
                subscriptionDetails(user_id)
            }, 500);
        }
    }, []);

    const subscriptionDetails = (user_id) => {
        if (id) {
            userService.userSubsciptions(user_id).then(response => {
                let status = response.data.status;
                if (status == 200) {
                    response.data.data.map(item => {
                        if (item.product._id == id) {
                            setHaveSubscription(false)
                        }
                    })
                }
            }).catch((error) => {
            });

        }
    }
    function getProductDetail(id) {
        userService.getProductDetail(id).then((response) => {
            setIsLoading(false);
            if (response.data.status == 200) {
                setProductDetail(response.data.data);
                setColor(response?.data?.data?.color?.length > 0 ? response?.data?.data?.color[0] : '')
                setSize(response.data.data.size.length > 0 ? response.data.data.size[0] : '')
                setIsFavourite(response.data.data.is_favourite);
                setSubs(response.data.subscriptions)
                setHaveSubscription(response.data.data.category.haveSubscription)
            } else {
                setProductDetail('');
                toast.error("Some Error Occur");
            }
        }).catch((error) => {
            setIsLoading(false);
            setProductDetail('');
        });
    }

    function getCartProducts(id) {
        setIsLoading(true);
        userService.getCartProducts().then((res) => {
            if (res.status === 200) {
                setBtnStatus('')
                if (res.data.cart?.products) {
                    setIsLoading(false);
                    let contArr = res.data.cart.lineitems.filter(item => id == item.product._id)
                    if (contArr.length > 0) {
                        setBtnStatus('Go')
                        setLineItemId(contArr[0]._id)
                        setQuantity(contArr[0].qty)
                    }
                }
            }
        })
    }

    function handleQuantity(type) {
        if (btnStatus) {
            if (type === 'INC' && quantity < productDetail?.quantity) {
                updateQuantity(type)
            } else if (type === 'DEC' && quantity > 1) {
                updateQuantity(type)
            } else if (type === 'DEC' && quantity == 1) {
                updateQuantity('DEL')
            }
        } else {
            if (type === 'INC' && quantity < productDetail?.quantity) {
                setQuantity(quantity + 1)
            } else if (type === 'DEC' && quantity > 1) {
                setQuantity(quantity - 1)
            }
        }
    }

    function updateQuantity(type) {
        setIsLoading(true);
        let params = { type: type, lineitem: lineItemId }
        userService.updateQuantity(params).then((response) => {
            setIsLoading(false);
            if (response.data.status == 200) {
                getCartProducts(id);
            } else {
                toast.error("Some Error Occur");
            }
        }).catch((error) => {
            setIsLoading(false);
        });
    }

    function handleFavourite(status) {
        if (userId) {
            var answer = true;
            if (status) answer = window.confirm("Are you sure you want to remove it from your favourite list.");
            if (answer) {
                setIsLoading(true);
                let params = { user: userId, product: productId, is_favourite: !status }
                userService.updateFavourite(params).then((response) => {
                    setIsLoading(false);
                    if (response.data.status == 200) {
                        setFavCount(response.data.favCount);
                        setIsFavourite(!status);
                        toast.success("Product " + (!status ? "added to" : "removed from") + " your favourite list successfully.");
                    } else {
                        toast.error("Some Error Occur");
                    }
                }).catch((error) => {
                    setFavCount('')
                    setIsLoading(false);
                    // console.log("error ", error);
                });
            }
        } else {
            window.location.pathname = '/signin'
        }
    }

    function addToCart() {
        setShow(false)
        if (userId) {
            setIsLoading(true);
            let params = { user: userId, product: productId, qty: quantity, subscription_type: subIndex, subscription: subIndex != 0, color, size }
            userService.addToCart(params).then((response) => {
                setIsLoading(false);
                if (response.data.status == 200) {
                    setCartCount(response.data.cartCount);
                    toast.success("Product added to cart successfully.");
                    setBtnStatus('GO');
                    getCartProducts(id)
                    if (isCheckout) {
                        history.push('checkout')
                    }
                } else {
                    setCartCount('');
                    toast.error("Some Error Occur");
                }
            }).catch((error) => {
                setIsLoading(false);
            });
        } else {
            localStorage.setItem('redirectUri', window.location)
            window.location.pathname = '/signin'
        }
    }

    function goToCart() {
        history.push('cart')
    }

    function checkout() {
        if (btnStatus == 'GO') {
            history.push('checkout')
        } else
            if (userId) {
                setIsLoading(true);
                let params = { user: userId, product: productId, qty: quantity, subscription_type: subIndex, subscription: subIndex != 0 }
                userService.addToCart(params).then((response) => {
                    setIsLoading(false);
                    if (response.data.status == 200) {
                        setCartCount(response.data.cartCount);
                        toast.success("Product added to cart successfully.")
                        history.push('checkout')
                    } else {
                        setCartCount('');
                    }
                }).catch((error) => {
                    setIsLoading(false);
                });
            } else {
                window.location.pathname = '/signin'
            }
    }

    function selectSub(id) {
        if (id != subIndex)
            setSubIndex(id)
        else { setSubIndex(0) }
    }

    function getDiscountedPrice(item) {
        return (item.price - (item.discount / 100) * item.price).toFixed(2)
    }

    function showPreview(image) {
        setPreviewImage(image)
    }

    useEffect(() => {
        if (subIndex == 0 && proceedStatus == 'SKIP_SUB') {
            setProceedStatus('')
            addToCart()
        }
    }, [subIndex, proceedStatus])

    return (
        <>
            <Header cartCount={cartCount} favCount={favCount} />

            {isLoading && <Loader />}
            <section className="product-detials-page">
                <div className="container">
                    <div className="row">
                        <div class="col-xl-6 col-lg-7">
                            <Swiper
                                spaceBetween={25}
                                pagination={{ clickable: true }}
                                slidesPerView={1}
                                navigation
                                scrollbar={{ draggable: true }}
                                onSwiper={(swiper) =>  console.log(swiper)}
                                onSlideChange={() =>  console.log('slide change')}

                            >
                                {productDetail?.images?.map((image) => {
                                    return (<SwiperSlide onClick={() => { showPreview(config.imageUrl + image.image) }}>
                                        <div className="slider_box_new text-center">
                                            <img src={image.image ? config.imageUrl + image.image : ''} alt="img" />
                                        </div>
                                    </SwiperSlide>)
                                })}
                            </Swiper>


                        </div>
                        <div className="col-xl-6 col-lg-5">
                            <div className="product-detail-rightside">
                                <ShareOnSocialMedia url={window.location.href} text={`Big4Health - ${productDetail?.name}`} />
                                <h2>{productDetail?.name}</h2>
                                <h6><span>{productDetail?.category?.name}</span></h6>
                                <h5>
                                    {productDetail?.rating && Array.from(Array(parseInt(productDetail?.rating))).map(t => {
                                        return <i class="fa fa-star" aria-hidden="true" />
                                    })}
                                    <span><a href={`/reviews?id=${id}`}>({productDetail?.reviewCount} reviews)</a></span></h5>
                                {productDetail?.discount > 0 && <h5 className="mt-4">PRICE:
                                    <del>${parseFloat(productDetail?.price).toFixed(2)}</del>
                                    <span style={{ fontSize: "23px" }}>${getDiscountedPrice(productDetail)}
                                        ({productDetail?.discount}% Off)
                                    </span></h5>}
                                {productDetail?.discount == 0 && <h5 className="mt-3">PRICE:
                                    <span style={{ fontSize: "23px" }} className="ml-1"><span style={{ fontSize: "18px" }}>$</span>{parseFloat(productDetail?.price).toFixed(2)}
                                    </span></h5>}
                                {productDetail?.size?.length > 0 && <h5 className="mt-0">SIZE: <span className="text-secondary font-weight-regular">Choose an option</span>
                                    <div className="size_row">
                                        {productDetail?.size.map(item => {
                                            return <span onClick={() => setSize(item)} className={item == size && "active"}>{item}</span>
                                        })}
                                    </div>
                                </h5>}
                                {productDetail?.color?.length > 0 && <h5 className="mt-4">Color: <span className="text-secondary font-weight-regular">Choose an option</span>
                                    <div className="color_row">
                                        {productDetail?.color.map(item => {
                                            return <span onClick={() => setColor(item)} className={item == color && `active`} style={{ backgroundColor: item }}>
                                                <i className="fa fa-check"></i>
                                            </span>
                                        })}
                                    </div>
                                </h5>}

                                {productDetail.quantity != 0 ?
                                    <h5 className="select-quantity-box">SELECT QUANTITY </h5> : null}


                                {productDetail.quantity != 0 ?
                                    <button type="button" class="btn add-minus mr-2" onClick={() => handleQuantity('DEC')}><i class="fa fa-minus" aria-hidden="true"></i></button>
                                    : null}
                                {productDetail.quantity != 0 ? quantity : null}

                                {productDetail.quantity != 0 ?
                                    <button type="button" class="btn add-minus ml-2" onClick={() => handleQuantity('INC')}><i class="fa fa-plus" aria-hidden="true"></i></button>
                                    : null}

                                {productDetail.quantity != 0 ?
                                    <p className="border-top">
                                        {!btnStatus ?
                                            <>
                                                <a className="checkout-bt pointer my-1" onClick={() => {
                                                    setIsCheckout(true)
                                                    if (subIndex == 0 && haveSubscription)
                                                        handleShow()
                                                    else
                                                        addToCart()
                                                }}>CHECKOUT</a>
                                                <a className="favourite-bt pointer my-1" onClick={() => subIndex == 0 && haveSubscription ? handleShow() : addToCart()} >
                                                    <i class="fa fa-cart-plus" aria-hidden="true"></i> ADD TO CART</a>
                                            </>
                                            :
                                            <a className="checkout-bt pointer my-1" onClick={goToCart}><i class="fa fa-cart-plus mr-2" aria-hidden="true"></i> GO TO CART</a>}
                                        <a className="heart_icon1 pointer my-1 ml-3" onClick={() => handleFavourite(isFavourite)} ><i class={isFavourite ? "fa fa-heart" : "fa fa-heart-o"} aria-hidden="true"></i></a>
                                    </p>
                                    :
                                    <p>
                                        <a style={{
                                            fontSize: "16px", backgroundColor: 'white', display: 'block', width: '50%', padding: '9px',
                                            height: '44px', borderRadius: '5px', color: 'red', justifyContent: 'center', textAlign: 'center', borderWidth: '0.2px', borderColor: 'red', border: 'solid'
                                        }}>SOLD OUT</a>


                                    </p>
                                }
                            </div>

                            <section id="moredesc" className="description-area ">
                                <div>
                                    <h5 className="select-quantity-box mb-2">DESCRIPTION</h5>
                                    <span style={{ whiteSpace: 'pre-line' }}> {productDetail?.description} </span>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </section>

            {haveSubscription == true ?
                <div className="container">
                    <hr className="my-4" />
                </div> : null}

            {haveSubscription == true ?
                <section className="subscribe_section" style={{ display: btnStatus ? 'none' : 'block' }}>
                    <div className="container">
                        {subs.length > 0 && <div className="row">
                            <div className="col-md-12 mb-2">
                                <h3>SUBSCRIBE & SAVE</h3>
                            </div>
                            <div className="col-md-4 mb-2">
                                <div className="subs_firstbox subscribe_list">
                                    <span>
                                        <div class="form-check pl-0">
                                            <input class="form-check-input" checked={subIndex == subs[0]._id}
                                                onClick={(e) => selectSub(subs[0]._id)} type="radio" name="exampleRadios" id="exampleRadios1" />
                                        </div>
                                    </span>
                                    <h5>EVERY 30 DAYS</h5>
                                    <h4>& SAVE 10%</h4>
                                </div>
                            </div>

                            <div className="col-md-4 mb-2">
                                <div className="subs_secondbox subscribe_list">
                                    <span>
                                        <div class="form-check pl-0">
                                            <input class="form-check-input" checked={subIndex == subs[1]._id}
                                                onClick={(e) => selectSub(subs[1]._id)} type="radio" name="exampleRadios" id="exampleRadios1" />
                                        </div>
                                    </span>
                                    <h5>EVERY 45 DAYS</h5>
                                    <h4>& SAVE 5%</h4>
                                </div>
                            </div>

                            <div className="col-md-4 mb-2">
                                <div className="subs_thirdbox subscribe_list">
                                    <span>
                                        <div class="form-check pl-0">
                                            <input class="form-check-input" checked={subIndex == subs[2]._id}
                                                onClick={(e) => selectSub(subs[2]._id)} type="radio" name="exampleRadios" id="exampleRadios1" />
                                        </div>
                                    </span>
                                    <h5>EVERY 60 DAYS</h5>
                                    <h4>& SAVE 2%</h4>
                                </div>
                            </div>
                        </div>}
                    </div>
                </section> : null}

            <div className="container">
                <hr className="my-4" />
            </div>


            <Download />

            <Footer />

            <Modal
                show={previewImage}
                onHide={() => setPreviewImage('')}
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header closeButton className="border-0">
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Image Preview
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="border-0" >
                    <Magnifier src={previewImage} mgWidth={200} mgHeight={200} mgShape={'square'} />
                </Modal.Body>
            </Modal>

            <Modal show={show} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header closeButton>
                    <Modal.Title style={{ color: "grey" }}>SUBSCRIBE & SAVE</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    {haveSubscription == true ?
                        <section className="subscribe_section" style={{ display: btnStatus ? 'none' : 'block' }}>
                            <div className="container">
                                {subs.length > 0 && <div className="row">
                                    <div className="col-md-12 mb-2">
                                        <div className="subs_firstbox subscribe_list">
                                            <span>
                                                <div class="form-check pl-0">
                                                    <input class="form-check-input" checked={subIndex == subs[0]._id}
                                                        onClick={(e) => selectSub(subs[0]._id)} type="radio" name="exampleRadios4" id="exampleRadios4" />
                                                </div>
                                            </span>
                                            <h5>EVERY 30 DAYS</h5>
                                            <h4>& SAVE 10%</h4>
                                        </div>
                                    </div>

                                    <div className="col-md-12 mb-2">
                                        <div className="subs_secondbox subscribe_list">
                                            <span>
                                                <div class="form-check pl-0">
                                                    <input class="form-check-input" checked={subIndex == subs[1]._id}
                                                        onClick={(e) => selectSub(subs[1]._id)} type="radio" name="exampleRadios2" id="exampleRadios2" />
                                                </div>
                                            </span>
                                            <h5>EVERY 45 DAYS</h5>
                                            <h4>& SAVE 5%</h4>
                                        </div>
                                    </div>

                                    <div className="col-md-12 mb-2">
                                        <div className="subs_thirdbox subscribe_list">
                                            <span>
                                                <div class="form-check pl-0">
                                                    <input class="form-check-input" checked={subIndex == subs[2]._id}
                                                        onClick={(e) => selectSub(subs[2]._id)} type="radio" name="exampleRadios3" id="exampleRadios3" />
                                                </div>
                                            </span>
                                            <h5>EVERY 60 DAYS</h5>
                                            <h4>& SAVE 2%</h4>
                                        </div>
                                        <div className='add-skip-btn mt-3 text-center'>
                                            <button className='btn add_subs_btn mr-2' onClick={() => {
                                                if (subIndex != 0)
                                                    addToCart()
                                                else
                                                    toast.error('Please select a subscription first')
                                            }}>Add</button>
                                            <button className='btn skip_subs_btn' onClick={() => {
                                                setProceedStatus('SKIP_SUB')
                                                setSubIndex(0);
                                            }}>Skip</button>
                                        </div>
                                    </div>
                                </div>}
                            </div>
                        </section> : null}
                </Modal.Body>
            </Modal>
        </>
    )
}


export default Product_details;