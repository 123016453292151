import { useEffect, useState } from "react";
import { userService } from "../services";
import { toast } from 'react-toastify';
import moment from 'moment'


function MyOrder() {
    const [lgShow, setLgShow] = useState(false);
    const [modalContent, setModalContent] = useState(null);
    const [activeOrders, setActiveOrders] = useState([]);
    const [cancelledOrders, setCancelledOrders] = useState([]);
    const [pastOrders, setPastOrders] = useState([]);
    const [selectedTab, setTab] = useState('Active');
    const [cancelReason, setCancelReason] = useState('');

    useEffect(() => {
        if (localStorage.getItem('user_id')) {
            if (selectedTab == 'Active')
                userService.getActiveOrders().then(res => {
                    if (res.status == 200) {
                        setActiveOrders(res.data.data)
                    }
                })
        }
    }, [])

    const cancelOrder = () => {
        if (!cancelReason) {
            toast.error('Please enter cancellation reason')
        } else {
            let params = { cancel_reason: cancelReason }
            userService.cancelOrder(modalContent._id, params).then(res => {
                setModalContent(null)
                setCancelReason("")
            })
        }
    }

    function getDiscountedPrice(item) {
        return parseFloat((item.price)*(item?.qty) + item.shipping_charge + ((item.tax / 100) * item.price)).toFixed(2)
    }

    const handleTabClick = (tab) => {
        setTab(tab);
        localStorage.setItem("webnairType", tab);
        if (tab === "active") {
            userService.getActiveOrders().then(res => {
                if (res.status == 200) {
                    setActiveOrders(res.data.data)
                }
            })
        }
        else if (tab === "past") {
            userService.getPastOrders().then(res => {
                if (res.status == 200) {
                    setPastOrders(res.data.data)
                }
            })
        }
        else if (tab === "cancelled") {
            userService.getCancelledOrders().then(res => {
                if (res.status == 200) {
                    setCancelledOrders(res.data.data)
                }
            })
        }
    };


    return (
        <>
            <div className="my-order profile-right-body row">
                <ul class="nav nav-tabs mb-3">
                    <li class="nav-item">
                        <a
                            className={`nav-link ${selectedTab === 'Active' ? 'active' : ''}`}
                            onClick={(e) => { handleTabClick('active'); localStorage.setItem("Ordercategory", "Active"); }} data-toggle="tab" href="#Active">Active</a>
                    </li>
                    <li class="nav-item">
                        <a
                            className={`nav-link ${selectedTab === 'post' ? 'active' : ''}`}
                            onClick={(e) => { handleTabClick('past'); localStorage.setItem("Ordercategory", "past") }} data-toggle="tab" href="#post">Past</a>
                    </li>
                    <li class="nav-item">
                        <a
                            className={`nav-link ${selectedTab === 'cancel' ? 'active' : ''}`}
                            onClick={(e) => { handleTabClick('cancelled'); localStorage.setItem("Ordercategory", "cancelled") }} data-toggle="tab" href="#cancel">Cancelled</a>
                    </li>
                </ul>
                <div className="order-list-area-content">
                <div class="tab-content">
                    <div class="tab-pane container active p-0" id="Active">
                        {activeOrders?.length > 0 ? activeOrders?.map((item, key) => {
                            return (
                                <div className="order-box mt-3 d-flex align-items-center w-100" onClick={(e) => window.location.href=`/order-details?id=`+ item._id}>
                                    <img src={require("../images/update/Ing-product.png").default} alt="img" className="order-product" />
                                    {item?.status == "ONWAY" && <img src={require("../images/update/check-mark-green.svg").default} alt="img" className="check-mark-green" />}
                                    <div className="w-100  order-detail-right">
                                        <h4>{item?.product?.name}</h4>
                                        <p className="mb-1"><b>{"Ordered On " + moment(item.createdAt).format("MMM DD, YYYY") + " at " + moment(item.createdAt).format("hh:mm A")}</b></p>
                                        <p className="mb-1"><b className="blue-text">${item?.grandtotal >0 ? item?.grandtotal.toFixed(2) :getDiscountedPrice(item)}</b> Qty: {item?.qty}</p>
                                        <div className="mb-1 d-flex align-items-center justify-content-between order-id-area">
                                            <span className="unique-id">ID: {item?.product?._id}</span>
                                            {item.status == 'ACT' ?
                                                <span className="discount-amount" style={{ color: '#ff9933' }}>{'Pending'}</span> : <a href="#" className="hightlight-green-btn">On the Way</a>}
                                        </div>
                                    </div>
                                </div>)
                        }) :
                            <div className="profile-right-side no-data-profile no-upper-main-data mb-5">
                                <div className="no-data-box">
                                    <img src={require("../images/update/no-data.png").default} alt="img" className="mr-2" />
                                    <h3>No data found</h3>
                                </div>
                            </div>}

                    </div>

                    <div class="tab-pane container p-0" id="post">
                        {pastOrders?.length > 0 ? pastOrders?.map((item) => {
                            return (

                                <div className="order-box mt-3 d-flex align-items-center w-100" onClick={(e) => window.location.href=`/order-details?id=`+ item._id}>
                                    <img src={require("../images/update/Ing-product.png").default} alt="img" className="order-product" />
                                    <img src={require("../images/update/delivered.svg").default} alt="img" className="check-mark-green" />
                                    <div className="w-100  order-detail-right">
                                        <h4>{item?.product?.name}</h4>
                                        <p className="mb-1"><b>{"Ordered On " + moment(item.createdAt).format("MMM DD, YYYY") + " at " + moment(item.createdAt).format("hh:mm A")}</b></p>
                                        <div className="mb-1 d-flex align-items-center justify-content-between">
                                            <p className="mb-1"><b className="blue-text">${item?.grandtotal >0 ? item?.grandtotal.toFixed(2) :getDiscountedPrice(item)}</b> Qty: {item?.qty}</p>
                                            <h5 className="ratings">
                                                {item.rating ? Array.from(Array(item.rating.rating)).map(t => {
                                                    return <i class="fa fa-star" aria-hidden="true"></i>
                                                }) : 'No rating given'}
                                            </h5>
                                        </div>
                                        <div className="mb-1 d-flex align-items-center justify-content-between">
                                            <span className="unique-id">ID:  {item?.product?._id}</span>
                                            {item.refunded ==false ? <a href="#" className="hightlight-blue-btn">Returned</a> : (item.status == 'COM' && item.refunded ==true) && <a href="#" className="hightlight-blue-btn">Delivered</a>}
                                        </div>
                                    </div>
                                </div>
                            )
                        }) :
                            <div className="profile-right-side no-data-profile no-upper-main-data mb-5">
                                <div className="no-data-box">
                                    <img src={require("../images/update/no-data.png").default} alt="img" className="mr-2" />
                                    <h3>No data found</h3>
                                </div>
                            </div>
                        }
                    </div>
                    <div class="tab-pane container p-0" id="cancel" >
                        {cancelledOrders?.length > 0 ? cancelledOrders?.map((item, index) => {
                            return (
                                <div className="order-box mt-3 d-flex align-items-center w-100" onClick={(e) => window.location.href=`/order-details?id=`+ item._id}>
                                    <img src={require("../images/update/Ing-product.png").default} alt="img" className="order-product" />
                                    <img src={require("../images/update/shipping.svg").default} alt="img" className="check-mark-green" />
                                    <div className="w-100  order-detail-right">
                                        <h4>{item?.product?.name}</h4>
                                        <p className="mb-1"><b>{"Ordered On " + moment(item.createdAt).format("MMM DD, YYYY") + " at " + moment(item.createdAt).format("hh:mm A")}</b></p>
                                        <div className="mb-1 d-flex align-items-center justify-content-between">
                                            <p className="mb-1"><b className="blue-text">${item?.grandtotal >0 ? item?.grandtotal.toFixed(2) :getDiscountedPrice(item)}</b> Qty: {item?.qty}</p>
                                        </div>
                                        <div className="mb-1 d-flex align-items-center justify-content-between">
                                            <span className="unique-id">ID: {item?.product?._id}</span>
                                            <a href="#" className="hightlight-red-btn">Cancelled</a>
                                        </div>
                                    </div>
                                </div>
                            )

                        }) :
                            <div className="profile-right-side no-data-profile no-upper-main-data mb-5">
                                <div className="no-data-box">
                                    <img src={require("../images/update/no-data.png").default} alt="img" className="mr-2" />
                                    <h3>No data found</h3>
                                </div>
                            </div>
                        }

                    </div>

                </div>
                </div>
            </div>
        </>
    )
}
export default MyOrder;