import Header from "./common/Header";
import Footer from "./common/Footer";
import { Button, Modal } from "react-bootstrap";
import { useEffect, useState } from "react";
import { userService } from "../services";
import { config,appName, appUrl } from "../config/config";
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import Loader from "./common/Loader";
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookMessengerShareButton,
} from "react-share";
import {
  EmailIcon,
  FacebookIcon,
  LineIcon,
  LinkedinIcon,
  TwitterIcon,
  WhatsappIcon,
  FacebookMessengerIcon,
} from "react-share";
import { CopyToClipboard } from "react-copy-to-clipboard";
export const DATE_OPTIONS = {
  month: 'long',
  day: '2-digit',
  year: 'numeric',
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
  timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
}

const WorldDetails = () => {
  const handleCloseSuccess = () => {
    setShowSuccess(false)
  }

const [copy, setCopy] = useState(true);
let title =`Hey! Checkout this health webinar on ${appName}`;
const [share_url, setshare_url] = useState(null);
const [show, setShow] = useState(false);
const [showSuccess, setShowSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  
  let currentDate = new Date();
  currentDate.setHours(currentDate.getHours() - 24);
  const [userDetail, setUserDetail] = useState()
  let slug = window.location.pathname?.split("/")[2]
  useEffect(() => {
    getWebnairDetails(slug)
    let data=window.location.href;
    setshare_url(data)
  }, [])

  useEffect(() => {
    if (copy) {
      const interval = setInterval(() => {
        setCopy(false);
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [copy]);

  function getWebnairDetails(slug) {
    setIsLoading(true)
    userService.webnair_detail(slug).then((res) => {
      setUserDetail(res?.data?.data)
      setIsLoading(false)
      var webinarDate = new Date(res?.data?.data?.date);
      if(res?.data?.data?.status !="ACT"){
        Swal.fire("Warning","This webinar is no longer available.","warning").then((e) =>{
          window.location.href ="/world-listing";
        })
      }

    })
      .catch((Error) => {
        setIsLoading(false)
      })
  }

  return (
    <>
      <Header />
      {isLoading && <Loader />}
      <div className="big-med-details">
        <div className="container">
          <div className="back-btn-sec mb-3 d-flex justify-content-between align-items-center flex-wrap">
            <Button className="btn back-btn" href="/world-listing"><i className="fa fa-angle-left mr-2"></i>Back to Listing</Button>
              <button className="join-button mt-0" onClick={(e) =>setShowSuccess(true)} >
              <i class="fa fa-share-alt mr-1" aria-hidden="true"></i> Share
                </button>
          </div> 
          <div className="details-nor-sec">
            <div className="row align-items-center">
              <aside className="col-md-6">
                <div className="big-med-details-text mt-4">
                  <div className="big-med-text big-med-text-main mb-4">
                    <div className="new-txt-wraps">
                      <h4>{userDetail?.title}</h4>
                      <p className="mb-1"><img src={require("../images/update/date.svg").default} alt="img" className="mr-2" /> Start date&time: {userDetail && new Intl.DateTimeFormat('en', DATE_OPTIONS).format(new Date(userDetail?.date))}</p>
                      {userDetail?.webinar_endTime && <p className="mb-1"><img src={require("../images/update/date.svg").default} alt="img" className="mr-2" /> End date&time: {userDetail && new Intl.DateTimeFormat('en', DATE_OPTIONS).format(new Date(userDetail?.webinar_endTime))}</p>}
                      <p className="blue-text mb-1"><img src={require("../images/update/language.svg").default} alt="img" className="mr-2" />{userDetail?.language}</p>
                      <p className="mb-0">Category:<b> {userDetail?.big_categories?.name}</b></p>
                      <p className="mb-0">Webinar link:<b> {userDetail && userDetail?.timePassed == "past" ?
                        "Webinar date has passed" :
                        userDetail?.timePassed == "live" ?
                          <a class="join-button" href="javascript:;"
                            onClick={(e) => {
                              if (localStorage?.getItem("user_id")) {
                                window.open(userDetail?.webinar_url, "_blank");
                              } else {
                                Swal.fire('warning', "To perform this action login is required", "warning").then((e) => {
                                  window.location.href = '/signin';
                                  localStorage.setItem("redirectUri", )
                                })
                              }
                            }}>Join Now</a>
                          :
                          "Coming Soon"}</b></p>
                    </div>
                   
                    <div className="big-txt-btn"></div>

                  </div>
                  <div className="big-med-icon-text d-flex">
                    <div className="big-med-thumbnail" onClick={((e) => {
                      window.location.href = `/profile/${userDetail?.userDetail?.userSlug}`;
                    })}>
                      <img src={userDetail && userDetail?.userDetail?.profilePicture && userDetail?.userDetail?.profilePicture  !=="undefined"?  userDetail?.userDetail?.profilePicture : require("../images/update/dummy-profile-img.jpg").default} alt="img" />
                    </div>
                    <div className="big-med-text mr-4">
                      <div className="big-txt-in">
                        <p className="channel-name">{userDetail?.userDetail?.name}</p>
                        <p><span>{userDetail?.userDetail?.professionalType}</span></p>
                      </div>
                      <div className="btn-subs">
                      </div>
                    </div>
                  </div>
                </div>
                <div className="description mt-3 mb-5">
                  <p>{userDetail?.description}</p>
                </div>
              </aside>
              <aside className="col-md-6">
                <div className="details-img-area">
                  <img src={require("../images/update/doctor-img.png").default} alt="img" />
                </div>
              </aside>
            </div>
          </div>

        </div>

      </div>
      <Modal show={showSuccess} onHide={handleCloseSuccess} centered>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-0">
          <div className="email-verfication text-center">
            <h3 className="mt-4 mb-3 black-text">Share Details </h3>
            <p className="black-text">
              <span>Would you like to share it with your friends/communities?</span>
            </p>
            <div className="copy-profile d-flex mb-4">
              {copy ? (
                <>
                  <span style={{ fontSize: "12px", color: "#ff7750" }}>
                    Link Copied
                  </span>
                  <br></br>
                </>
              ) : (
                <>
                  <span style={{ fontSize: "10px" }}></span>
                  <br></br>
                </>
              )}
              <input
                type="text"
                value={share_url}
                disabled={true}
                style={{ fontSize: "12px" }}
              />
              <CopyToClipboard text={share_url} onCopy={() => setCopy(true)}>
                <i
                  className="fa fa-clipboard Demo__some-network__share-button"
                  title="Copy to clipboard"
                  aria-hidden="true"
                  style={{ fontSize: "19px", cursor: "pointer" }}
                ></i>
              </CopyToClipboard>
            </div>
            <div className="congrats-social-icons mb-2">
              <FacebookShareButton
                url={share_url}
                quote={title}
                className="Demo__some-network__share-button"
              >
                <FacebookIcon size={32} round />
              </FacebookShareButton>
              <EmailShareButton
                url={share_url}
                subject={title}
                body="Lincode Card: "
                separator=" "
                className="Demo__some-network__share-button"
              >
                <EmailIcon size={32} round />
              </EmailShareButton>
              <TwitterShareButton
                url={share_url}
                title={title}
                className="Demo__some-network__share-button"
              >
                <TwitterIcon size={32} round />
              </TwitterShareButton>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Footer />
    </>
  );
};
export default WorldDetails;
