import Header from "./common/Header";
import Footer from "./common/Footer";
import { useEffect, useState } from 'react';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
  geocodeByPlaceId
} from "react-places-autocomplete";
import { Button, Col, Form, Row, Card, Accordion, Modal } from "react-bootstrap";
import { useHistory } from 'react-router-dom';
import { userService } from '../services';
import { toast } from 'react-toastify';
import MaskedInput from 'react-text-mask';
import { Country } from 'country-state-city';
import Swal from "sweetalert2";
import Loader from "./common/Loader";


const CheckoutUpdated = () => {
  const [showFullContent, setShowFullContent] = useState(false);

  const toggleContent = () => {
    setShowFullContent(!showFullContent);
  };
  const [userDetail, setuserDetails] = useState({ email: "", name: "", PhoneNumber: "", companyName: "", country: "", service: "", message: "" })
  const [serviceOther, setserviceOther] = useState("")
  const [show, setShow] = useState(false);
  const handleClose = () => setLgShow(false);
  const handleShow = () => setLgShow(true);
  const history = useHistory()
  const [lgShow, setLgShow] = useState(false);
  const [name, setName] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [street1, setStreet1] = useState("");
  const [street2, setStreet2] = useState("");
  const [phone, setNumber] = useState("");
  const [zip, setZip] = useState("");
  const [country, setCountry] = useState("");
  const [address_id, setAddressID] = useState("");
  const [address, setAddress] = useState("")
  const [selCountry, setSelCountry] = useState("")
  const [priceStatus, setPriceStatus] = useState(false)

  const handleSelect = async (valueOfLocation, placeId) => {
    const results = await geocodeByAddress(valueOfLocation);
    const latLng = await getLatLng(results[0]);
    setAddress(valueOfLocation)
    const [place] = await geocodeByPlaceId(placeId);
    const { long_name: postalCode = '' } = place.address_components.find(c => c.types.includes('postal_code')) || {};
    const { long_name: countryselect = '' } = place.address_components.find(c => c.types.includes('country')) || {};
    const { long_name: selState = '' } = place.address_components.find(c => c.types.includes('administrative_area_level_1')) || {};
    const { long_name: selCity = '' } = place.address_components.find(c => c.types.includes('administrative_area_level_2')) || {};
    const { long_name: selStreet = '' } = place.address_components.find(c => c.types.includes("neighborhood")) || {};
    const { long_name: route = '' } = place.address_components.find(c => c.types.includes("route")) || {};
    const { long_name: street_number = '' } = place.address_components.find(c => c.types.includes("street_number")) || {};
    const { long_name: locality = '' } = place.address_components.find(c => c.types.includes("locality") || c.types.includes("political") || c.types.includes('administrative_area_level_2')) || {};

    setZip(postalCode)
    setSelCountry(countryselect)
    setCountry(countryselect)
    setState(selState)
    setCity(locality)
    setStreet1(street_number + " " + route)
  }

  const handleClearText = () => {
    setName("")
    setNumber("")
    setAddress("")
    setZip("")
    setCountry("")
    setState("")
    setCity("")
    setStreet1("")

  }

  const handleAddNewAddress = () => {
    setLgShow(true)
    handleClearText()
    setName("")
    setNumber("")
    setAddress("")
    setZip("")
    setCountry("")
    setState("")
    setCity("")
    setStreet1("")

  }

  const [bname, setBName] = useState("");
  const [bphone, setBNumber] = useState("");
  const [bzip, setBZip] = useState("");
  const [bcountry, setBCountry] = useState("");
  const [cityName, setCityName] = useState("")
  const [streetName, setStreetName] = useState("")
  const [bstate, setBState] = useState("")
  const [baddress, setBAddress] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [prices, setPrices] = useState([]);
  const [cartProducts, setCartProducts] = useState([]);
  const [cartCount, setCartCount] = useState('');
  const [shipping, setShipping] = useState('');
  const [tax, setTax] = useState('');
  const [similarProducts, setSimilarProducts] = useState([]);
  const [addresses, setAddresses] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState("");
  const [selectedAddressID, setSelectedAddressID] = useState("");
  const [favCount, setFavCount] = useState('');
  const [cartId, setCartId] = useState('');
  const [showservice, setShowservice] = useState(false)
  const [editAddress, setEditAddress] = useState(false)



  const handleShowEdit = (id) => {
    let address = addresses.filter(i => i._id == id)[0]
    setName(address.name)
    setCountry(address.country)
    setNumber(address.phone)
    setZip(address.zip_code)
    setStreet1(address.street1)
    setStreet2(address.street2)
    setCity(address.city)
    setState(address.state)
    setAddressID(address._id)
    let temArr = addresses.indexOf(address)
    setEditAddress(true)

  }

  const handleEditAddress = (id) => {
    let address = addresses.filter(i => i._id == id)[0]
    if (!name) {
      toast.error("Please Enter Name", {
        toastId: 'success_draft_1',
      })
    } else if (!phone) {
      toast.error("Please Enter Number", {
        toastId: 'success_draft_1',
      })
    }
    else if (!street1) {
      toast.error("Please Enter Street Address", {
        toastId: 'success_draft_1',
      })
    }
    else if (!city) {
      toast.error("Please Enter City", {
        toastId: 'success_draft_1',
      })
    }
    else if (!state) {
      toast.error("Please Enter State", {
        toastId: 'success_draft_1',
      })
    }
    else if (!zip) {
      toast.error("Please Enter Zip", {
        toastId: 'success_draft_1',
      })
    }
    else if (!country) {
      toast.error("Please Enter Country", {
        toastId: 'success_draft_1',
      })
    }
    else {
      let data = {
        name, phone, country, zip_code: zip, street1, street2,
        city, state, address: "", id: address_id, user: localStorage.getItem('user_id')
      }
      setIsLoading(true)
      userService.editAddress(data).then(response => {
        setIsLoading(false)
        if (response.data.status == 200) {
          toast.success(response.data.message, {
            toastId: 'success_draft_1',
          })
          handleCloseEdit()
          getCartProducts()
          setLgShow(false)

        } else if (response.data.status == 400) {
          setIsLoading(false)
          Swal.fire("Warning",response.data.result.errors[0].message,"warning")
        }
      }).catch(err => {
        setIsLoading(false)
      })
    }
  }





  const handleCloseEdit = () => 
  {setEditAddress(false)
    
  }

  useEffect(() => {
    getCartProducts();

  }, [])

  const onAddAddress = () => {
    if (!name) {
      toast.error("Please Enter Name", {
        toastId: 'success_draft_1',
      })
    } else if (!phone) {
      toast.error("Please Enter Number", {
        toastId: 'success_draft_1',
      })
    }
    else if (!street1) {
      toast.error("Please Enter Street Address", {
        toastId: 'success_draft_1',
      })
    }
    else if (!city) {
      toast.error("Please Enter City", {
        toastId: 'success_draft_1',
      })
    }
    else if (!state) {
      toast.error("Please Enter State", {
        toastId: 'success_draft_1',
      })
    }
    else if (!zip) {
      toast.error("Please Enter Zip", {
        toastId: 'success_draft_1',
      })
    }
    else if (!country) {
      toast.error("Please Enter Country", {
        toastId: 'success_draft_1',
      })
    }
    else {
      setIsLoading(true)
      let data = {
        name, phone, country, zip_code: zip, street1, street2,
        city, state, user: localStorage.getItem('user_id')
      }
      userService.addAddress(data).then(response => {
        setIsLoading(false)
        if (response.data.status == 200) {
          toast.success(response.data.message, {
            toastId: 'success_draft_1',
          })
          let tempArr = addresses;
          tempArr.push(data);
          getCartProducts()
          setLgShow(false)
        } else if (response.data.status == 400) {
          setIsLoading(false)
          alert(response.data.result.errors[0].message)
        }
      }).catch(err => {
        setIsLoading(false)
      })
    }
  }
 

  function getCartProducts() {
    setIsLoading(true);
    userService.getCartProducts().then((response) => {
      setIsLoading(false);
      if (response.data.status == 200) {
        let tmpArry = [{ price: 0, qty: 1, subscription: 0, subscription_type: 0, discount: 0 }];
        setCartId(response.data.cart._id)
        setCartCount(response.data.cart.lineitems.length)
        if (response.data.cart.lineitems) {
          response?.data?.cart?.lineitems?.map((product) => {
            tmpArry.push({ price: product?.qty * product?.product?.price, qty: product?.qty, subscription: product?.subscription, subscription_type: product?.subscription_type, discount: product?.product?.discount });
          });
        }

        setPrices(tmpArry);
        setCartProducts(response.data.cart.lineitems);
        setSimilarProducts(response.data.similarProducts);
        setTax(response.data.tax);
        setShipping(response.data.shipping);
        setPriceStatus(true)
        if (response.data.addresses.length > 0) {
          setAddresses(response.data.addresses);
          setSelectedAddress(response.data.addresses[0]._id);
          setSelectedAddressID(response.data.addresses[0].addressId);
        } else {
          setAddresses([]);
          setSelectedAddress("");
          setSelectedAddressID("");
        }
      } else {
        setCartProducts([]);
      }
    }).catch((error) => {
      setIsLoading(false);
      setCartProducts([]);
    });
  }

  function addToCart(id) {
    if (window.confirm("Are you sure you want to add this product?")) {
      let userId = localStorage.getItem('user_id');
      if (userId) {
        setIsLoading(true);
        let params = { user: userId, product: id, qty: 1 };
        userService.addToCart(params).then((response) => {
          setIsLoading(false);
          if (response.data.status === 200) {
            setCartCount(response.data.cartCount);
            getCartProducts();
            toast.success("Product added to cart successfully.", {
              toastId: 'success_draft_1',
            })
          } else {
            toast.error("Some Error Occur", {
              toastId: 'success_draft_1',
            });
          }
        }).catch((error) => {
          setCartProducts([]);
          setIsLoading(false);
        });
      } else {
        window.location.pathname = './signin';
      }
    }
  }

  function deleteAddress(id) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Are you sure you want to delete this address?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        userService.deleteAddress(id).then(res => {
          if (res.status === 200) {
            getCartProducts();
          }
        });
      }
    });
  }

  const handleCloseService = () => {
    setShowservice(false)
  }
  const handleShowservice = () => {
    setShowservice(true)
  }


  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    let updatedService;

    if (checked) {
      updatedService = [...userDetail.service, value];
    } else {
      updatedService = userDetail.service.filter((item) => item !== value);
    }

    setuserDetails((prevState) => ({
      ...prevState,
      service: updatedService,
    }));

  };

  const handleSubmit2 = () => {
    setuserDetails((prevState) => ({
      ...prevState,
      service: [...prevState.service, serviceOther],
    }));

    setShowservice(false)
  };

  function inputChange(e) {
    let { name, value } = e.target;
    setuserDetails({ ...userDetail, [name]: value });
  }

  function handleSumit(e) {
    e.preventDefault();
    var pattern = new RegExp(/^[_a-z0-9-]+(\.[_a-z0-9-]+)*(\+[a-z0-9-]+)?@[a-z0-9-]+(\.[a-z0-9-]+)*$/i);
    if (!userDetail.name || userDetail?.name.trimStart() == "") {
      Swal.fire("Warning", "Name field can’t be empty.", "warning")
      return
    }
    else if (!userDetail.email || userDetail?.email.trimStart() == "") {
      Swal.fire("Warning", "E-mail field can’t be empty.", "warning")
      return
    }
    else if (!pattern.test(userDetail.email)) {
      Swal.fire("Warning", 'Entered E-mail address is not valid.', "warning")
      return
    }
    else if (!userDetail.PhoneNumber || userDetail?.PhoneNumber.trimStart() == "") {
      Swal.fire("Warning", 'Phone Number field can’t be empty.', "warning")
      return
    }
    else if (!userDetail.companyName || userDetail?.companyName.trimStart() == "") {
      Swal.fire("Warning", 'Company Name field can’t be empty.', "warning")
      return
    }
    else if (!userDetail?.country || userDetail?.country.trimStart() == "") {
      Swal.fire("Warning", 'country field can’t be empty.', "warning")
      return
    }
    else if (!userDetail?.service) {
      Swal.fire("Warning", 'service field can’t be empty.', "warning")
      return
    }
    else if (!userDetail?.message || userDetail?.message.trimStart() == "") {
      Swal.fire("Warning", 'message field can’t be empty.', "warning")
      return
    }
    else {
      contactus()
    }
  }
  let preview;

  function contactus() {
    const params = {
      name: userDetail.name,
      email: userDetail?.email,
      companyName: userDetail?.companyName,
      country: userDetail?.country,
      PhoneNumber: userDetail?.PhoneNumber,
      service: userDetail?.service,
      message: userDetail?.message,
      page_type: "business"
    }
    userService.contactUs(params).then((res) => {
      if (res?.data?.status === 200) {
        Swal.fire("Success", res?.data?.message, "success").then((res) => {
          setuserDetails({
            email: "",
            name: "",
            PhoneNumber: "",
            companyName: "",
            country: "",
            service: "",
            message: ""
          });
        })
        setuserDetails({
          email: "",
          name: "",
          PhoneNumber: "",
          companyName: "",
          country: "",
          service: "",
          message: ""
        });
      }
    })
      .catch((error) => {
      })
  }

  const handleInputChange = (event) => {
    const { value } = event.target;
    setserviceOther(value)

  };


  function handleFavourite(id, status) {
    let userId = localStorage.getItem('user_id');
    if (userId) {
      var answer = true;
      if (status) answer = window.confirm("Are you sure want to remove it from your favourite list.");
      if (answer) {
        setIsLoading(true);
        let params = { user: userId, product: id, is_favourite: !status }
        userService.updateFavourite(params).then((response) => {
          setIsLoading(false);
          if (response.data.status == 200) {
            setFavCount(response.data.favCount);
            getCartProducts()
          } else {
            toast.error("Some Error Occur", {
              toastId: 'success_draft_1',
            });
          }
        }).catch((error) => {
          setIsLoading(false);
        });
      }
    } else {
      window.location.pathname = '/signin'
    }
  }


  function BAddressValidation() {
    var nameRegExp = new RegExp(/^[a-zA-Z ]+$/i);
    if (addresses.length == 0) {
      toast.error("Please Add Valid Shipping Address", {
        toastId: 'success_draft_1',
      });
    } else if (bname.length == 0) {
      toast.error("Please Enter Name", {
        toastId: 'success_draft_1',
      });
    } else if (!(bphone.length >= 8 && bphone.length <= 12)) {
      toast.error("Please Enter Valid Phone Number", {
        toastId: 'success_draft_1',
      });
    }

    else if (!streetName) {
      toast.error("Please Enter Street", {
        toastId: 'success_draft_1',
      })
    }
    else if (!cityName) {
      toast.error("Please Enter City", {
        toastId: 'success_draft_1',
      })
    }
    else if (!bstate) {
      toast.error("Please Enter State", {
        toastId: 'success_draft_1',
      })
    }

    else if (!(bzip.length <= 6 && bzip !== '')) {
      toast.error("Please Enter Valid Zip Code", {
        toastId: 'success_draft_1',
      });
    } else if (bcountry == -1 || bcountry === '') {
      toast.error("Please Enter Valid Country", {
        toastId: 'success_draft_1',
      });
    }
    else {
      history.replace('shipping?address=' + selectedAddress + '&addressID=' + selectedAddressID + '&cartId=' + cartId)
    }

  }

  function getDiscountedPrice(item) {
    return (item.price - (item.discount / 100) * item.price).toFixed(2)
  }

  function getTotalPrice() {
    let sum = 0;
    prices.map((item) => {
      let value = (item.price - (item.discount * item.price / 100))
      sum = sum + value
    });
    return sum;
  }

  function getTax() {
    return parseFloat(((getTotalPrice() - getSubDiscount()) * tax) / 100).toFixed(2);
  }

  function getSubDiscount() {
    let sum = 0;
    prices.forEach((item) => {
      let value = item.subscription ? (item.price - (item.discount * item.price / 100)) / 100 * parseFloat(item.subscription_type.save_percent) : 0
      sum = sum + value
    });
    return sum.toFixed(2);
  }

  function getGrandTotalPrice() {

    let gPrice = getTotalPrice();       //setting initial GrandTotal from getTotalPrice()

    let taxVal = parseFloat(getTax());
    let shippingVal = 0;
    gPrice += taxVal;
    gPrice += shippingVal;
    gPrice -= getSubDiscount()
    return gPrice;
  }
  function selectedAddressValue(item) {
    setSelectedAddress(item._id)
    setSelectedAddressID(item.addressId)
  }

  return (
    <>
      {isLoading && <Loader />}
      <Header />
      <section className="shopping-cart-sec burden-disease">
        <div className="container">
          <div className="banner-text">
            <h1 className="text-center">Checkout</h1>
          </div>
          <div className="row">
           
            <div className="col-lg-4 summry">
              <div className="card shopping-details">
                <div className="card-body">
                  {cartProducts?.map((item, index) => {
                    return <dl className="dlist-align mb-1">
                      <h6><span>{index + 1}.</span> {item.product.name} <span>({item.qty}x)</span></h6>
                      <dd className="text-right ml-3 text-dark" style={{ fontSize: "14px" }}>${getDiscountedPrice(item.product)}</dd>
                    </dl>
                  })}
                  <hr />
                  <dl className="dlist-align">
                    <dt>Total price:</dt>
                    {priceStatus && <dd className="text-right ml-3">${getTotalPrice().toFixed(2)}</dd>}
                  </dl>
                  {priceStatus && <dl className="dlist-align">
                    <dt>Discount:</dt>
                    <dd className="text-right text-success ml-3">-${getSubDiscount()}</dd>
                  </dl>}
                  <dl className="dlist-align">
                    <dt>Tax({tax}%):</dt>
                    {priceStatus && <dd className="text-right ml-3">${getTax()}</dd>}
                  </dl>
                  <dl className="dlist-align">
                    <dt>Shipping:</dt>
                    <dd className="text-right ml-3 pending-text" style={{ color: 'red' }}>{'pending'}</dd>

                  </dl>

                  <hr />
                  <dl className="dlist-align mb-0">
                    <dt style={{ fontSize: "20px", color: "black" }}>Total:</dt>
                    {priceStatus && <dd className="text-right ml-3" style={{ fontSize: "20px" }}><strong>${getGrandTotalPrice().toFixed(2)}</strong></dd>}
                  </dl>
                </div>
              </div>
              <div className="make_payment">
                <a className="btn text-white" onClick={() => BAddressValidation()}>Continue</a>
              </div>
            </div>

            <aside className="col-lg-8">
              <div className="delivey-box">
                <div className="delivery-head">
                  <h5><b>Delivery Address</b></h5>
                  <button className="shopping-btn" onClick={handleShow}>
                    + Add new address
                  </button>
                </div>
              
                <div className="delivery-body">
                  {addresses?.length == 0 && <div className="no-address-area">
                    <img src={require("../../src/images/update/no-address.png").default} />
                    <p>No Address Found</p>
                  </div>}
                  {addresses.map((item, index) => {
                    return <div className="address-detail">


                      <div className="address-left-area">
                        <span>

                          <input class="form-check-input" onChange={(e) => { selectedAddressValue(item) }}
                            checked={item._id == selectedAddress} type="radio" />

                        </span>
                        <div className="address-info">
                          <h5>{item.name}</h5>
                          <h6 >{item.phone}</h6>
                          <p>{item.street1} {item.city}, {item.state}, {item.country}</p>
                        </div>
                      </div>
                    
                      <div className="inner-action">
                        <button className="edit-btn" onClick={(e) => handleShowEdit(item._id)}>Edit</button>
                        <button className="edit-btn delete-btn" onClick={() => deleteAddress(item._id)} >Delete</button>
                      </div>


                      <Modal
                        show={editAddress}
                        onHide={() =>{ setEditAddress(false); handleClearText()}}
                        aria-labelledby="example-modal-sizes-title-lg"
                        className="address_modal"
                        size="lg"
                        centered
                      >
                        <Modal.Header closeButton>
                          <Modal.Title id="example-modal-sizes-title-lg">
                            Edit Details
                          </Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="delivery-payment p-0">
                          <div className="payment_modal px-3">
                            <div className="row">

                              <div className="col-md-6 mb-3">
                                <div>
                                  <label>Full Name</label>
                                  <input value={name}
                                    onKeyDown={(e) => {
                                      if (!e.target.value && e.code === "Space") {
                                        e.preventDefault();
                                      }
                                    }} onChange={(e) => setName(e.target.value)} maxLength={20} type="" placeholder="Enter Full Name" />
                                </div>
                              </div>
                              <div className="col-md-6 mb-3">
                                <div>
                                  <label>Phone Number</label>
                                  <MaskedInput
                                    mask={[/[1-9]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                                    placeholder="Enter Phone Number"
                                    guide={false}
                                    onChange={(e) => setNumber(e.target.value)}
                                    maxLength={12}
                                    onKeyDown={(e) => {
                                      if (!e.target.value && e.code === "Space") {
                                        e.preventDefault();
                                      }
                                    }}
                                    value={phone}
                                  />


                                </div>
                              </div>
                              <div className="col-md-6 mb-3">
                                <div>
                                  <label>Street Address</label>
                                  <input value={street1}
                                    onKeyDown={(e) => {
                                      if (!e.target.value && e.code === "Space") {
                                        e.preventDefault();
                                      }
                                    }}
                                    onChange={(e) => setStreet1(e.target.value)} type="" placeholder="Enter Street 1" />
                                </div>
                              </div>
                              <div className="col-md-6 mb-3">
                                <div>
                                  <label>Apt, Suite, etc.</label>
                                  <input type="" placeholder="Enter Apt" />
                                </div>
                              </div>
                              <div className="col-md-6 mb-3">
                                <div>
                                  <label>City</label>
                                  <input value={city} onKeyDown={(e) => {
                                    if (!e.target.value && e.code === "Space") {
                                      e.preventDefault();
                                    }
                                  }} onChange={(e) => setCity(e.target.value)} maxLength={20} type="" placeholder="Enter City" />
                                </div>
                              </div>
                              <div className="col-md-6 mb-3">
                                <div>
                                  <label>State</label>
                                  <input value={state} onKeyDown={(e) => {
                                    if (!e.target.value && e.code === "Space") {
                                      e.preventDefault();
                                    }
                                  }} onChange={(e) => setState(e.target.value)} maxLength={20} type="" placeholder="Enter State" />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div>
                                  <label>ZIP Code</label>
                                  <input value={zip} onKeyDown={(e) => {
                                    if (!e.target.value && e.code === "Space") {
                                      e.preventDefault();
                                    }
                                  }} onChange={(e) => setZip(e.target.value)} type="" maxLength={7} placeholder="Enter ZIP Code" />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div>
                                  <label>Country</label>
                                  <div className="address-country-area">
                                    <Form>
                                      <Form.Control as="select" value={country} onChange={(e) => setCountry(e.target.value)}>
                                        <option value="">Select Country</option>
                                        {Country.getAllCountries().map((c) => {
                                          return (<option>{c.name}</option>)
                                        })}
                                      </Form.Control>
                                    </Form>
                                    <i class="fa fa-angle-down" aria-hidden="true"></i>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Modal.Body>
                        <Modal.Footer className="border-0 pb-4 px-4">
                          <button
                            className="save-address-btn paybtn w-100 py-3" onClick={(e) => handleEditAddress(item._id)}>
                            Edit Address
                          </button>
                        </Modal.Footer>
                      </Modal>
                    </div>
                  })}
                </div>

                {addresses && addresses?.length > 0 && <div className="col-md-12 mt-4 p-0">
                  <div className="payment_modal px-3 delivery-payment">
                    <div className="address_box">
                      <div className="add_head checboxhead pb-1">
                        <div className="delivery-head mt-3">
                          <h5><b>Billing Address</b></h5>
                        </div>

                      </div>
                      <hr className="my-2" />
                    </div>
                    <div className="row">
                      <div className="col-md-6 mb-3">
                        <div>
                          <label>Full Name</label>
                          <input value={bname} maxLength={30} onChange={(e) => setBName(e.target.value)} type="" placeholder="Enter Full Name" />
                        </div>
                      </div>
                      <div className="col-md-6 mb-3">
                        <div>
                          <label>Phone Number</label>
                          <input value={bphone} maxLength={13} onChange={(e) => setBNumber(e.target.value)} type="" placeholder="Enter Phone Number" />
                        </div>
                      </div>
                      <div className="col-md-6 mb-3">
                        <div>
                          <label>Street</label>
                          <input value={streetName} onChange={(e) => setStreetName(e.target.value)} type="" placeholder="Enter Your Street" />
                        </div>
                      </div>
                      <div className="col-md-6 mb-3">
                        <div>
                          <label>Apt, Suite, etc.</label>
                          <input type="" placeholder="Enter Apt" />
                        </div>
                      </div>
                      <div className="col-md-6 mb-3">
                        <div>
                          <label>City</label>
                          <input value={cityName} maxLength={30} onChange={(e) => setCityName(e.target.value)} type="" placeholder="Enter City Name" />
                        </div>
                      </div>
                      <div className="col-md-6  mb-1">
                        <div>
                          <label>State</label>
                          <input value={bstate} maxLength={70} onChange={(e) => setBState(e.target.value)} type="" placeholder="Enter State" />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div>
                          <label>ZIP Code</label>
                          <input value={bzip} maxLength={7} onChange={(e) => setBZip(e.target.value)} type="" placeholder="Enter ZIP Code" />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div>
                          <label>Country</label>
                          <div className="address-country-area">
                            <Form>
                              <Form.Control as="select" value={bcountry} onChange={(e) => setBCountry(e.target.value)}>
                                <option value="">Select Country</option>
                                {Country.getAllCountries().map((c) => <option>{c.name}</option>)}
                              </Form.Control>
                            </Form>
                            <i class="fa fa-angle-down" aria-hidden="true"></i>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>}
                <Form.Group className="mb-1 same-billing-area" controlId="formBasicCheckbox">
                      
                      <Form.Check type="checkbox" onChange={(e) => {
                        if (!e.target.checked) {
                          setBAddress("")
                          setBCountry("")
                          setBName("")
                          setBNumber("")
                          setBZip("")
                          setStreetName("")
                          setCityName("")
                          setBState("")
                        } else {
                          let address = addresses.filter(i => i._id == selectedAddress)[0]
                          setBAddress(address.address)
                          setBCountry(address.country)
                          setBName(address.name)
                          setBNumber(address.phone)
                          setBZip(address.zip_code)
                          setStreetName(address.street1)
                          setCityName(address.city)
                          setBState(address.state)


                        }
                      }}
                        
                        label="Same as billing address" />
                    </Form.Group>
              </div>
            </aside>
          </div>
        </div>
      </section>

      {/* contact us section start */}

      <section className="get-started-form bg_section signup_section redesign-profile">
        <div className="container">
          <h3> We’re here with the experts and personalized guidance to help you navigate the current healthcare landscape.</h3>
          <div className="get-form-wrap">
            <h2>Get Started</h2>

            <aside className="col-lg-12 pt-4">
              <div className="Signup_box text-center w-100">
                <Form className="">
                  <div className="row frist_step  contact-form-area ">
                    <aside className="col-md-4 mb-3 text-left">
                      <label>
                        Full Name<span className="mandatory"></span>
                      </label>
                      <div className="input_row">
                        <input
                          type="firstName"
                          name="name"
                          maxLength={50}
                          onKeyDown={(e) => {
                            if (!e.target.value && e.code === "Space") {
                              e.preventDefault();
                            }
                          }}
                          placeholder="Enter Full Name"
                          className="input103 w-100"
                          value={userDetail?.name}
                          onChange={(e) => inputChange(e)}

                        />
                      </div>
                    </aside>
                    <aside className="col-md-4 mb-3 text-left">
                      <label>
                        Email<span className="mandatory"></span>
                      </label>
                      <div className="input_row">
                        <input
                          type="Email"
                          name="email"
                          onKeyDown={(e) => {
                            if (!e.target.value && e.code === "Space") {
                              e.preventDefault();
                            }
                          }}
                          placeholder="Enter Email"
                          className="input103 w-100"
                          value={userDetail?.email}
                          onChange={(e) => inputChange(e)}

                        />
                      </div>
                    </aside>
                    <aside className="col-md-4 mb-3 text-left">
                      <label>
                        Phone Number<span className="mandatory"></span>
                      </label>
                      <div className="input_row">
                        <input
                          type="PhoneNumber"
                          name="PhoneNumber"
                          onKeyDown={(e) => {
                            if (!e.target.value && e.code === "Space") {
                              e.preventDefault();
                            }
                          }}
                          placeholder="Enter Phone Number"
                          className="input103 w-100"
                          value={userDetail?.PhoneNumber}
                          onChange={(e) => inputChange(e)}

                        />
                      </div>
                    </aside>
                    <aside className="col-md-4 mb-3 text-left">
                      <label>
                        Company Name<span className="mandatory"></span>
                      </label>
                      <div className="input_row">
                        <input
                          type="Text"
                          name="companyName"
                          maxLength={50}
                          onKeyDown={(e) => {
                            if (!e.target.value && e.code === "Space") {
                              e.preventDefault();
                            }
                          }}
                          placeholder="Enter Company Name"
                          className="input103 w-100"
                          value={userDetail?.companyName}
                          onChange={(e) => inputChange(e)}


                        />
                      </div>
                    </aside>
                    <aside className="col-md-4 mb-3 text-left">
                      <label>Country </label>
                      <div className="input_row input103">


                        <select
                          className="w-100 select-main"
                          name="country"
                          value={userDetail?.country}
                          onChange={(e) => inputChange(e)}
                        >
                          <option value={""}>Select Country</option>
                          {Country.getAllCountries().map((c) => {
                            return (
                              <option value={c.name}>{c.name}</option>
                            )
                          })}

                        </select>
                      </div>
                    </aside>
                   
                    <aside className="col-md-4 mb-3 text-left">
                      <label>Service</label>
                      <>
                        <a variant="primary" onClick={handleShowservice} className='d-block servies-text'>
                          {userDetail?.service?.length > 0 ? "+ Update Services" : "+ Add Services"}

                        </a>
                       
                        <div className='services-preview'>
                          {userDetail?.service && <span>{showFullContent ? userDetail?.service?.join(" , ") : userDetail?.service?.join(" , ")?.slice(0, 30)}</span>}
                          {userDetail?.service && (userDetail?.service?.join(" ")?.length > 30) ? (
                            <p onClick={toggleContent}>{showFullContent ? 'View less' : 'View more'}</p>
                          ) : (
                            ''
                          )}
                        </div>
                      </>
                    </aside>


                    <aside className="col-md-12 mb-3 text-left">
                      <div className="input_row">
                        <label>Message</label>
                        <textarea
                          className=" w-100 select-main"
                          placeholder="Enter Message"
                          name="message"
                          onKeyDown={(e) => {
                            if (!e.target.value && e.code === "Space") {
                              e.preventDefault();
                            }
                          }}
                          maxLength={500}
                          value={userDetail?.message}
                          onChange={(e) => inputChange(e)}
                        ></textarea>
                      </div>
                    </aside>
                  </div>
                </Form>
              </div>
              <div className="next_btn text-center">
                <Button
                  variant="secondary"
                  type="button"
                  className="btn w-25"
                  onClick={handleSumit}
                >
                  Submit
                </Button>
              </div>
            </aside>

          </div>
        </div>
      </section>
      <Modal show={showservice} onHide={handleCloseService} className='services-box'>
        <Modal.Header closeButton>
          <Modal.Title>Get Started, the services available </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='services-box'>
            <Accordion>
              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="0">
                    Prevention and Reversal Programs
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                  <Card.Body>
                    <div className='row'>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox1">
                          <Form.Check type="checkbox" label="Diabetes" checked={userDetail.service.includes('Diabetes')} value="Diabetes" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>

                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox2">
                          <Form.Check type="checkbox" label="High Blood Pressure" checked={userDetail.service.includes('High Blood Pressure')} value="High Blood Pressure" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox3">
                          <Form.Check type="checkbox" label="High Cholesterol" checked={userDetail.service.includes('High Cholesterol')} value="High Cholesterol" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox4">
                          <Form.Check type="checkbox" label="Obesity Prevention/Reversal Programs" checked={userDetail.service.includes('Obesity Prevention/Reversal Programs')} value="Obesity Prevention/Reversal Programs" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox5">
                          <Form.Check type="checkbox" label="Chronic Disease Prevention/Reversal Programs" checked={userDetail.service.includes('Chronic Disease Prevention/Reversal Programs')} value="Chronic Disease Prevention/Reversal Programs" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="1">
                    Health and Wellness Applications
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="1">
                  <Card.Body>
                    <div className='row'>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox6">
                          <Form.Check type="checkbox" label="Medical & Health Apps" checked={userDetail.service.includes('Medical & Health Apps')} value="Medical & Health Apps" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox7">
                          <Form.Check type="checkbox" label="Wellness Apps" checked={userDetail.service.includes('Wellness Apps')} value="Wellness Apps" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="2">
                    Workplace Wellness Initiatives
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="2">
                  <Card.Body>
                    <div className='row'>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox8">
                          <Form.Check type="checkbox" label="Corporate Wellness Programs" checked={userDetail.service.includes('Corporate Wellness Programs')} value="Corporate Wellness Programs" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox9">
                          <Form.Check type="checkbox" label="Health and Wellness Consulting" checked={userDetail.service.includes('Health and Wellness Consulting')} value="Health and Wellness Consulting" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="3">
                    Community Health Initiatives
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="3">
                  <Card.Body>
                    <div className='row'>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox10">
                          <Form.Check type="checkbox" label="Population Health Improvement Programs" checked={userDetail.service.includes('Population Health Improvement Programs')} value="Population Health Improvement Programs" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox11">
                          <Form.Check type="checkbox" label="Behavioral Health Strategic Management" checked={userDetail.service.includes('Behavioral Health Strategic Management')} value="Behavioral Health Strategic Management" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox12">
                          <Form.Check type="checkbox" label="Certified Community Behavioral Health Clinics (CCBHCs)" checked={userDetail.service.includes('Certified Community Behavioral Health Clinics (CCBHCs)')} value="Certified Community Behavioral Health Clinics (CCBHCs)" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="4">
                    Nutrition and Health Products:
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="4">
                  <Card.Body>
                    <div className='row'>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox13">
                          <Form.Check type="checkbox" label="Nutrition" checked={userDetail.service.includes('Nutrition')} value="Nutrition" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox14">
                          <Form.Check type="checkbox" label="Cancer-Fighting Meals" checked={userDetail.service.includes('Cancer-Fighting Meals')} value="Cancer-Fighting Meals" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox15">
                          <Form.Check type="checkbox" label="Meal Replacement Solutions" checked={userDetail.service.includes('Meal Replacement Solutions')} value="Meal Replacement Solutions" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox16">
                          <Form.Check type="checkbox" label="Health Supplements" checked={userDetail.service.includes('Health Supplements')} value="Health Supplements" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="5">
                    Wellness Initiatives
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="5">
                  <Card.Body>
                    <div className='row'>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox17">
                          <Form.Check type="checkbox" label="Health Wellness Programs" checked={userDetail.service.includes('Health Wellness Programs')} value="Health Wellness Programs" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox18">
                          <Form.Check type="checkbox" label="Employee Engagement " checked={userDetail.service.includes('Employee Engagement')} value="Employee Engagement" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="6">
                    Health Advocacy and Information:
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="6">
                  <Card.Body>
                    <div className='row'>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox19">
                          <Form.Check type="checkbox" label="Healthcare Speakers and Keynote Speakers" checked={userDetail.service.includes('Healthcare Speakers and Keynote Speakers')} value="Healthcare Speakers and Keynote Speakers" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox20">
                          <Form.Check type="checkbox" label="Public Health Policies for Government Agencies " checked={userDetail.service.includes('Public Health Policies for Government Agencies')} value="Public Health Policies for Government Agencies" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox21">
                          <Form.Check type="checkbox" label="Health Data Analytics (Making complex data understandable and useful)" checked={userDetail.service.includes('Health Data Analytics (Making complex data understandable and useful)')} value="Health Data Analytics (Making complex data understandable and useful)" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="7">
                    Collaboration Opportunities
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="7">
                  <Card.Body>
                    <div className='row'>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox22">
                          <Form.Check type="checkbox" label="Become a Partner" checked={userDetail.service.includes('Become a Partner')} value="Become a Partner" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="8">
                    Build Custom Medical Apps
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="8">
                  <Card.Body>
                    <div className='row'>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox23">
                          <Form.Check type="checkbox" label="Custom Medical Apps" checked={userDetail.service.includes('Custom Medical Apps')} value="Custom Medical Apps" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <label className='mt-3'><b>Other</b></label>
              <textarea
                className="form-control"
                placeholder="Please Specify"
                name="service"
                maxLength={500}
                onChange={handleInputChange}
              ></textarea>
            </Accordion>
          </div>
          {userDetail?.service?.length > 0 && <a
            type="button"
            className="join-button"
            onClick={handleSubmit2}

          >
            Submit
          </a>}
        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal>


      {/* contact us section start */}

      <Footer />
     
      <Modal
        show={lgShow}
        onHide={() =>{ setLgShow(false); handleClearText()}}
        aria-labelledby="example-modal-sizes-title-lg"
        className="address_modal"
        centered
        size="lg">
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg" >
            Add New Address
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="delivery-payment p-0">
          <div className="payment_modal px-3">
            <div className="row p-0">
              <div className="col-md-6 mb-3">
                <div>
                  <label>Full Name</label>
                  <input value={name} onKeyDown={(e) => {
                    if (!e.target.value && e.code === "Space") {
                      e.preventDefault();
                    }
                  }} onChange={(e) => setName(e.target.value)} maxLength={20} type="" placeholder="Enter Full Name" />
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <div>
                  <label>Phone Number</label>
                  <MaskedInput
                    mask={[/[1-9]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                    placeholder="Enter Phone Number"
                    guide={false}
                    onChange={(e) => setNumber(e.target.value)}
                    maxLength={12}
                    onKeyDown={(e) => {
                      if (!e.target.value && e.code === "Space") {
                        e.preventDefault();
                      }
                    }}
                    value={phone}
                  />
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <div>
                  <label>Street</label>
                  <input value={street1} onKeyDown={(e) => {
                    if (!e.target.value && e.code === "Space") {
                      e.preventDefault();
                    }
                  }} onChange={(e) => setStreet1(e.target.value)} type="" placeholder="Enter Your Street" />
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <div>
                  <label>Apt, Suite, etc.</label>
                  <input type="" placeholder="Enter Apt" />
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <div>
                  <label>City</label>
                  <input value={city}
                    onKeyDown={(e) => {
                      if (!e.target.value && e.code === "Space") {
                        e.preventDefault();
                      }
                    }} onChange={(e) => setCity(e.target.value)} maxLength={20} type="" placeholder="Enter City Name" />
                </div>
              </div>
              <div className="col-md-6  mb-1">
                <div>
                  <label>State</label>
                  <input value={state}
                    onKeyDown={(e) => {
                      if (!e.target.value && e.code === "Space") {
                        e.preventDefault();
                      }
                    }}
                    onChange={(e) => setState(e.target.value)} maxLength={20} type="" placeholder="Enter State" />
                </div>
              </div>
              <div className="col-md-6">
                <div>
                  <label>ZIP Code</label>
                  <input value={zip} onKeyDown={(e) => {
                    if (!e.target.value && e.code === "Space") {
                      e.preventDefault();
                    }
                  }} onChange={(e) => setZip(e.target.value)} type="" maxLength={7} placeholder="Enter ZIP Code" />
                </div>
              </div>
              <div className="col-md-6">
                <div>
                  <label>Country</label>

                  <div className="address-country-area">
                    <Form>
                      <Form.Control as="select" value={country} onChange={(e) => setCountry(e.target.value)}>
                        <option value="">Select Country</option>
                        {Country.getAllCountries().map((c) => {
                          return (<option >{c.name}</option>)
                        })}
                      </Form.Control>
                    </Form>
                    <i class="fa fa-angle-down" aria-hidden="true"></i>
                  </div>
                </div>
              </div>




            </div>

          </div>
        </Modal.Body>
        <Modal.Footer className="border-0 pb-4 px-4">
          <Button className="save-address-btn mb-0" type="button" onClick={onAddAddress}>Save Address</Button>
        </Modal.Footer>
      </Modal>

    </>
  );
};
export default CheckoutUpdated;
