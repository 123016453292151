import Header from './Header'
import Footer from './Footer'

const TermApp = () => {
  return (
    <div className="container">
      <div>
        <div>
          <div className="tcondition">
            <h3>
              <b>COLLCONNECT LLC MOBILE APP TERMS OF USE</b>
            </h3>
            <ol>
              <li>
                <strong>1. TERMS OF USE</strong>
              </li>
            </ol>
            <p>Effective as of January 27, 2022.</p>
            <p>
              Welcome to the CollConnect L.L.C (the "Service"). The following
              Terms of Use apply when you view or use the Service located at:
              http://www.big4healthapp.com. Please review the following terms
              carefully.
            </p>
            <p>
              By downloading, browsing, accessing or using this CollConnect
              LLC Mobile App (“Mobile Application”), you agree to be bound by
              these Terms and Conditions of Use. We reserve the right to amend
              these terms and conditions at any time. If you disagree with any
              of these Terms and Conditions of Use, you must immediately
              discontinue your access to the Mobile Application and the use of
              the services offered on the Mobile Application. Continued use of
              the Mobile Application will constitute acceptance of these
              stated Terms and Conditions of Use, as may be amended
              periodically
            </p>
            <ol>
              <li>
                <strong>2. DEFINITIONS</strong>
              </li>
            </ol>
            <p>
              In these Terms and Conditions of Use, the following capitalized
              terms shall have the following meanings, except where the
              context otherwise requires:
            </p>
            <p>
              "Account" means an account created by a User on the CollConnect
              LLC Mobile Application as part of Registration.
            </p>
            <p>
              "Merchant" refers to CollConnect LLC whose products or Samples
              can be purchased and/or redeemed (as the case may be) via the
              Mobile Application.
            </p>
            <p>
              "Redeem" means to redeem CollConnect LLC products or Samples on
              these Terms and Conditions of Use and
            </p>
            <p>
              "Redemption" means the act of redeeming such products or Samples
            </p>
            <p>
              "Register" means to create an Account on the Mobile Application.
            </p>
            <p> Registration" means the act of creating such an Account.</p>
            <p>
              “Subscription” means to initiate payment for the app purchased.{" "}
            </p>
            <p>
              For the app purchase, it would be automatic renewal (two
              options)
            </p>
            <p>1) If select monthly charge</p>
            <p>2) If select annual charge</p>
            <p>
              &#8220;Samples&#8221; means samples of CollConnect LLC products
              or services, including without limitation, any vouchers for the
              redemption of such services, and &#8220;Sample&#8221; means any
              one of them.
            </p>
            <p>
              &#8220;Services&#8221; means all the services provided by
              CollConnect LLC via the Mobile Application to Users, and
              &#8220;Service&#8221; means any one of them,
            </p>
            <p>
              &#8220;Users&#8221; means users of CollConnect LLC Mobile
              Application, including you and &#8220;User&#8221; means any one
              of them.
            </p>
            <ol>
              <li>
                <strong>
                  {" "}
                  3. GENERAL ISSUES ABOUT THE MOBILE APPLICATION AND THE SERVICES
                </strong>
              </li>
            </ol>
            <p>
              <strong>
                3.1 <i>Applicability of terms and conditions:</i>
              </strong>{" "}
              The use of any Services and/or the Mobile Application and the
              making of any Redemption are subject to these Terms and
              Conditions of Use.
            </p>
            <p>
              <strong>
                3.2 <i>Location:</i>
              </strong>{" "}
              The Mobile Application, the Services and any Redemption are
              intended solely for use by Users who access the Mobile
              Application in United States. We make no representation that the
              Services (or any goods or services) are available or otherwise
              suitable for use outside of United State. Notwithstanding the
              above, if you access the Mobile Application, use the Services or
              make any Redemption from locations outside America, you do it on
              your own initiative and are responsible for the consequences and
              for compliance with all applicable laws.
            </p>
            <p>
              <strong>
                3.3a. <i>Scope</i>
              </strong>
              <strong>:</strong> The scope of CollConnect LLC nature of
              business is to sell the BIG4Health App, which is an updated and
              user friendly mobile app for IOS and Android along with the
              Health Ingredients (a nutrition supplement).{" "}
            </p>
            <p>
              <strong>3.3b.</strong> Clients also have the option to buy a few
              products advertised on the app such as T-shirts, Cooking books,
              and Health Ingredients (the nutrition supplement).
            </p>
            <p>
              <strong>
                3.4 <i>Prevention on us</i>
              </strong>
              <b>
                <i>e</i>
              </b>
              : We reserve the right to prevent you from using the Mobile
              Application and the Service (or any part of them) and to prevent
              you from making any Redemption.
            </p>
            <p>
              <strong>
                3.5 <i>Equipment and Networks</i>
              </strong>
              <strong>:</strong> The provision of the Services and the Mobile
              Application does not include the provision of a mobile telephone
              or handheld device or other necessary equipment to access the
              Mobile Application or the Services or make any Redemptions. To
              use the Mobile Application or Services or to make Redemptions,
              you will require Internet connectivity and appropriate
              telecommunication links. You acknowledge that the terms of
              agreement with your respective mobile network provider
              (&#8220;Mobile Provider&#8221;) will continue to apply when
              using the Mobile Application. As a result, you may be charged by
              the Mobile Provider for access to network connection services
              for the duration of the connection while accessing the Mobile
              Application or any such third party charges as may arise. You
              accept responsibility for any such charges that arise.
            </p>
            <ol>
              <li>
                <strong>4. REDEMPTIONS</strong>
              </li>
            </ol>
            <p>
              <strong>
                4.1 <i>Need for registration:</i>
              </strong>{" "}
              You must register to make Redemption from the Mobile Application
              or Websites.
            </p>
            <p>
              <strong>
                4.2 <i>Application of these Terms and Conditions of Use:</i>
              </strong>{" "}
              By making any Redemption, you acknowledge that the Redemption is
              subject to these Terms and Conditions of Use.
            </p>
            <p>
              <strong>
                4.3 <i>Redemption</i>
              </strong>
              <strong>:</strong> Any attempted Redemption not consistent with
              these Terms and Conditions of Use may be disallowed or rendered
              void at our or the relevant Merchant’s discretion.
            </p>
            <ol>
              <li>
                <strong>
                  5. RULES ABOUT USE OF THE SERVICE AND THE MOBILE APPLICATION
                </strong>
              </li>
            </ol>
            <p>
              5.1 We will use reasonable endeavors to correct any errors or
              omissions as soon as practicable after being notified of them.
              However, we do not guarantee that the Services or the Mobile
              Application will be free of faults, and we do not accept
              liability for any such faults, errors or omissions. In the event
              of any such error, fault or omission, you should report it by
              contacting us at{" "}
              <a href="mailto:info@big4healthapp.com">
                info@big4healthapp.com
              </a>
            </p>
            <p>
              5.2 We do not warrant that your use of the Services or the
              Mobile Application will be uninterrupted and we do not warrant
              that any information (or messages) transmitted via the Services
              or the Mobile Application will be transmitted accurately,
              reliably, in a timely manner or at all. Notwithstanding that we
              will try to allow uninterrupted access to the Services and the
              Mobile Application, access to the Services and the Mobile
              Application may be suspended, restricted or terminated at any
              time.
            </p>
            <p>
              5.3 We do not give any warranty that the Services and the Mobile
              Application are free from viruses or anything else which may
              have a harmful effect on any technology.
            </p>
            <p>
              5.4 We reserve the right to change, modify, substitute, suspend
              or remove without notice any information or Services on the
              Mobile Application from time to time. Your access to the Mobile
              Application and/or the Services may also be occasionally
              restricted to allow for repairs, maintenance or the introduction
              of new facilities or services. We will attempt to restore such
              access as soon as we reasonably can. For the avoidance of doubt,
              we reserve the right to withdraw any information or Services
              from the Mobile Application at any time.
            </p>
            <ol>
              <li>
                <strong>6. SUSPENSION AND TERMINATION</strong>
              </li>
            </ol>
            <p>
              6.1 If you use (or anyone other than you, with your permission
              uses) the Mobile Application, any Services in contravention of
              these Terms and Conditions of Use, we may suspend your use of
              the Services and/or Mobile Application.
            </p>
            <p>
              6.2 If we suspend the Services or Mobile Application, we may
              refuse to restore the Services or Mobile Application for your
              use until we receive an assurance from you, in a form we deem
              acceptable, that there will be no further breach of the
              provisions of these Terms and Conditions of Use.
            </p>
            <p>
              6.3 CollConnect LLC shall fully co-operate with any law
              enforcement authorities or court order requesting or directing
              CollConnect LLC to disclose the identity or locate anyone in
              breach of these Terms and Conditions of Use.
            </p>
            <p>
              6.4 Without limitation to anything else in this Clause 6, we
              shall be entitled immediately or at any time (in whole or in
              part) to: (a) suspend the Services and/or Mobile Application;
              (b) suspend your use of the Services and/or Mobile Application;
              and/or (c) suspend the use of the Services and/or Mobile
              Application for persons we believe to be connected (in whatever
              manner) to you, if:
            </p>
            <p>
              6.5.1 If you commit any breach of these Terms and Conditions of
              Use;
            </p>
            <p>
              6.5.2 If we suspect, on reasonable grounds, that you have, might
              or will commit a breach of these Terms and Conditions of Use; or
            </p>
            <p>
              6.5.3 If we suspect, on reasonable grounds, that you may have
              committed or be committing any fraud against us or any person.
            </p>
            <p>
              6.5 .4 Our rights under this Clause 8 shall not prejudice any
              other right or remedy we may have in respect of any breach or
              any rights, obligations or liabilities accrued prior to
              termination.
            </p>
            <ol>
              <li>
                <strong>7. DISCLAIMER AND EXCLUSION OF LIABILITY</strong>
              </li>
            </ol>
            <p>
              7.1 The Mobile Application, the Services, the information on the
              Mobile Application and use of all related facilities are
              provided on an &#8220;as is, as available&#8221; basis without
              any warranties whether express or implied.
            </p>
            <p>
              7.2 To the fullest extent permitted by applicable law, we
              disclaim all representations and warranties relating to the
              Mobile Application and its contents, including in relation to
              any inaccuracies or omissions in the Mobile Application,
              warranties of merchant ability, quality, and fitness for a
              particular purpose, accuracy, availability, non-infringement or
              implied warranties from course of dealing or usage of trade.
            </p>
            <p>
              7.3 We do not warrant that the Mobile Application will always be
              accessible, uninterrupted, timely, secure, error free , virus
              free or other invasive or damaging code or that the Mobile
              Application will not be affected by any acts of God or other
              force majeure events, including inability to obtain or shortage
              of necessary materials, equipment facilities, power or
              telecommunications, lack of telecommunications equipment or
              facilities and failure of information technology or
              telecommunications equipment or facilities.
            </p>
            <p>
              7.4 While we may use reasonable efforts to include accurate and
              up-to-date information on the Mobile Application, we make no
              warranties or representations as to its accuracy, timeliness or
              completeness.
            </p>
            <p>
              7.5 We shall not be liable for any acts or omissions of any
              third parties howsoever caused, and for any direct, indirect,
              incidental, special, consequential or punitive damages,
              howsoever caused, resulting from or in connection with the
              mobile application and the services offered in the mobile
              application, your access to, use of or inability to use the
              mobile application or the services offered in the mobile
              application, reliance on or downloading from the mobile
              application and/or services, or any delays, inaccuracies in the
              information or in its transmission including but not limited to
              damages for loss of business or profits, use, data or other
              intangible, even if we have been advised of the possibility of
              such damages.
            </p>
            <p>
              7.6 We shall not be liable in contract, tort (including
              negligence or breach of statutory duty) or otherwise howsoever
              and whatever the cause thereof, for any indirect, consequential,
              collateral, special or incidental loss or damage suffered or
              incurred by you in connection with the Mobile Application and
              these Terms and Conditions of Use. For the purposes of these
              Terms and Conditions of Use, indirect or consequential loss or
              damage includes, without limitation, loss of revenue,
              anticipated savings or business, loss of data or goodwill, loss
              of use or value of any equipment including software, claims of
              third parties, and all associated and incidental costs and
              expenses.
            </p>
            <p>
              7.7 The above exclusions and limitations apply only to the
              extent permitted by law. None of your statutory rights as a
              consumer that cannot be excluded or limited are affected.
            </p>
            <ol>
              <li>
                <b>
                  <i> </i>
                </b>
                <strong>
                  <i>8. DISCLAIMER AND TERM OF SERVICE OF COLLCONNECT LLC </i>
                  MOBILE<i> APPLICATION</i>
                </strong>
              </li>
            </ol>
            <p>
              The following terms of service <b>must</b> be agreed on and
              adhere strictly to when using CollConnect LLC App.
            </p>
            <ul>
              <li>
                The user agrees that they are using the health app at their
                own risk.
              </li>
              <li>
                The user agrees that CollConnect and the app are not a
                certified medical doctor or health care professional, and are
                not acting as one.
              </li>
              <li>
                We are not liable for any adverse consequence, both health
                wise and medical, resulting from the use of the products and
                service (Nutrition supplement, T-shirts, Cooking books, and
                the Health Ingredients, while using CollConnect LLC mobile
                app.
              </li>
              <li>
                The user agrees that all transitional, behavioral, and
                personal data and the information extrapolated from such data,
                whether it was entered or transferred to the app, is the
                property of CollConnect LLC. This is to include any data
                acquired through their parties such a social media platforms
                or any other means.
              </li>
              <li>
                We are not liable for the misuse or abuse of the application.
              </li>
              <li>
                We are not liable for the unauthorized use of the application
                to include processing orders with the intent to distribute the
                product to other parties (underage patrons).
              </li>
              <li>
                The user agrees to the any relevant terms and conditions of
                the payment processing merchant.
              </li>
              <li>
                The user agrees to the communication via push notifications
                from CollConnect LLC and all establishments which use
                CollConnect LLC.
              </li>
              <li>
                The user agrees to CollConnect LLC using the user&#8217;s
                location to determine eligibility of venues, the push of
                notification and to otherwise gather marketing data and
                behavioral trends.
              </li>
              <li>
                The user agrees to any and all terms and conditions for the
                venue in which they have chosen to use CollConnect LLC Mobile
                App.
              </li>
              <li>
                The user agrees to the established transaction fees, service
                fees, and any other fees established by CollConnect LLC
                associated payment merchant.{" "}
              </li>
              <li>
                CollConnect LLC can change any and all fees without approval
                from the user at the discretion of CollConnect LLC at any
                given time, including changing from a free service to a paid
                service and charging for Services that were previously offered
                free of charge.
              </li>
            </ul>
            <ol>
              <li>
                <b></b>
                <b>9. AUTOMATIC SUBSCRIPTION RENEWAL</b>
              </li>
            </ol>
            <p>9.1 Subscription Options: </p>
            <ul>
              <li>1 Month: $8.95</li>
              <li>3 Months: $16.95</li>
              <li>Annually: $29.95</li>
            </ul>
            <p>
              9.2 The term of this subscription shall automatically renew for
              the same term unless you cancel the service and uninstall the
              app before the end of your current subscription.
            </p>
            <p>9.3 Subscription Terms of Use:</p>
            <p>
              A subscription is valid for one month, three months, or one
              year, depending on the subscription type selected. Payment will
              be charged to the credit/debit card we have on file for your
              account upon confirmation of purchase. Your subscription shall
              automatically renew unless auto-renew is turned off at least 24
              hours before the end of the current period. Your account shall
              be charged for renewal at the price listed within 24 hours prior
              to the end of the current period. Subscriptions may be managed,
              and auto-renewal may be turned off via the Settings in the
              Apple/Google store. The renewal shall begin on the day after the
              last day of your subscription. No cancellation of the current
              subscription is allowed during the active subscription period.
            </p>
            <ol>
              <li>
                <strong>10. INDEMNITY</strong>
              </li>
            </ol>
            <p>
              You agree to indemnify and keep us indemnified against any
              claim, action, suit or proceeding brought or threatened to be
              brought against us which is caused by or arising out of:
            </p>
            <p> (a) Your use of the Services.</p>
            <p>
              {" "}
              (b) Any other party’s use of the Services using your user ID,
              verification PIN and/or any identifier number.
            </p>
            <p>
              {" "}
              (c) Your breach of any of these Terms and Conditions of Use, and
              to pay us damages, costs and interest in connection with such
              claim, action, suit or proceeding.
            </p>
            <ol>
              <li>
                <strong>11. INTELLECTUAL PROPERTY RIGHTS</strong>
              </li>
            </ol>
            <p>
              10.1 All editorial content, information, photographs,
              illustrations, artwork and other graphic materials, and names,
              logos and trade marks on the Mobile Application are protected by
              copyright laws and/or other laws and/or international treaties,
              and belong to us and/or our suppliers, as the case may be. These
              works, logos, graphics, sounds or images may not be copied,
              reproduced, retransmitted, distributed, disseminated, sold,
              published, broadcasted or circulated whether in whole or in
              part, unless expressly permitted by us and/or our suppliers, as
              the case may be.
            </p>
            <p>
              10.2 Nothing contained on the Mobile Application should be
              construed as granting by implication, or otherwise, any license
              or right to use any trademark displayed on the Mobile
              Application without our written permission. Misuse of any
              trademarks or any other content displayed on the Mobile
              Application is prohibited.
            </p>
            <p>
              10.3 We will not hesitate to take legal action against any
              unauthorized usage of our trademarks, name or symbols to
              preserve and protect its rights in the matter. All rights not
              expressly granted herein are reserved.{" "}
            </p>
            <ol>
              <li>
                <strong>12 .AMENDMENTS</strong>
              </li>
            </ol>
            <p>
              11.1 We may periodically make changes to the contents of the
              Mobile Application, including to the descriptions and prices of
              goods and services advertised, at any time and without notice.
              We assume no liability or responsibility for any errors or
              omissions in the content of the Mobile Application.
            </p>
            <p>
              11.2 We reserve the right to amend these Terms and Conditions of
              Use from time to time without notice. The revised Terms and
              Conditions of Use will be posted on the Mobile Application and
              shall take effect from the date of such posting. You are advised
              to review these terms and conditions periodically as they are
              binding upon you.
            </p>
            <ol>
              <li>
                <strong>13. APPLICABLE LAW AND JURISDICTION</strong>
              </li>
            </ol>
            <p>
              12.1 The Mobile Application can be accessed from all countries
              around the world where the local technology permits. As each of
              these places have differing laws, by accessing the Mobile
              Application both you and we agree that the laws of the United
              States , without regard to the conflicts of laws principles
              thereof, will apply to all matters relating to the use of the
              Mobile Application.
            </p>
            <p>
              12.2 You accept and agree that both you and we shall submit to
              the exclusive jurisdiction of the courts of American in respect
              of any dispute arising out of and/or in connection with these
              Terms and Conditions of Use.
            </p>
          </div>
        </div>
      </div>
      <div class="eut-bg-wrapper"></div>
    </div>
  )
}

export default TermApp;