import { useEffect, useRef, useState } from "react"
import { userService } from "../services"
import { config, appName } from "../config/config"
import { Button, Modal } from "react-bootstrap"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import { Swiper, SwiperSlide, Autoplay } from 'swiper/react';
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookMessengerShareButton,
} from "react-share";
import {
  EmailIcon,
  FacebookIcon,
  LineIcon,
  LinkedinIcon,
  TwitterIcon,
  WhatsappIcon,
  FacebookMessengerIcon,
} from "react-share";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Swal from "sweetalert2"

function MedUserData(props) {
  const handleCloseSuccess = () => {
    setShowSuccess(false)
  }

  const [copy, setCopy] = useState(true);
  const [isLoading, setIsLoading] = useState(false)
  let title = `Hey! Checkout this ${appName} post`;
  const [share_url, setshare_url] = useState(null);
  const [show, setShow] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [userDetail, setUserDetail] = useState("")
  const play = useRef("")
  let history = useHistory()
  const [userid, setUserId] = useState(localStorage?.getItem("user_id") ? localStorage?.getItem("user_id") : "")

  useEffect(() => {
    if (props) {
      getMedUserDeatils(props?.data, props?.userId ? props?.userId :"" )
    }
  }, [props])

  useEffect(() => {
    if (copy) {
      const interval = setInterval(() => {
        setCopy(false);
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [copy]);

  useEffect(() => {
    let location = window.location.href
    setshare_url(location);
  }, [])

  /// getdetails api
  function getMedUserDeatils(id, userid) {
    userService.getmed_detail(id, userid).then((res) => {
      setUserDetail(res?.data?.getDetailAfteViews)
    })
      .catch((Error) => {
      })
  }

  function postLikeAndDislike(id) {
    const params = {
      post_id: id,
    }
    setIsLoading(true)
    userService.postLike(params).then((res) => {
      setIsLoading(false)
      getMedUserDeatils(id, userid)
    })
      .catch((error) => {
        setIsLoading(false)
      })
  }

  function handleContextMenu(event) {
    event.preventDefault();
  }


  const [onplaying, setOnPlaying] = useState(false)
  let pathname = window.location.pathname?.split("/")[1];
  return (
    <>
      <div className="profile-right-body">
        <div className="container">
          <div className="back-btn-sec mb-3 d-flex align-item-center justify-content-between flex-wrap">
            <Button onClick={(e) => {
              if (pathname == "profilenew") {
                history.push("/profilenew")
              }
              else if (pathname == "profile") {
                history.push(`/profile/${userDetail?.user?.userSlug}`)
              }
            }}
              className="btn back-btn"><i className="fa  fa-angle-left"></i>Back to Listing</Button>

            <button className="join-button mt-0" onClick={(e) => setShowSuccess(true)} >
              <i class="fa fa-share-alt mr-1" aria-hidden="true"></i>  Share
            </button>
          </div>
          <div className="big-med-details-wrap">
            <div className="big-med-main-img big-med-main-vid">
              {userDetail?.media_type == "video" &&
                <div className="big-med-main-img big-med-main-vid">
                  <video
                    onContextMenu={handleContextMenu}
                    controlsList="nodownload"
                    width="100%"
                    height="100%"
                    ref={play}
                    muted
                    loop
                    onPlay={(e) => setOnPlaying(true)}
                    onEnded={(e) => setOnPlaying(false)}
                    preload='auto'
                    playsinline
                    controls
                    poster={userDetail?.thumbnail_icon}
                  >
                    <source
                      src={userDetail?.media[0]}
                      type="video/mp4"
                    />
                    <source
                      src={userDetail?.media[0]}
                      type="video/ogg"
                    />
                    <source
                      src={userDetail?.media[0]}
                      type="video/webm"
                    />
                  </video>
                  {
                    !onplaying && <img className="video-btn" onClick={(e) => play.current.play()} src={require("../images/video-play.svg").default} alt="img" />}
                </div>}

            </div>
            {userDetail?.media_type == "pdf" && <div className="big-med-main-img big-med-main-pdf ">
              <a href={userDetail?.media[0]} target='_blank'><img src={userDetail?.media_type == "pdf" && userDetail?.thumbnail_icon && userDetail?.thumbnail_icon != "undefined" ? userDetail?.thumbnail_icon : require("../images/big-med-pdf.jpg").default} alt="img" /></a>
            </div>}
            {userDetail?.media_type == "voice" && <div className="big-med-main-img big-med-main-audio">

              <a href="#"><img src={userDetail?.media_type == "voice" && userDetail?.thumbnail_icon && userDetail?.thumbnail_icon != "undefined" ? userDetail?.thumbnail_icon : require("../images/big-med-audio.jpg").default} alt="img" /></a>
              <div class="player">
                <audio controls autoplay>
                  <source src={userDetail?.media[0]} type="audio/ogg" />
                  <source src={userDetail?.media[0]} type="audio/mpeg" />
                </audio>
              </div>

            </div>}
            {userDetail?.media_type == "image" && <div className="big-med-main-img profile-big-me">
              {userDetail?.media?.length > 1 ? (
                <Swiper
                  spaceBetween={50}
                  slidesPerView={1}
                  speed={700}
                  loop={true}
                  pagination={{ clickable: true }}
                >
                  {userDetail?.media?.map((image, key) => (
                    <SwiperSlide key={key}>
                      <a href="#">
                        <img className="" src={image} alt="img" />
                      </a>
                    </SwiperSlide>
                  ))}
                </Swiper>
              ) : (
                <a href="#">
                  <img className="" src={userDetail?.media[0]} alt="img" />
                </a>
              )}
            </div>}
          </div>
          <div className="d-flex align-items-center justify-content-between">
            <div className="big-med-details-text">
              <div className="big-med-text mb-4">
                <div className="video-like">
                  <img onClick={() => {
                    if (localStorage.getItem("token")) {
                      postLikeAndDislike(userDetail?._id)
                    } else {
                      Swal.fire("Warning", "To perform this action Login is required.", "warning").then((res) => {
                        window.location.href = "/signin";
                        let redirectUri = window.location.pathname + window.location.search;
                        localStorage.setItem("redirectUri", redirectUri);
                      })

                    }
                  }} src={require(userDetail?.isLikedByMe == false ? "../images/update/like.svg" : "../images/update/chat-icon-fill.svg").default} alt="img" />

                  <p> {userDetail?.likeCount ? userDetail?.likeCount :""} likes</p>
                </div>
                <h4>{userDetail?.title ? userDetail?.title :""}</h4>

                <p><span className="channel-views">{userDetail?.views ? userDetail?.views : 0} </span>  <span>|</span> <span className="channel-time"> {userDetail?.timeAgo}</span></p>
                <div className=""></div>
              </div>
              <div className="big-med-icon-text d-flex">
                <div className="big-med-thumbnail">
                  {userDetail?.user && userDetail?.user?.profilePicture !== "undefined" ? <img src={userDetail && userDetail?.user?.profilePicture ? userDetail?.user?.profilePicture : require("../../src/images/update/dummy-profile-img.jpg").default} alt="img" /> : <img src={require("../../src/images/update/dummy-profile-img.jpg").default} alt="img" />}
                </div>

                <div className="big-med-text mr-4">
                  <p className="channel-name">{userDetail?.user?.name}</p>
                  {localStorage.getItem("userType") == "Professional" && <p><span>{userDetail?.user?.professionalType}</span></p>}
                </div>
                <div className="details-cetagory d-flex"><span>Category: <b>{userDetail?.category?.name}</b></span><span>|</span><span>Severity Level: <b>{userDetail?.severity_level}</b></span><span>|</span>{localStorage.getItem("userType") == "Professional" && <span>Accepting New Patient: <b>{userDetail?.looking_provider == false ? "No" : "Yes"}</b></span>}{localStorage.getItem("userType") == "Client" && <span>Looking for a healthcare provider: <b>{userDetail?.healthcare_provider == false ? "No" : "Yes"}</b></span>}{localStorage.getItem("userType") == "Professional" && <span>Hospital/Clinic Name :<b>{userDetail?.HospitalName ? userDetail?.HospitalName : "N/A"}</b></span>}</div>
              </div>
            </div>

          </div>
          <div className="description mt-3 mb-5">
            <p>{userDetail?.description}</p>
          </div>
        </div>

      </div>
      <Modal show={showSuccess} onHide={handleCloseSuccess} centered>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-0">
          <div className="email-verfication text-center">
            <h3 className="mt-4 mb-3 black-text">Share Details </h3>
            <p className="black-text">
              <span>Would you like to share it with your friends/communities?</span>
            </p>
            <div className="copy-profile d-flex mb-4">
              {copy ? (
                <div>
                  <span style={{ fontSize: "12px", color: "#ff7750" }}>
                    Link Copied
                  </span>
                  <br></br>
                </div>
              ) : (
                <div>
                  <span style={{ fontSize: "10px" }}></span>
                  <br></br>
                </div>
              )}
              <input
                type="text"
                value={share_url}
                disabled={true}
                style={{ fontSize: "12px" }}
              />
              <CopyToClipboard text={share_url} onCopy={() => setCopy(true)}>
                <i
                  className="fa fa-clipboard Demo__some-network__share-button"
                  title="Copy to clipboard"
                  aria-hidden="true"
                  style={{ fontSize: "19px", cursor: "pointer" }}
                ></i>
              </CopyToClipboard>
            </div>
            <div className="congrats-social-icons mb-2">
              <FacebookShareButton
                url={share_url}
                quote={title}
                className="Demo__some-network__share-button"
              >
                <FacebookIcon size={32} round />
              </FacebookShareButton>
              <EmailShareButton
                url={share_url}
                subject={title}
                body="Lincode Card: "
                separator=" "
                className="Demo__some-network__share-button"
              >
                <EmailIcon size={32} round />
              </EmailShareButton>
              <TwitterShareButton
                url={share_url}
                title={title}
                className="Demo__some-network__share-button"
              >
                <TwitterIcon size={32} round />
              </TwitterShareButton>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )

}
export default MedUserData;