import React, { useRef } from 'react'
import { useEffect, useState } from "react";
import Header from './common/Header'
import Footer from './common/Footer'
import Download from './common/Download'
import { Button, Col, Form, Modal, Row } from 'react-bootstrap'
import { userService } from '../services';
import Swal from 'sweetalert2';
import { config } from '../config/config';
import Pagination from "react-js-pagination";
import Loader from './common/Loader';
import socket, { APPLICATION_ID, AUTH_KEY, AUTH_SECRET, ACCOUNT_KEY } from '../config/socket';
import VideoCall from '../pages/VideoCall';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';


const ScheduleListing = () => {
  const [isMuted, setIsMuted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [totalRecord, setTotalRecord] = useState("")
  const [perPage, setPerPage] = useState("")
  const [page, setPage] = useState(1)
  const [appointmentData, setAppointmentData] = useState([]);
  const [show, setShow] = useState(false);
  const [rejectAppointment, setRejectAppointment] = useState(false)
  const [no, setNo]=useState(false)
  const handleClose = () => setShow(false);
  const RejectedRef = useRef()
  const [description, setDescription] = useState("")
  const [showModal, setShowModal] = useState(false);
  const [fullDescription, setFullDescription] = useState('');
  const [videocalldata, setvideocallData] = useState({ "calltype": "", item: "", videoModal: false, session: "", localStream: "", remoteStream: "" })
  const [session, setSession] = useState("")
  const [userDetail, setUserDetail] = useState()
  const urlLocation = useLocation();
    const queryParams = new URLSearchParams(urlLocation.search);
    const activedata = queryParams.get('activeTab');
    const [activeTab, setActiveTab] = useState(activedata ? activedata :'world-1');

  const handleShow = (selectid) => {
    setShow(true);
    RejectedRef.current = selectid;

  }

  const selectedRejectRef = useRef()
  const handleRejectAppointment = (selectid) => {
    setRejectAppointment(true);
    selectedRejectRef.current = selectid;
  }
  const [showtime, setShowtime] = useState(false);
  const [cancellationReason, setCancellationReason] = useState("")
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const [usercategory, setUsercategory] = useState(localStorage.getItem("selectedTab") == "world-1" ? "pending" : localStorage.getItem("selectedTab") == "world-2" ? "upcoming" : localStorage.getItem("selectedTab") == "world-3" ? "past" : localStorage.getItem("selectedTab") == "world-4" ? "cancelled" : "pending")

  useEffect(() => {
    getAppontmentList(page, usercategory);
  }, []);



  useEffect(() => {
    const selectedTab = localStorage.getItem('selectedTab');
    if (selectedTab) {
      setActiveTab(selectedTab);
    }
  }, []);

  const handleTabClick = (tabId) => {
    if (activeTab === tabId) {
      return;
    }

    setActiveTab(tabId);
    localStorage.setItem('selectedTab', tabId);
    if (tabId === 'world-1') {
      setUsercategory("pending")
      getAppontmentList(1, 'pending');
    } else if (tabId === 'world-2') {
      setUsercategory("upcoming")
      getAppontmentList(1, 'upcoming');

    }
    else if (tabId === 'world-3') {
      setUsercategory("past")
      getAppontmentList(1, 'past');
    }
    else if (tabId === 'world-4') {
      setUsercategory("cancelled")
      getAppontmentList(1, 'cancelled');
    }
  };

  useEffect(() => {
    getProfileList()
  }, [])

  function getProfileList() {
    let user_id = localStorage.getItem('user_id');
    // let params = { userId: user_id }
    let token =localStorage.getItem("token")
    let params = { userId: user_id, token:token }
    userService.getProfile(params).then(res => {
      if (res.data.status == 200) {
        let userData = res.data.data;
        setUserDetail(userData)
      }
    }).catch((error) => {
    });
  }
  function handlePageChange(pageNumber) {
    setPage(pageNumber);
    getAppontmentList(pageNumber, usercategory);
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 1000);
  }

  const handleReadMoreClick = (description) => {
    setFullDescription(description);
    setShowModal(true);
  };

  function getAppontmentList(page, usercategory) {
    setIsLoading(true)
    userService.getScheduleList(page - 1, usercategory).then((res) => {
      if (res?.data?.status === 200) {
        setAppointmentData(res?.data?.data);
        setTotalRecord(res?.data?.total_records)
        setPerPage(res?.data?.per_page);
        setIsLoading(false)
      }
    })
      .catch((error) => {
        setIsLoading(false)
        Swal.fire("Warning",error?.response?.data,"warning").then((e) =>{
          localStorage.clear()
          window.location.href="/";
      })
      })
  }

  function AcceptAppointment(id, status) {
    const params = {
      status: status,
      id: id
    };
    setIsLoading(true)
    userService.acceptAppointment(params)
      .then((res) => {
        if (res?.data?.status === 200) {
          Swal.fire("Success", "Appointment Request accepted successfully.", "success").then((e) => {
            getAppontmentList(page, usercategory);
          })
          setIsLoading(false)
        }
      })
      .catch((error) => {
        setIsLoading(false)
      });
  }

  function RejectAppointment(id, status, cancellationReason) {
    const params = {
      status: status,
      id: id,
      cancellationReason: cancellationReason
    };
    setIsLoading(true)
    userService.rejectAppointment(params)
      .then((res) => {
        if (res?.data?.status === 200) {
          setIsLoading(false)
          Swal.fire("Success", "Appointment Cancelled successfully.", "success").then((res) => {
            getAppontmentList(page, usercategory);
            setShow(false)
            setRejectAppointment(false)
          })
        }
      })
      .catch((error) => {
        setIsLoading(false)
      });
  }
  function getCircularReplacer() {
    const ancestors = [];
    return function (key, value) {
      if (typeof value !== "object" || value === null) {
        return value;
      }
      while (ancestors.length > 0 && ancestors.at(-1) !== this) {
        ancestors.pop();
      }
      if (ancestors.includes(value)) {
        return "[Circular]";
      }
      ancestors.push(value);
      return value;
    };
  }


  const renderAppointments = () => {
    if (appointmentData) {
      return (
        <div className="webinar-outer-box schedule-box no-data-area">
          <div className="row">
            {appointmentData && appointmentData?.length > 0 ?
              appointmentData?.map((item, key) => {
                const bookingDate = new Date(item?.bookingDate);
                const bookingDateEnd = new Date(item?.bookingEndDate);
                const options = {
                  weekday: 'long',
                  month: 'short',
                  day: 'numeric',
                  hour: 'numeric',
                  minute: 'numeric',
                };
                const formattedDate = bookingDate.toLocaleString('en-US', options);
                const formattedDateEnd = bookingDateEnd.toLocaleString('en-US', options);
                var currentDate = new Date().getTime();
                var givenDate = bookingDate.getTime();
                var timeDifference = givenDate - currentDate;
                var joinNowTime = (timeDifference / 60000)
                var hours = Math.floor(joinNowTime / 60);
                var minutes = joinNowTime % 60;
                return (
                  <>
                    <aside className="col-lg-6 col-md-6 col-sm-12 col-12">
                      <div className="webinar-inner-box cursor-issue">
                        <img src={item?.meduserDetail?._id == localStorage?.getItem("user_id") ?
                          (item?.userDetail?.profilePicture && item?.userDetail?.profilePicture != "undefined" ? item?.userDetail?.profilePicture :
                            require('../images/update/dummy-profile-img.jpg').default) :
                          (item?.meduserDetail?.profilePicture && item?.meduserDetail?.profilePicture != "undefined" ?
                            item?.meduserDetail?.profilePicture :
                            require('../images/update/dummy-profile-img.jpg').default)} alt="img" className="mr-2 schedule-img" />
                        <div>
                          <h4>{item?.userDetail?.name}</h4>
                          <p>{item?.category?.name}</p>
                          <p className="mb-0 blue-text"><img src={require('../images/update/date-blue.svg').default} alt="img" className="mr-2" />{formattedDate}{" "}{userTimezone}</p>
                          <span className='description-view'>
                            {item?.notes?.length > 30 ? (
                              <div>
                                {item?.notes?.slice(0, 30) + '...'}
                                <a
                                  className='description-view-btn' data-toggle="modal" data-target="#exampleModalCenter13"
                                  onClick={() => handleReadMoreClick(item?.notes)}
                                >
                                  Read More
                                </a>{' '}
                              </div>
                            ) : (
                              item?.notes
                            )}
                          </span>
                          {item.status == "ACCEPTED" && <p>
                            {joinNowTime > 10 ?
                              `Start in ${hours > 0 ? `${hours} hours` : ''} ${minutes > 0 ? `${minutes.toFixed(0)} minutes` : ''}` :
                              "Started"
                            }
                          </p>}
                          {activeTab === 'world-1' && (
                            <div className='btn-alignment'>
                              <button className="join-button mr-2" onClick={(e) => AcceptAppointment(item?._id, "ACCEPTED")}>
                                Accept
                              </button>
                              <button className="join-button red-btn" onClick={(e) => handleRejectAppointment(item?._id)}>Reject</button>
                            </div>
                          )}
                          {activeTab === 'world-2' &&
                            item?.status === "ACCEPTED" && (
                              <div className='btn-alignment'>
                                {(item?.status === "ACCEPTED" && joinNowTime < 10) ?
                                  (
                                    <div>
                                      <VideoCall isFromHeader="true" isUserCalling="true" item={item} />
                                    </div>
                                  ) :
                                  (
                                    <button disabled={true} className='join-now-btn-box'>Join Now</button>
                                  )}
                                <button className="join-button red-btn" onClick={(e) => handleShow(item?._id)}>Cancel</button>
                              </div>
                            )}
                          {activeTab === 'world-3' && (
                            <div>
                              <a className="check-btn mr-2">Completed<img src={require('../images/update/check-mark.svg').default} alt="img" className="ms-2" /></a>
                            </div>
                          )}
                          {activeTab === 'world-4' && (
                            <div>
                              <a className="cancel-btn mr-2">Cancelled<img src={require('../images/update/cancel.svg').default} alt="img" className="ms-2" /></a>
                            </div>
                          )}
                          <a className='blue-text vew-chat' onClick={(e) => window.location.href = `/chat/${item?.userDetail?._id}`} ><img src={require('../images/update/chat-view.svg').default} alt="img" className="mr-2" />View Chat</a>
                        </div>
                      </div>
                    </aside>
                  </>


                )
              }) : <>
                <div className="profile-right-side no-data-profile no-upper-main-data mb-5">
                  <div className="no-data-box">
                    <img src={require("../images/update/no-data.png").default} alt="img" className="mr-2" />
                    <h3>No data found</h3>
                  </div>
                </div></>}

            {totalRecord > perPage && (
              <div className='col-md-12 text-center'>
                <div className="pagination1">
                  <Pagination
                    activePage={page}
                    itemsCountPerPage={perPage}
                    totalItemsCount={totalRecord}
                    pageRangeDisplayed={5}
                    onChange={(e) => handlePageChange(e)}
                    hideDisabled={true}
                    prevPageText={"Previous"}
                    nextPageText={"Next"}
                  />
                </div>
              </div>
            )}

          </div>
          <div class="modal fade description-popup" id="exampleModalCenter13" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLongTitle">Description</h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  {fullDescription}     </div>
                <div class="modal-footer description-btn">
                  <button type="button" class="btn btn-primary " data-dismiss="modal" aria-label="Close">Ok</button>
                </div>
              </div>
            </div>
          </div>
        </div>


      );
    }
  };
  return (
    <>
      {isLoading && <Loader />}
      <div className="profile-right-header">
        <h4>My Booked Medical Chat</h4>
      </div>
      <div className="world-box my-schedule-area">
        <div className="container">
          <ul class="nav nav-tabs">
            <li className="nav-item">
              <a
                className={`nav-link ${activeTab === 'world-1' ? 'active' : ''}`}
                data-toggle="tab"
                onClick={() => handleTabClick('world-1')}
                href="#world-1"
              >
                Appointment Request
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link ${activeTab === 'world-2' ? 'active' : ''}`}
                data-toggle="tab"
                onClick={() => handleTabClick('world-2')}
                href="#world-2"
              >
                Upcoming Appointments
              </a>
            </li>
            <li class="nav-item">
              <a
                className={`nav-link ${activeTab === 'world-3' ? 'active' : ''}`}
                onClick={() => handleTabClick('world-3')}
                data-toggle="tab"
                href="#world-3">Completed Appointments</a>
            </li>
            <li class="nav-item">
              <a
                className={`nav-link ${activeTab === 'world-4' ? 'active' : ''}`}
                onClick={() => handleTabClick('world-4')}
                data-toggle="tab"
                href="#world-4">Canceled Request</a>
            </li>
          </ul>
          <div className="tab-content">
            {renderAppointments()}
          </div>
        </div>
      </div>

      <Modal show={show} onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered className="quiz-pop quiz-close"
      >
        <Modal.Header className="m-auto q-mark">
          <Modal.Title>?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='start-the-quiz text-center p-0'>

          <p>Are you sure you want to Cancel this appointment?</p>
          <div className="Signup_box text-center contact-main-box">
            <Form className="text-left">
              <label className='text-left'>
                Reason<span className="mandatory"></span>
              </label>
              <textarea
                className=" w-100 select-main"
                placeholder="Enter Message"
                name="message"
                onChange={(e) => setCancellationReason(e.target.value)}
                onKeyDown={(e) => {
                  if (!e.target.value && e.code === "Space") {
                    e.preventDefault();
                  }
                }}

                maxLength={500}
              ></textarea>
            </Form>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-none m-auto">
          <Button className='btn notnowbtn' onClick={handleClose}>NO</Button>
          <Button className='btn quiznow-btn' onClick={(e) => RejectAppointment(RejectedRef.current, "CANCELLED", cancellationReason)}>YES</Button>
        </Modal.Footer>
      </Modal>
      <Modal show={rejectAppointment} onHide={handleRejectAppointment}
        aria-labelledby="contained-modal-title-vcenter"
        centered className="quiz-pop quiz-close"
      >
        <Modal.Header className="m-auto q-mark">
          <Modal.Title>?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='start-the-quiz text-center p-0'>

          <p>Are you sure you want to Reject this appointment?</p>
          <div className="Signup_box text-center contact-main-box">
            <Form className="text-left">
              <label className='text-left'>
                Reason<span className="mandatory"></span>
              </label>
              <textarea
                className=" w-100 select-main"
                placeholder="Enter Message"
                name="message"
                onChange={(e) => setCancellationReason(e.target.value)}
                onKeyDown={(e) => {
                  if (!e.target.value && e.code === "Space") {
                    e.preventDefault();
                  }
                }}

                maxLength={500}
              ></textarea>
            </Form>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-none m-auto">
          <Button className='btn notnowbtn' onClick={(e) => setRejectAppointment(false)}>NO</Button>
          <Button className='btn quiznow-btn' onClick={(e) => RejectAppointment(selectedRejectRef.current, "REJECTED", cancellationReason)}>YES</Button>
        </Modal.Footer>
      </Modal>
    </>
  )

}

export default ScheduleListing;