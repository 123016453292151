import { useEffect, useState } from "react"
import { useParams, useLocation } from "react-router-dom"
import { userService } from '../services/user.services';
import { Modal, Button } from 'react-bootstrap';
import Footer from "../Components/common/Footer";
import Header from '../Components/common/Header';

function VerifyEmail() {
    const [showloader, setShowloader] = useState(true)
    const [show, setShow] = useState("verifying")
    const handleClose = () => setShow(false);
    useEffect(() => {
        EmailVerifyApi();
    }, [])
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get('token');
    function EmailVerifyApi() {
        const params = {
            token: token,
        }
        userService.EmailVerifyApi(params).then((res) => {
            if (res?.status === 200) {
                setShow("success");
            }
        }).catch((error) => {
            setShow("error")
        })
    }
    return (
        <>

        <Header />
            <section className="user-login-page user-login-page-animate">
                <div className="page-title">
                    <h2>Email Verification</h2>
                </div>
            </section>
            <div className="filled-area filled-area1 text-center d-flex" style={{ height: '24em' }}>
                <div className="col-md-4 m-auto">
                <div className="expire-mail">
                    <div className="popup1 pt-5">
                      {show === "success" &&  <span className="circle pulse red">
                            <i className="fa fa-check"></i>
                        </span>
                        }
                        {show === "error" &&<span className="circle pulse red black">
                            <i className="fa fa-plus"></i>
                        </span>}
                        {show === "success" && <div className="verify-txt-email">
                            <h3>Great</h3>
                            <p>Your Email has been verified!</p>
                        </div>}
                        {show === "error" && <div className="verify-txt-email">
                        <h3>Fail</h3>
                        <p>Your email has either been verified already or the token has expired.</p>
                    </div>}
                        <form onSubmit={"handleNewPassword"} >
                        </form >
                    </div>
                    </div>
                </div>
            </div >
            <Footer />
        </>
    )
}
export default VerifyEmail; 