

import Header from './common/Header'
import Footer from './common/Footer'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { userService } from '../services/user.services';
import React, { useState, useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import { config } from '../config/config';
import Pagination from "react-js-pagination";
import Swal from 'sweetalert2';
import { useHistory, useLocation, useParams } from "react-router-dom/cjs/react-router-dom.min";
import Loader from './common/Loader'
import { Swiper, SwiperSlide, Autoplay } from 'swiper/react';


const Big_med = () => {
    const history = useHistory()

    let pathname = window.location.pathname?.split("/")[1]
    const [isLoading, setIsLoading] = useState(false);
    const [medUserList, setMedUserList] = useState()
    const urlLocation = useLocation();
    const queryParams = new URLSearchParams(urlLocation.search);
    const medAndMePage = queryParams.get('page')
    const [page, setPage] = useState( medAndMePage ? Number(medAndMePage) :1);
    const [totalpage, settotalpage] = useState();
    const [userType, setUserType] = useState(pathname == "big-med" ? "Professional" : pathname == "big-me" && "Client");
    const [userid, setUserId] = useState(localStorage?.getItem("user_id") ? localStorage?.getItem("user_id") : "")
    const [categoryList, setcategoryList] = useState()
    const [activeTab, setActiveTab] = useState("");
    const play = useRef(null)
    const [onplaying, setOnPlaying] = useState(false)
    const [userDetail, setUserDetail]=useState("")

    const handleTabClick = (tab) => {
        if(activeTab == tab?._id){
            return;
          }
        history.push(`${window.location.pathname}?page=${1}`)
        
        setActiveTab(tab._id);
        setPage(1)
        medicalList(1, userType, tab._id)
    };

    useEffect(() =>{
        getProfileList()
        localStorage.removeItem("redirectUri")
    },[])

    useEffect(() => {
        if (pathname == "big-med") {
            medicalList(page, "Professional", activeTab)
        }
        else if (pathname == "big-me") {
            medicalList(page, "Client", activeTab)
        }
        getCotagery()
    }, [pathname])

    function medicalList(page, userType, activeTab) {
        setIsLoading(true)
        userService.med_list((page - 1), userType, activeTab).then((res) => {
            setMedUserList(res?.data?.postsWithViewsAndDaysAgo)
            settotalpage(res?.data?.totalCount)
            setIsLoading(false)
        })
            .catch((Error) => {
                setIsLoading(false)
            })
    }

    function getProfileList() {
        let user_id = localStorage.getItem('user_id');
        let token =localStorage.getItem("token")
        let params = { userId: user_id, token:token }
        userService.getProfile(params).then(res => {
          if (res.data.status == 200) {
            let userData = res.data.data;
            setUserDetail(userData)
            localStorage.setItem("qb_user_id", userData?.qb_user_id)
          }
        }).catch((error) => {
        });
      }

    function getCotagery() {
        userService.get_category().then((res) => {
            setcategoryList([{ name: "All", _id: "" }, ...res?.data?.data])

        })
            .catch((Error) => {
            })
    }



    function handlePageChange(pageNumber) {
        history.push(`${window.location.pathname}?page=${pageNumber}`)
        setPage(pageNumber);
        medicalList(pageNumber, userType, activeTab)
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 1000);
    }



    const onVideoPlay = (item) => {
        setMedUserList(prev =>
            prev.map((value) =>
                value._id == item._id ? { ...value, videoPlay: true } : { ...value, videoPlay: false }
            )
        )
    }
    const onVideoPause = (item) => {
        setMedUserList(prev =>
            prev.map((value) =>
                value._id == item._id ? { ...value, videoPlay: false } : { ...value, videoPlay: false }
            )
        )
    }
    let pathName = window.location.pathname.split("/")

    function gotoMidAndMed(itemId) {
        let pathName = window.location.pathname.split("/")
        let url = `${pathName[1]}-details?id=${itemId}&page=${page}`
        if (localStorage.getItem("userType") == "Professional") {
            window.location.href =  `${pathName[1]}-details?id=${itemId}&page=${page}`
        }
        else {
            window.location.href = url;
        }
    }

    function handleContextMenu(event) {
        event.preventDefault();
    }
    return (
        <>

            <Header />
            {isLoading && <Loader />}
            <div className="big-med">
                <div className="container">
                    <div className="big-med-heading d-flex align-items-start justify-content-between w-100">
                        {pathname === "big-med" && <h1>BIG4 Health Med, a worldwide open healthcare platform- addressing unmet medical needs.</h1>}
                        {pathname === "big-me" && <h1>BIG4 Health Me, a worldwide open client platform- looking for medical answers.</h1>}
                       {(pathname === "big-me" && localStorage.getItem("userType") == "Client") && <a 
                       onClick={(e) =>{
                        if(userDetail?.ProfileCreated ==0){
                            Swal.fire("Warning","Your profile is incomplete. Please take a moment to fill in the required details.","warning").then((e) =>{
                                window.location.href ="/create-profile";
                                let redirectUri = window.location.pathname + window.location.search;
                                localStorage.setItem("redirectUri", redirectUri);
                            })
                        }
                        else{
                            window.location.href="/create-post-form";
                        }
                       }} 
                       className="btn create"><img src={require("../images/update/create-vid-icon.svg").default} alt="img" />Create</a>}
                        {(pathname === "big-med" && localStorage.getItem("userType") == "Professional") && <a 
                        onClick={(e) =>{
                            if(userDetail?.ProfileCreated ==0){
                                Swal.fire("Warning","Your profile is incomplete. Please take a moment to fill in the required details.","warning").then((e) =>{
                                    window.location.href ="/create-profile";
                                    let redirectUri = window.location.pathname + window.location.search;
                                    localStorage.setItem("redirectUri", redirectUri);
                                })
                            }
                            else{
                                window.location.href="/create-post-form";
                            }
                           }} 
                        className="btn create"><img src={require("../images/update/create-vid-icon.svg").default} alt="img" />Create</a>}

                    </div>
                    {pathname === "big-med" && <p>Healthcare providers share challenging BIG4 cases that they have managed.</p>}
                    {pathname === "big-me" && <p>Clients share their testimonies, showcasing their struggles with the BIG4: high blood pressure, diabetes, high cholesterol, or obesity.</p>}
                    <div>
                        <div className="tab-container">
                            {categoryList?.map(tab => (
                                <button
                                    key={tab._id}
                                    className={`cat-list mb-3 ${activeTab === tab._id ? 'active' : ''}`}
                                    onClick={() => handleTabClick(tab)}
                                >
                                    {tab?.name}
                                </button>
                            ))}
                        </div>
                    </div>

                    <div className="row mt-4 notranslate" >
                        {medUserList?.length > 0 ?
                            medUserList?.map((item, index) => {
                                return (
                                    <>
                                        <div className="col-md-6 col-lg-4">
                                            <div className="big-med-wrap big-med-swipe"
                                            >
                                                {item?.media_type == "image" &&
                                                    <div className="big-med-main-img" onClick={() => {
                                                        gotoMidAndMed(item?._id)
                                                    }}>
                                                        {item?.media?.length > 1 ? ( // Check if there is more than one image
                                                            <Swiper
                                                                spaceBetween={50}
                                                                slidesPerView={1}
                                                                speed={700}
                                                                loop={true}
                                                                pagination={{ clickable: true }}
                                                            >
                                                                {item?.media?.map((image, key) => (
                                                                    <SwiperSlide key={key}>
                                                                        <a href="#">
                                                                            <img className="" src={image} alt="img" />
                                                                        </a>
                                                                    </SwiperSlide>
                                                                ))}
                                                            </Swiper>
                                                        ) : (
                                                            <a href="#">
                                                                <img className="" src={item?.media[0]} alt="img" />
                                                            </a>
                                                        )}


                                                    </div>}
                                                <div className="big-med-icon-text"
                                                    onMouseOver={event => onVideoPlay(item)}
                                                    onMouseOut={event => onVideoPause(item)}
                                                >

                                                    {item?.media_type == "video" &&
                                                        (<div className="big-med-main-img big-med-main-vid" >
                                                            <span onClick={() => {
                                                                gotoMidAndMed(item?._id)
                                                            }} >

                                                                {item?.videoPlay ?
                                                                    <video
                                                                        onContextMenu={handleContextMenu}
                                                                        controlsList="nodownload"
                                                                        autoPlay={item?.videoPlay}
                                                                        width="100%"
                                                                        height="100%"
                                                                        muted
                                                                        loop
                                                                        preload='auto'
                                                                        playsinline
                                                                        poster={ item?.thumbnail_icon}

                                                                    >
                                                                        <source
                                                                            src={ item?.media[0] + "#t=0.1"}
                                                                            type="video/mp4"
                                                                        />
                                                                        <source
                                                                            src={ item?.media[0] + "#t=0.1"}
                                                                            type="video/ogg"
                                                                        />
                                                                        <source
                                                                            src={ item?.media[0] + "#t=0.1"}
                                                                            type="video/webm"
                                                                        />
                                                                    </video>

                                                                    :

                                                                    <div className="big-med-main-img">
                                                                        <a href="#"><img src={ item?.thumbnail_icon} alt="img" /></a>
                                                                    </div>
                                                                }
                                                            </span>

                                                            {!item.videoPlay &&
                                                                <img className="video-btn" onClick={(e) => onVideoPlay(item)} src={require("../images/video-play.svg").default} alt="img" />
                                                            }
                                                        </div>)
                                                    }
                                                    {item?.media_type == "pdf" && <div className="big-med-main-img big-med-pdf" onClick={() => {
                                                        gotoMidAndMed(item?._id)

                                                    }}>
                                                        <a href={item?.media[0]} target='_blank'><img src={item?.media_type == "pdf" && item?.thumbnail_icon && item?.thumbnail_icon !="undefined" ? item?.thumbnail_icon : require("../images/big-med-pdf.jpg").default} alt="img" /></a>
                                                    </div>}

                                                    {item?.media_type == "voice" &&
                                                        <div className="big-med-main-img big-med-audio" onClick={() => {
                                                            gotoMidAndMed(item?._id)
                                                        }}
                                                        >
                                                            <a href="#"><img src={item?.media_type == "voice" && item?.thumbnail_icon && item?.thumbnail_icon !="undefined" ? item?.thumbnail_icon : require("../images/big-med-audio.jpg").default} alt="img" />
                                                            </a>
                                                        </div>

                                                    }
                                                    <div className='outer-content'>
                                                        <div className="big-med-thumbnail" onClick={(e) =>{
                                                            window.location.href=`/profile/${item?.user?.userSlug}`;
                                                        }}>
                                                            {item && item?.user?.profilePicture !== "undefined" ? <img src={item && item?.user?.profilePicture ? item?.user?.profilePicture : require("../../src/images/update/dummy-profile-img.jpg").default} alt="img" /> : <img src={require("../../src/images/update/dummy-profile-img.jpg").default} alt="img" />}
                                                        </div>
                                                        <div className="big-med-text"  onClick={() => {
                                                        gotoMidAndMed(item?._id)
                                                    }}>
                                                            <h4>{item?.title}</h4>
                                                            <p className="channel-name person-problem">{item?.categoryName?.name}</p>
                                                            {item && item?.user?.userType == "Professional" && <p className="channel-name">{item?.user?.firstName}</p>}
                                                            {item && item?.user?.userType === "Client" ? (
                                                                <p className="channel-name">
                                                                    {item?.user?.pseudoName ? item?.user?.pseudoName : item?.user?.firstName}
                                                                </p>
                                                            ) : ""}
                                                            {item && item?.user?.userType == "Professional" ? <p className="channel-name">{item?.user?.professionalType}</p> : <p className="channel-name"></p>}
                                                            <p><span className="channel-views">{item?.views ? item?.views : 0} Views </span>  <span>|</span> <span className="channel-time"> {item?.timeAgo}</span></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )
                            })
                            :
                            <div className="profile-right-side no-data-profile no-upper-main-data mb-5">
                                <div className="no-data-box">
                                    <img src={require("../images/update/no-data.png").default} alt="img" className="mr-2" />
                                    <h3>No data found</h3>
                                </div>
                            </div>
                        }
                        {totalpage > 15 && (
                            <div className='col-md-12 text-center'>
                                <div className="pagination1">
                                    <Pagination
                                        activePage={page}
                                        itemsCountPerPage={15}
                                        totalItemsCount={totalpage}
                                        pageRangeDisplayed={5}
                                        onChange={(e) => handlePageChange(e)}
                                        hideDisabled={true}
                                        prevPageText={"Previous"}
                                        nextPageText={"Next"}
                                    />
                                </div>
                            </div>
                        )}
                    </div >
                </div>
            </div>
            <Footer />
        </>
    );
};
export default Big_med;