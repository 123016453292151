import Header from "./common/Header";
import Footer from "./common/Footer";
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { userService } from "../services";
import Swal from "sweetalert2";
import { Button, Col, Form, Row, Card, Accordion, Modal } from "react-bootstrap";
import { Country, State, City, getAllCountries } from "country-state-city";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
  geocodeByPlaceId,
} from "react-places-autocomplete";
import Loader from "./common/Loader";

const ShoppingCart = () => {
  const [productID, setProductID] = useState('')
  const [haveSubscription, setHaveSubscription] = useState('');
  const [subs, setSubs] = useState([]);
  const [save, setSave] = useState(false)
  const handleSave = () => setSave(true);
  const handleCloseSave = () => setSave(false);
  const [subIndex, setSubIndex] = useState(0)
  const [btnStatus, setBtnStatus] = useState('');
  const [showFullContent, setShowFullContent] = useState(false);
  const [priceStatus, setPriceStatus] = useState(false)
  const [addtoCartStatus, setAddtoCartStatus] = useState(false)

  const toggleContent = () => {
    setShowFullContent(!showFullContent);
  };
  const [serviceOther, setserviceOther] = useState("")
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [show, setShow] = useState(false)
  const [address, setAddress] = useState("");
  const targetRef = useRef(null);
  const [userDetail, setuserDetails] = useState({ email: "", name: "", PhoneNumber: "", companyName: "", country: "", service: "", message: "" })
  const history = useHistory()
  const [cartProducts, setCartProducts] = useState([]);
  const [similarProducts, setSimilarProducts] = useState([]);
  const [prices, setPrices] = useState([]);
  const priceRef = useRef(null)
  const [quantities, setQuantities] = useState([]);
  const [categoryId, setCategoryId] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [userId, setUserId] = useState('');
  const [loadingStates, setLoadingStates] = useState(0);
  const [cartCount, setCartCount] = useState('');
  const [shipping, setShipping] = useState('');
  const [tax, setTax] = useState('');
  const [taxVal, setTaxVal] = useState('');
  const [favCount, setFavCount] = useState('');
  const [cartItems, setCartItems] = useState([]);
  const [temp, setTemp] = useState('0')
  const [proceedStatus, setProceedStatus] = useState('');
  const [products, setProducts] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [searchText, setSearchText] = useState('');
  const cotId = "62d7761d5c91b78ea876856c";

  useEffect(() => {
    let user_id = localStorage.getItem('user_id');
    if (user_id) {
      setUserId(user_id);
      setIsLoading(true);
      getCartProducts();
      if (cotId) {
        getProducts(cotId, searchText, 0);
      }
    }
  }, []);

  useEffect(() => {
    if (subIndex == 0 && proceedStatus == 'SKIP_SUB') {
      setProceedStatus('')
      addToCart(productID)
    }
  }, [subIndex, proceedStatus])

  function getProducts(catId, searchText, page) {
    userService.getProducts(catId, searchText, page).then((response) => {
      setIsLoading(false);
      if (response.data.status == 200) {
        setProducts(response.data.data);
        setSubs(response.data.subscriptions)
        let btnStatus = cartItems.filter(cartItem => cartItem === response?.data?.data[0]?._id).length > 0;
      } else {
        setProducts([]);
        toast.error("Some Error Occur");
      }
    }).catch((error) => {
      setIsLoading(false);
      setProducts([]);
    });
  }


  function selectSub(id) {
    if (id != subIndex)
      setSubIndex(id)
    else { setSubIndex(0) }
  }

  const handleInputChange = (event) => {
    const { value } = event.target;
    setserviceOther(value)
  };

  const handleSelect = async (valueOfLocation, placeId) => {
    const results = await geocodeByAddress(valueOfLocation);
    const latLng = await getLatLng(results[0]);
    setAddress(valueOfLocation)
    const [place] = await geocodeByPlaceId(placeId);
    const { long_name: countryselect = '' } = place.address_components.find(c => c.types.includes('country')) || {};
    const { long_name: selCity = '' } = place.address_components.find(c => c.types.includes('administrative_area_level_2')) || {};
    Country.getAllCountries().forEach(item => {
      if (countryselect == item.name) {
      }
    })
  }

  function subShowModal(haveSubscription, id) {
    if (cartItems.length > 0) {
      window.location.pathname = '/shopping-cart'
      return
    }
    setHaveSubscription(haveSubscription)
    setProductID(id)

    if (haveSubscription == true) {
      setTimeout(() => {
        handleSave()
      }, 500)
    }
  }

  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    let updatedService;

    if (checked) {
      updatedService = [...userDetail.service, value];
    } else {
      updatedService = userDetail.service.filter((item) => item !== value);
    }
    setuserDetails((prevState) => ({
      ...prevState,
      service: updatedService,
    }));
  };

  const handleSubmit2 = (e) => {
    e.preventDefault();
    setuserDetails((prevState) => ({
      ...prevState,
      service: [...prevState.service, serviceOther],
    }));

    setShow(false);
    // Scroll to the element with the ID "myInput"
    const element = document.getElementById("myInput");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
    // Perform any additional actions you need with the 'service' value.
  };

  function inputChange(e) {
    let { name, value } = e.target;
    setuserDetails({ ...userDetail, [name]: value });
  }

  function handleSumit(e) {
    e.preventDefault();
    var pattern = new RegExp(/^[_a-z0-9-]+(\.[_a-z0-9-]+)*(\+[a-z0-9-]+)?@[a-z0-9-]+(\.[a-z0-9-]+)*$/i);
    if (!userDetail.name || userDetail?.name.trimStart() == "") {
      Swal.fire("Warning", "Name field can’t be empty.", "warning")
      return
    }
    else if (!userDetail.email || userDetail?.email.trimStart() == "") {
      Swal.fire("Warning", "E-mail field can’t be empty.", "warning")
      return
    }
    else if (!pattern.test(userDetail.email)) {
      Swal.fire("Warning", 'Entered E-mail address is not valid.', "warning")
      return
    }
    else if (!userDetail.PhoneNumber || userDetail?.PhoneNumber.trimStart() == "") {
      Swal.fire("Warning", 'Phone Number field can’t be empty.', "warning")
      return
    }
    else if (!userDetail.companyName || userDetail?.companyName.trimStart() == "") {
      Swal.fire("Warning", 'Company Name field can’t be empty.', "warning")
      return
    }
    else if (!userDetail?.country || userDetail?.country.trimStart() == "") {
      Swal.fire("Warning", 'country field can’t be empty.', "warning")
      return
    }
    else if (!userDetail?.service) {
      Swal.fire("Warning", 'service field can’t be empty.', "warning")
      return
    }
    else if (!userDetail?.message || userDetail?.message.trimStart() == "") {
      Swal.fire("Warning", 'message field can’t be empty.', "warning")
      return
    }
    else {
      contactus()
    }
  }
  let preview;

  function contactus() {
    const params = {
      name: userDetail.name,
      email: userDetail?.email,
      companyName: userDetail?.companyName,
      country: userDetail?.country,
      PhoneNumber: userDetail?.PhoneNumber,
      service: userDetail?.service,
      message: userDetail?.message,
      page_type: "INGREDIENTS"?.toLowerCase()
    }
    setIsLoading(true)
    userService.contactUs(params).then((res) => {
      setIsLoading(false)
      if (res?.data?.status === 200) {
        Swal.fire("Success", res?.data?.message, "success").then((res) => {
          setuserDetails({
            email: "",
            name: "",
            PhoneNumber: "",
            companyName: "",
            country: "",
            service: "",
            message: ""
          });
        })
        setuserDetails({
          email: "",
          name: "",
          PhoneNumber: "",
          companyName: "",
          country: "",
          service: "",
          message: ""
        });
      }
    })
      .catch((error) => {
        setIsLoading(false)
      })
  }

  function getCartProducts() {
    setIsLoading(true);
    userService.getCartProducts().then((response) => {
      setIsLoading(false);
      if (response?.data?.status == 200) {
        let tmpArry = [];
        setCartCount(response?.data?.cart?.lineitems?.length);
        if (response?.data?.cart?.lineitems) {
          response?.data?.cart?.lineitems?.map((product) => {
            tmpArry.push({ price: product?.qty * product?.product?.price, qty: product?.qty, subscription: product.subscription, subscription_type: product.subscription_type, discount: product?.product?.discount });
          });
        }
        priceRef.current = tmpArry
        setPrices(tmpArry);
        setCartProducts(response?.data?.cart?.lineitems);
        setSimilarProducts(response?.data?.similarProducts);
        setTax(response?.data?.tax);
        setShipping(response?.data?.shipping);
        setCartItems(response?.data?.cart?.products);
        setPriceStatus(true)
      } else {
        setCartCount('');
        toast.error("Some Error Occur");
      }
    }).catch((error) => {
      setIsLoading(false);
    });
  }

  function handleQuantity(i, type, qty, price, id) {
    var tmpArry = [...prices];
    let tmpQty
    if (type === 'INC' && qty < cartProducts[i].product.quantity) {
      tmpQty = qty + 1;
    } else if (type === 'DEC' && qty > 1) {
      tmpQty = qty - 1;
    } else {
      return
    }
    tmpArry[i] = { ...tmpArry[i],price: tmpQty * price };
    tmpArry[i].qty=tmpQty;
    setPrices(tmpArry);
    priceRef.current = tmpArry
    setLoadingStates(loadingStates + 1);
    updateQuantity(type, id)
  }

  function updateQuantity(type, id) {
    setIsLoading(true);
    let params = { type: type, lineitem: id }
    userService.updateQuantity(params).then((response) => {
      setIsLoading(false);
      if (response?.data?.status == 200) {
        if (type == 'DEL') {
          window.location.reload()
        }
      } else {
        toast.error("Some Error Occur");
      }
    }).catch((error) => {
      setIsLoading(false);
    });
  }

  function getTotalPrice() {
    let sum = 0;
    prices.map((item) => {
      let value = (item.price - (item.discount * item.price / 100))
      sum = sum + value
    });
    return sum;
  }

  function getTax() {
    return parseFloat(((getTotalPrice() - getSubDiscount()) * tax) / 100).toFixed(2);
  }

  function getSubDiscount() {
    let sum = 0;
    prices.forEach((item) => {
      let value = item.subscription ? (item.price - (item.discount * item.price / 100)) / 100 * parseFloat(item.subscription_type.save_percent) : 0
      sum = sum + value
    });
    return sum.toFixed(2);
  }

  function getGrandTotalPrice() {

    let gPrice = getTotalPrice();       //setting initial GrandTotal from getTotalPrice()

    let taxVal = parseFloat(getTax());
    let shippingVal = 0;
    //Calculation for Grand Total Price
    gPrice += taxVal;
    gPrice += shippingVal;
    gPrice -= getSubDiscount()
    return gPrice;
  }


  function handleFavourite(id, status) {
    if (userId) {
      var answer = true;
      if (status) answer = window.confirm("Are you sure want to remove it from your favourite list.");
      if (answer) {
        setIsLoading(true);
        let params = { user: userId, product: id, is_favourite: !status }
        userService.updateFavourite(params).then((response) => {
          setIsLoading(false);
          if (response.data.status == 200) {
            setFavCount(response.data.favCount);
            getCartProducts()
            setTemp(favCount)
          } else {
            toast.error("Some Error Occur");
          }
        }).catch((error) => {
          setIsLoading(false);
        });
      }
    } else {
      window.location.pathname = '/signin'
    }
  }

  function getDiscountedPrice(item) {
    return (item.price - (item.discount / 100) * item.price).toFixed(2)
  }

  function getDiscountedPriceWithSub(item) {
    let discountedPrice = (item.product.price - (item.product.discount / 100) * item.product.price).toFixed(2)
    let finalPrice = item.subscription ? discountedPrice * item.subscription_type.save_percent / 100 : 0
    return discountedPrice - finalPrice
  }

  function addToCart(id) {
    if (window.confirm("Are you sure you want to add this product?")) {
      if (userId) {
        setIsLoading(true);
        let params = { user: userId, product: id, qty: 1 }
        userService.addToCart(params).then((response) => {
          setIsLoading(false);
          if (response.data.status == 200) {
            setCartCount(response.data.cartCount);
            getCartProducts();
            setTemp(cartCount)
            toast.success("Product added to cart successfully.")
          } else {
            toast.error("Some Error Occur");
          }
        }).catch((error) => {
          setCartCount('');
          setIsLoading(false);
        })

      } else {
        window.location.pathname = './signin';
      }
    }
  }

  function goToCart() {
    window.location.href = window.location.origin + '/cart';
  }



  function handleSubscribeProduct() {
    if (cartProducts[0]?.subscription == false) {
      setSave(true)
    }
  }

  console.log("subs in shopping details", cartProducts)
  const getTotalQty = (index) => {
    return <span>{prices[index]?.qty}</span>;
  }

  return (
    <>
      {isLoading && <Loader />}
      <Header />
      <section className="shopping-cart-sec burden-disease">
        <div className="container">
          <div className="banner-text">
            <h1 className="text-center">Your Shopping Cart</h1>
          </div>
          <div className="row">
            {cartProducts && cartProducts?.length > 0 ? cartProducts.map((cartProduct, i) => {
              return (
                <aside className="col-lg-8">
                  <div className="shopping-cart-wrap shopping-cart-left">
                    <div className="row align-items-center">
                      <aside className="col-md-4">
                        <div className="product-box ">

                          <img
                            src={
                              require("../images/update/Ing-product.png").default
                            }
                            alt="img"
                          />
                        </div>
                      </aside>
                      <aside className="col-md-8">
                        <div className="product-box shopping-added-product">
                          <h6 className="replace-text">Meal Replacement</h6>
                          <h5>{cartProduct?.product?.name}</h5>
                          <p><b>Price:  {cartProduct.product?.discount > 0 && <del>${cartProduct.product.price}</del>} $ {priceStatus == true && getDiscountedPrice(cartProduct.product)}</b></p>
                          <p ><b>{cartProduct?.subscription == true && `Subscribed(-${cartProduct?.subscription_type.save_percent}%)`}</b></p>
                          <p><b>Size: {cartProduct?.size}</b></p>
                          <div className="color_row shopping-clr">
                            <div className="color_row">
                                <span
                                  style={{ backgroundColor: cartProduct?.color }}
                                >
                                </span>
                            </div>
                          </div>
                          <div class="product-count">
                            <button
                              type="button"
                              className="button-count no-active"
                              onClick={() => {
                                if (prices[i]?.qty > 1) {
                                  handleQuantity(i, 'DEC', prices[i]?.qty, cartProduct.product?.price, cartProduct._id);
                                } else {
                                  Swal.fire({
                                    title: 'Are you sure you want to delete this product from your cart?',
                                    icon: 'warning',
                                    showCancelButton: true,
                                    confirmButtonText: 'Yes',
                                    cancelButtonText: 'No',
                                  }).then((result) => {
                                    if (result.isConfirmed) {
                                      updateQuantity('DEL', cartProduct._id);
                                    } else {
                                    }
                                  });
                                }
                              }}
                            >
                              <i className="fa fa-minus" aria-hidden="true"></i>
                            </button>
                            {getTotalQty(i)}
                            <button type="button" class="button-count" onClick={() => {
                              handleQuantity(i, 'INC', prices[i]?.qty, cartProduct.product?.price, cartProduct._id)
                            }}>
                              <i class="fa fa-plus" aria-hidden="true"></i>
                            </button>
                          </div>
                          {priceStatus && <h4 className="blue-text mt-3"><b>${getTotalPrice().toFixed(2)}</b></h4>}
                        </div>
                      </aside>
                      <div className="shopping-btns">


                      </div>
                    </div>
                  </div>
                </aside>)
            }) :
              <>
                <div className="no-item-area">
                  <img src={require("../../src/images/update/no-item-cart.png").default} />
                  <p>No Items on cart.</p>
                </div>
              </>}

            {cartProducts && cartProducts?.length > 0 &&
              <aside className="col-lg-3 summry">
                <div className="card shopping-details">
                  <div className="card-body">
                    <dl className="dlist-align">
                      <dt>Total price:</dt>
                      {priceStatus && <dd className="text-right ml-3">${getTotalPrice().toFixed(2)}</dd>}
                    </dl>
                    {priceStatus && <dl className="dlist-align">
                      <dt>Discount:</dt>
                      <dd className="text-right text-success ml-3">-${getSubDiscount()}</dd>
                    </dl>}

                    <dl className="dlist-align">
                      <dt>Tax({tax}%):</dt>
                      {priceStatus && <dd className="text-right ml-3">${getTax()}</dd>}
                    </dl>
                    <dl className="dlist-align">
                      <dt>Shipping:</dt>
                      <dd className="text-right ml-3 pending-text" style={{ color: 'red' }}>{'pending'}</dd>
                    </dl>
                    <dl className="dlist-align">
                      <dt>Grand Total:</dt>
                      {priceStatus && <dd className="text-right b ml-3"><strong>${getGrandTotalPrice().toFixed(2)}</strong></dd>}
                    </dl>
                    <hr /> <a onClick={() => history.push('checout-updated')} className="btn chkoutbtn w-100 shadow">Checkout </a>
                  </div>
                </div>

              </aside>
            }
          </div>
        </div>
      </section>

      <section className="get-started-form bg_section signup_section redesign-profile">
        <div className="container">
          <h3> We’re here with the experts and personalized guidance to help you navigate the current healthcare landscape.</h3>
          <div className="get-form-wrap">
            <h2>Get Started</h2>

            <aside className="col-lg-12 pt-4">
              <div className="Signup_box text-center w-100">
                <Form className="">
                  <div className="row frist_step contact-form-area  ">
                    <aside className="col-md-4 mb-3 text-left">
                      <label>
                        Full Name<span className="mandatory"></span>
                      </label>
                      <div className="input_row">
                        <input
                          type="firstName"
                          name="name"
                          maxLength={50}
                          placeholder="Enter Full Name"
                          className="input103 w-100"
                          value={userDetail?.name}
                          onChange={(e) => inputChange(e)}

                        />
                      </div>
                    </aside>
                    <aside className="col-md-4 mb-3 text-left">
                      <label>
                        Email<span className="mandatory"></span>
                      </label>
                      <div className="input_row">
                        <input
                          type="Email"
                          name="email"
                          placeholder="Enter Email"
                          className="input103 w-100"
                          value={userDetail?.email}
                          onChange={(e) => inputChange(e)}

                        />
                      </div>
                    </aside>
                    <aside className="col-md-4 mb-3 text-left">
                      <label>
                        Phone Number<span className="mandatory"></span>
                      </label>
                      <div className="input_row">
                        <input
                          type="PhoneNumber"
                          name="PhoneNumber"
                          placeholder="Enter Phone Number"
                          className="input103 w-100"
                          value={userDetail?.PhoneNumber}
                          onChange={(e) => inputChange(e)}

                        />
                      </div>
                    </aside>
                    <aside className="col-md-4 mb-3 text-left">
                      <label>
                        Company Name<span className="mandatory"></span>
                      </label>
                      <div className="input_row">
                        <input
                          type="Text"
                          name="companyName"
                          maxLength={50}
                          placeholder="Enter Company Name"
                          className="input103 w-100"
                          value={userDetail?.companyName}
                          onChange={(e) => inputChange(e)}


                        />
                      </div>
                    </aside>


                    <aside className="col-md-4 mb-3 text-left">
                      <label>Country </label>
                      <div className="input_row input103">


                        <select
                          className="w-100 select-main"
                          name="country"
                          value={userDetail?.country}
                          onChange={(e) => inputChange(e)}
                        >
                          <option value={""}>Select Country</option>
                          {Country.getAllCountries().map((c) => {
                            return (
                              <option value={c.name}>{c.name}</option>
                            )
                          })}

                        </select>
                      </div>
                    </aside>

                    <aside className="col-md-4 mb-3 text-left">
                      <label>Service</label>
                      <>
                        <a variant="primary" onClick={handleShow}
                          className='d-block servies-text' id="myInput" ref={targetRef}
                        >
                          {userDetail?.service?.length > 0 ? "+ Update Services" : "+ Add Services"}

                        </a>
                        <div className='services-preview'>
                          {userDetail?.service && <span>{showFullContent ? userDetail?.service?.join(" , ") : userDetail?.service?.join(" , ")?.slice(0, 30)}</span>}
                          {userDetail?.service && (userDetail?.service?.join(" ")?.length > 30) ? (
                            <p onClick={toggleContent}>{showFullContent ? 'View less' : 'View more'}</p>
                          ) : (
                            ''
                          )}
                        </div>
                      </>
                    </aside>


                    <aside className="col-md-12 mb-3 text-left">
                      <div className="input_row">
                        <label>Message</label>
                        <textarea
                          className=" w-100 select-main"
                          placeholder="Enter Message"
                          name="message"
                          maxLength={500}
                          value={userDetail?.message}
                          onChange={(e) => inputChange(e)}
                        ></textarea>
                      </div>
                    </aside>
                  </div>
                </Form>
              </div>
              <div className="next_btn text-center">
                <Button
                  variant="secondary"
                  type="button"
                  className="btn w-25"
                  onClick={handleSumit}
                >
                  Submit
                </Button>
              </div>
            </aside>

          </div>
        </div>
      </section>
      <Modal show={show} onHide={handleClose} className='services-box'>
        <Modal.Header closeButton>
          <Modal.Title>Get Started, the services available </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='services-box'>
            <Accordion>
              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="0">
                    Prevention and Reversal Programs
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                  <Card.Body>
                    <div className='row'>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox1">
                          <Form.Check type="checkbox" label="Diabetes" checked={userDetail.service.includes('Diabetes')} value="Diabetes" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>

                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox2">
                          <Form.Check type="checkbox" label="High Blood Pressure" checked={userDetail.service.includes('High Blood Pressure')} value="High Blood Pressure" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox3">
                          <Form.Check type="checkbox" label="High Cholesterol" checked={userDetail.service.includes('High Cholesterol')} value="High Cholesterol" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox4">
                          <Form.Check type="checkbox" label="Obesity Prevention/Reversal Programs" checked={userDetail.service.includes('Obesity Prevention/Reversal Programs')} value="Obesity Prevention/Reversal Programs" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox5">
                          <Form.Check type="checkbox" label="Reversal Programs" checked={userDetail.service.includes('Reversal Programs')} value="Reversal Programs" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="1">
                    Health and Wellness Applications
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="1">
                  <Card.Body>
                    <div className='row'>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox6">
                          <Form.Check type="checkbox" label="Medical & Health Apps" checked={userDetail.service.includes('Medical & Health Apps')} value="Medical & Health Apps" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox7">
                          <Form.Check type="checkbox" label="Wellness Apps" checked={userDetail.service.includes('Wellness Apps')} value="Wellness Apps" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="2">
                    Workplace Wellness Initiatives
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="2">
                  <Card.Body>
                    <div className='row'>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox8">
                          <Form.Check type="checkbox" label="Corporate Wellness Programs" checked={userDetail.service.includes('Corporate Wellness Programs')} value="Corporate Wellness Programs" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox9">
                          <Form.Check type="checkbox" label="Health and Wellness Consulting" checked={userDetail.service.includes('Health and Wellness Consulting')} value="Health and Wellness Consulting" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="3">
                    Community Health Initiatives
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="3">
                  <Card.Body>
                    <div className='row'>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox10">
                          <Form.Check type="checkbox" label="Population Health Improvement Programs" checked={userDetail.service.includes('Population Health Improvement Programs')} value="Population Health Improvement Programs" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox11">
                          <Form.Check type="checkbox" label="Behavioral Health Strategic Management" checked={userDetail.service.includes('Behavioral Health Strategic Management')} value="Behavioral Health Strategic Management" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox12">
                          <Form.Check type="checkbox" label="Certified Community Behavioral Health Clinics (CCBHCs)" checked={userDetail.service.includes('Certified Community Behavioral Health Clinics (CCBHCs)')} value="Certified Community Behavioral Health Clinics (CCBHCs)" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="4">
                    Nutrition and Health Products:
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="4">
                  <Card.Body>
                    <div className='row'>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox13">
                          <Form.Check type="checkbox" label="Nutrition" checked={userDetail.service.includes('Nutrition')} value="Nutrition" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox14">
                          <Form.Check type="checkbox" label="Cancer-Fighting Meals" checked={userDetail.service.includes('Cancer-Fighting Meals')} value="Cancer-Fighting Meals" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox15">
                          <Form.Check type="checkbox" label="Meal Replacement Solutions" checked={userDetail.service.includes('Meal Replacement Solutions')} value="Meal Replacement Solutions" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox16">
                          <Form.Check type="checkbox" label="Health Supplements" checked={userDetail.service.includes('Health Supplements')} value="Health Supplements" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="5">
                    Wellness Initiatives
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="5">
                  <Card.Body>
                    <div className='row'>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox17">
                          <Form.Check type="checkbox" label="Health Wellness Programs" checked={userDetail.service.includes('Health Wellness Programs')} value="Health Wellness Programs" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox18">
                          <Form.Check type="checkbox" label="Employee Engagement " checked={userDetail.service.includes('Employee Engagement')} value="Employee Engagement" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="6">
                    Health Advocacy and Information:
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="6">
                  <Card.Body>
                    <div className='row'>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox19">
                          <Form.Check type="checkbox" label="Healthcare Speakers and Keynote Speakers" checked={userDetail.service.includes('Healthcare Speakers and Keynote Speakers')} value="Healthcare Speakers and Keynote Speakers" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox20">
                          <Form.Check type="checkbox" label="Public Health Policies for Government Agencies " checked={userDetail.service.includes('Public Health Policies for Government Agencies')} value="Public Health Policies for Government Agencies" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox21">
                          <Form.Check type="checkbox" label="Health Data Analytics (Making complex data understandable and useful)" checked={userDetail.service.includes('Health Data Analytics (Making complex data understandable and useful)')} value="Health Data Analytics (Making complex data understandable and useful)" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="7">
                    Collaboration Opportunities
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="7">
                  <Card.Body>
                    <div className='row'>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox22">
                          <Form.Check type="checkbox" label="Become a Partner" checked={userDetail.service.includes('Become a Partner')} value="Become a Partner" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="8">
                    Build Custom Medical Apps
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="8">
                  <Card.Body>
                    <div className='row'>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox23">
                          <Form.Check type="checkbox" label="Custom Medical Apps" checked={userDetail.service.includes('Custom Medical Apps')} value="Custom Medical Apps" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <label className='mt-3'><b>Other</b></label>
              <textarea
                className="form-control"
                placeholder="Enter Message"
                name="service"
                maxLength={500}
                onChange={handleInputChange}
              ></textarea>
            </Accordion>
          </div>
          {userDetail?.service?.length > 0 && <a
            type="button"
            className="join-button"
            onClick={(e) => handleSubmit2(e)}

          >
            Submit
          </a>}
        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal>

      <Modal show={save} onHide={handleCloseSave} aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "grey" }}>SUBSCRIBE & SAVE</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <section className="subscribe_section" style={{ display: btnStatus ? 'none' : 'block' }}>
            <div className="container">

              {subs.length > 0 && <div className="row">

                <div className="col-md-12 mb-2">
                  <div className="subs_firstbox subscribe_list">
                    <span>
                      <div class="form-check pl-0">
                        <input class="form-check-input" checked={subIndex == subs[0]?._id}
                          onClick={(e) => selectSub(subs[0]._id)} type="radio" name="exampleRadios4" id="exampleRadios4" />
                      </div>
                    </span>
                    <h5>EVERY 30 DAYS</h5>
                    <h4>& SAVE 10%</h4>
                  </div>
                </div>

                <div className="col-md-12 mb-2">
                  <div className="subs_secondbox subscribe_list">
                    <span>
                      <div class="form-check pl-0">
                        <input class="form-check-input" checked={subIndex == subs[1]._id}
                          onClick={(e) => selectSub(subs[1]._id)} type="radio" name="exampleRadios2" id="exampleRadios2" />
                      </div>
                    </span>
                    <h5>EVERY 45 DAYS</h5>
                    <h4>& SAVE 5%</h4>
                  </div>
                </div>

                <div className="col-md-12 mb-2">
                  <div className="subs_thirdbox subscribe_list">
                    <span>
                      <div class="form-check pl-0">
                        <input class="form-check-input" checked={subIndex == subs[2]._id}
                          onClick={(e) => selectSub(subs[2]._id)} type="radio" name="exampleRadios3" id="exampleRadios3" />
                      </div>
                    </span>
                    <h5>EVERY 60 DAYS</h5>
                    <h4>& SAVE 2%</h4>
                  </div>
                  <div className='add-skip-btn mt-3 text-center'>
                    <button className='btn add_subs_btn mr-2' onClick={() => {
                      if (subIndex != 0)
                        // addToCart(productID)
                        addToCart(products[0]._id)
                      else
                        toast.error('Please select a subscription first', {
                          toastId: 'success_draft_1',
                        })
                    }}>Add</button>
                    <button className='btn skip_subs_btn' onClick={() => {
                      setProceedStatus('SKIP_SUB')
                      setSubIndex(0);
                    }}>Skip</button>
                  </div>
                </div>

              </div>}
            </div>
          </section>

        </Modal.Body>
      </Modal>


      {/* contact us section start */}

      <Footer />
    </>
  );
};
export default ShoppingCart;
