import Header from "./common/Header";
import Footer from "./common/Footer";
import { useEffect, useRef, useState } from 'react';
import { userService } from '../services/user.services';
import { Button, Modal } from 'react-bootstrap';
import { config } from "../config/config";
import Swal from "sweetalert2";
import Loader from './common/Loader'
import { Swiper, SwiperSlide, Autoplay } from 'swiper/react';
import { appName, appUrl } from '../config/config';
import {
    EmailShareButton,
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    FacebookMessengerShareButton,
} from "react-share";
import {
    EmailIcon,
    FacebookIcon,
    LineIcon,
    LinkedinIcon,
    TwitterIcon,
    WhatsappIcon,
    FacebookMessengerIcon,
} from "react-share";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { faTruckField } from "@fortawesome/free-solid-svg-icons";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";




const Big_med_details = () => {

    const handleCloseSuccess = () => {
        setShowSuccess(false)
    }

    const [copy, setCopy] = useState(true);
    let title = `Hey! Checkout this ${appName} post`;
    const [share_url, setshare_url] = useState(null);
    const [show, setShow] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const play = useRef("")
    const [isLoading, setIsLoading] = useState(false);
    const [userDetail, setUserDetail] = useState()
    const [userid, setUserId] = useState(localStorage?.getItem("user_id") ? localStorage?.getItem("user_id") : "")
    const urlLocation = useLocation();
    const queryParams = new URLSearchParams(urlLocation.search);
    const id = queryParams.get('id')
    const medAndMePage = queryParams.get('page')
    const [onplaying, setOnPlaying] = useState(false)

    useEffect(() => {
        getMedUserDeatils(id, userid)
        let location = window.location.href
        setshare_url(location)
    }, [])

    function getMedUserDeatils(id, userid) {
        setIsLoading(true)
        userService.getmed_detail(id, userid).then((res) => {
            setUserDetail(res?.data?.getDetailAfteViews)
            setIsLoading(false)
        })
            .catch((Error) => {
                setIsLoading(false)
            })
    }
    useEffect(() => {
        if (copy) {
            const interval = setInterval(() => {
                setCopy(false);
            }, 1000);
            return () => clearInterval(interval);
        }
    }, [copy]);

    function subscribe(id) {
        const params = {
            user_id: id,
        }
        setIsLoading(true)
        userService.subscribe_user(params).then((res) => {
            setIsLoading(false)
            Swal.fire("Success", res?.data?.message, "success").then((res) => {
                window.location.reload()
            })
            getMedUserDeatils(id, userid)
        })
            .catch((error) => {
                setIsLoading(false)
            })
    }

    function postLikeAndDislike(id) {
        const params = {
            post_id: id,
        }
        setIsLoading(true)
        userService.postLike(params).then((res) => {
            setIsLoading(false)
            getMedUserDeatils(id, userid)
        })
            .catch((error) => {
                setIsLoading(false)
            })
    }

    function unsubscribe(id) {
        setIsLoading(true)
        userService.un_subscribe_user(id).then((res) => {
            setIsLoading(false)
            Swal.fire("Success", "User unsubscribed successfully.", "success").then((res) => {
                window.location.reload()
            })
            getMedUserDeatils(id, userid)
        })
            .catch((error) => {
            })
    }

    function gotoMidAndMed(itemId) {
        let pathName = window.location.pathname.split("/")
        let url = `${pathName[1]}-details?id=${itemId}`
        if (localStorage.getItem("userType") == "Professional") {
            window.location.href = `/big-med-details?id=${itemId}`
        }
        else {
            window.location.href = url;

        }
    }

    function handleContextMenu(event) {
        event.preventDefault();
    }

    const handleClick = () => {
        const encodedURL = encodeURIComponent(share_url);
        const shareLink = `https://www.instagram.com/web/?url=${encodedURL}`;

        if (navigator.share) {
            navigator.share({
                url: shareLink,
                title: 'Share to Instagram',
            });
        } else {
            navigator.clipboard.writeText(shareLink);
            alert('Link copied to clipboard. Paste it into Instagram!');
        }
    };

    return (
        <>
            {isLoading && <Loader />}
            <Header />
            <div className="big-med-details notranslate">
                <div className="container">
                    <div className="back-btn-sec mb-3">
                        <Button onClick={(e) => {
                            let pathName = window.location.pathname?.split("/")[1]
                            if (pathName == "big-me-details") {
                                window.location.href = `/big-me?page=${medAndMePage}`;
                            }
                            else if (pathName == "big-med-details") {
                                window.location.href = `/big-med?page=${medAndMePage}`;
                            }
                        }} className="btn back-btn"><i className="fa fa-angle-left mr-2"></i>
                            Back to Listing
                        </Button>


                    </div>

                    <div className="big-med-details-wrap"
                    >
                        {userDetail?.media_type == "video" &&
                            <div className="big-med-main-img big-med-main-vid details-wrap-files">
                                <video
                                    onContextMenu={handleContextMenu}
                                    controlsList="nodownload"
                                    width="100%"
                                    height="100%"
                                    ref={play}
                                    muted
                                    loop
                                    onPlay={(e) => setOnPlaying(true)}
                                    onEnded={(e) => setOnPlaying(false)}
                                    preload='auto'
                                    playsinline

                                    controls
                                    poster={userDetail?.thumbnail_icon}
                                >
                                    <source
                                        src={userDetail?.media[0]}
                                        type="video/mp4"
                                    />
                                    <source
                                        src={userDetail?.media[0]}
                                        type="video/ogg"
                                    />
                                    <source
                                        src={userDetail?.media[0]}
                                        type="video/webm"
                                    />
                                </video>
                                {
                                    !onplaying &&

                                    <img className="video-btn" onClick={(e) => play.current.play()} src={require("../images/video-play.svg").default} alt="img" />}
                            </div>}


                        {userDetail?.media_type == "pdf" &&
                            <div className="big-med-main-img big-med-main-pdf details-wrap-files">
                                <a href={config.imageUrl + userDetail?.media[0]} target='_blank'><img src={userDetail?.media_type == "pdf" && userDetail?.thumbnail_icon && userDetail?.thumbnail_icon != "undefined" ? userDetail?.thumbnail_icon : require("../images/big-med-pdf.jpg").default} alt="img" /></a>
                            </div>}
                        {userDetail?.media_type == "voice" &&
                            <div className="big-med-main-img big-med-main-audio details-wrap-files">


                                <a href="#"><img src={userDetail?.media_type == "voice" && userDetail?.thumbnail_icon && userDetail?.thumbnail_icon != "undefined" ? userDetail?.thumbnail_icon : require("../images/update/audio-details.png").default} alt="img" /></a>
                                <div class="player">
                                    <audio controls autoplay>
                                        <source src={userDetail?.media[0]} type="audio/ogg" />
                                        <source src={userDetail?.media[0]} type="audio/mpeg" />
                                    </audio>

                                </div>
                            </div>}
                        {userDetail?.media_type == "image" &&
                            <div className="big-med-main-img big-med-slides detail-main-image" >
                                {userDetail?.media?.length > 1 ? (
                                    <Swiper
                                        spaceBetween={50}
                                        slidesPerView={1}
                                        speed={700}
                                        loop={true}
                                        pagination={{ clickable: true }}
                                    >
                                        {userDetail?.media?.map((image, key) => (
                                            <SwiperSlide key={key}>
                                                <a href="#">
                                                    <img className="" src={image} alt="img" />
                                                </a>
                                            </SwiperSlide>
                                        ))}
                                    </Swiper>
                                ) : (
                                    <a href="#">
                                        <img className="" src={userDetail?.media[0]} alt="img" />
                                    </a>
                                )}
                            </div>}


                    </div>
                    <div className="details-nor-sec">
                        <div className="big-med-details-text">
                            <div className="video-like">
                                <img onClick={() => {
                                    if (localStorage.getItem("token")) {
                                        postLikeAndDislike(userDetail?._id)
                                    } else {
                                        Swal.fire("Warning", "To perform this action Login is required.", "warning").then((res) => {
                                            window.location.href = "/signin";
                                            let redirectUri = window.location.pathname + window.location.search;
                                            localStorage.setItem("redirectUri", redirectUri);
                                        })

                                    }
                                }} src={require(userDetail?.isLikedByMe == false ?"../images/update/like.svg" :"../images/update/chat-icon-fill.svg").default} alt="img" />
                                <p> {userDetail?.likeCount} likes</p>
                            </div>
                            <div className="big-med-text big-med-text-main mb-3 pt-2">


                                <div className="new-txt-wraps like-left-area">


                                    <h4>{userDetail?.title}</h4>
                                    <p><span className="channel-views">{userDetail?.views ? userDetail?.views : 0} Views </span>  <span>|</span> <span className="channel-time"> {userDetail?.timeAgo}</span></p>
                                </div>

                                <div className="big-txt-btn">

                                    <button className="btn subscribe mr-2" onClick={(e) => setShowSuccess(true)} >
                                        <i class="fa fa-share-alt mr-1" aria-hidden="true"></i> Share
                                    </button>


                                    {localStorage.getItem("user_id") !== userDetail?.user?._id &&
                                        <>
                                            {userDetail?.subscribe_status && userDetail?.subscribe_status > 0 ?
                                                <button className="btn subscribe" onClick={(e) => {
                                                    let confirmBox = Swal.fire({
                                                        title: "Are you sure?",
                                                        text: "Are you sure you want to unsubscribe this user?",
                                                        icon: "warning",
                                                        showCancelButton: true,
                                                        confirmButtonText: "Ok",
                                                        cancelButtonText: "Cancel",
                                                    }).then((result) => {
                                                        if (result.isConfirmed) {
                                                            if (localStorage.getItem("token")) {
                                                                unsubscribe(userDetail?.user?._id);
                                                            } else {
                                                                Swal.fire("Warning", "To perform this action, login is required.", "warning").then((res) => {
                                                                    window.location.href = "/signin";
                                                                });
                                                            }
                                                        }
                                                    });
                                                }}>
                                                    Subscribed
                                                </button>
                                                :
                                                <button className="btn subscribe"
                                                    onClick={() => {
                                                        if (localStorage.getItem("token")) {
                                                            subscribe(userDetail?.user?._id)
                                                        } else {
                                                            Swal.fire("Warning", "To perform this action Login is required.", "warning").then((res) => {
                                                                window.location.href = "/signin";
                                                                let redirectUri = window.location.pathname + window.location.search;
                                                                localStorage.setItem("redirectUri", redirectUri);
                                                            })

                                                        }
                                                    }}
                                                >Subscribe</button>}
                                        </>}
                                </div>




                            </div>
                            <div className="big-med-icon-text d-flex med-detail">
                                <div className="big-med-thumbnail" onClick={(e) =>
                                    window.location.href = `/profile/${userDetail?.user?.userSlug}`}>
                                    {userDetail?.user && userDetail?.user?.profilePicture !== "undefined" ? <img src={userDetail && userDetail?.user?.profilePicture ? userDetail?.user?.profilePicture : require("../../src/images/update/dummy-profile-img.jpg").default} alt="img" /> : <img src={require("../../src/images/update/dummy-profile-img.jpg").default} alt="img" />}
                                </div>
                                <div className="big-med-text mr-4">
                                    <div className="big-txt-in">
                                        <p className="channel-name">{userDetail?.user?.pseudoName ? userDetail?.user?.pseudoName : userDetail?.user?.name}</p>
                                        {userDetail && userDetail?.user?.userType == "Professional" ? <p className="channel-name">{userDetail?.user?.professionalType}</p> : <p className="channel-name"></p>}
                                        {userDetail && userDetail?.user?.userType == "Professional" ? <p className="channel-name">{userDetail?.user?.acceptPatients}</p> : <p className="channel-name"></p>}
                                    </div>
                                    <div className="btn-subs">

                                    </div>
                                </div>
                                <div className="details-cetagory d-flex"><span>Category: <b>{userDetail?.category?.name}</b></span><span className="line-none">|</span><span>Severity Level: <b>{userDetail?.severity_level}</b></span><span className="line-none">|</span>{userDetail?.user?.userType == "Professional" && <span>Accepting New Patient: <b>{userDetail?.looking_provider == false ? "No" : "Yes"}</b></span>}{userDetail?.user?.userType == "Client" && <span>Looking for a healthcare provider: <b>{userDetail?.healthcare_provider == false ? "No" : "Yes"}</b></span>}{userDetail?.user?.userType == "Professional" && <span>Hospital/Clinic Name: <b>{userDetail?.HospitalName ? userDetail?.HospitalName : "N/A"}</b></span>}</div>
                            </div>
                        </div>
                    </div>
                    <div className="description mt-3 mb-5">
                        <p>{userDetail?.description}</p>
                    </div>
                </div>

            </div>
            <Modal show={showSuccess} onHide={handleCloseSuccess} centered>
                <Modal.Header closeButton>
                    <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body className="pt-0">
                    <div className="email-verfication text-center">
                        <h3 className="mt-4 mb-3 black-text">Share Details </h3>
                        <p className="black-text">
                            <span>Would you like to share it with your friends/communities?</span>
                        </p>
                        <div className="copy-profile d-flex mb-4">
                            {copy ? (
                                <>
                                    <span style={{ fontSize: "12px", color: "#ff7750" }}>
                                        Link Copied
                                    </span>
                                    <br></br>
                                </>
                            ) : (
                                <>
                                    <span style={{ fontSize: "10px" }}></span>
                                    <br></br>
                                </>
                            )}
                            <input
                                type="text"
                                value={share_url}
                                disabled={true}
                                style={{ fontSize: "12px" }}
                            />
                            <CopyToClipboard text={share_url} onCopy={() => setCopy(true)}>
                                <i
                                    className="fa fa-clipboard Demo__some-network__share-button"
                                    title="Copy to clipboard"
                                    aria-hidden="true"
                                    style={{ fontSize: "19px", cursor: "pointer" }}
                                ></i>
                            </CopyToClipboard>
                        </div>
                        <div className="congrats-social-icons mb-2">
                          
                            <FacebookShareButton
                                url={share_url}
                                quote={title}
                                className="Demo__some-network__share-button"
                            >
                                <FacebookIcon size={32} round />
                            </FacebookShareButton>

                            <EmailShareButton
                                url={`${share_url}`} // Specify the URL you want to share
                                subject={`${title}`}
                                body={`${share_url}`}
                                separator=" - "
                                className="Demo__some-network__share-button"
                            >
                                <EmailIcon size={32} round />
                            </EmailShareButton>
                            <TwitterShareButton
                                url={share_url}
                                title={title}
                                className="Demo__some-network__share-button"
                            >
                                <TwitterIcon size={32} round />
                            </TwitterShareButton>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <Footer />
        </>
    );
};
export default Big_med_details;
