import React, { useState, useEffect, useRef } from "react";
import { Button, Form, Modal, OverlayTrigger } from "react-bootstrap";
import { toast } from "react-toastify";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { userService } from "../../services";
import Loader from "./Loader";
import MaskedInput from "react-text-mask";
import csc from "country-state-city";
import { Country, State, City, getAllCountries } from "country-state-city";
import Header from "./Header";
import Footer from "./Footer";
import { useHistory } from "react-router";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
  geocodeByPlaceId,
} from "react-places-autocomplete";
import Swal from "sweetalert2";
import { config } from "../../config/config";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Stack, Tooltip } from "@mui/material";
import socket, { QB, APPLICATION_ID, AUTH_KEY, AUTH_SECRET, ACCOUNT_KEY } from "../../config/socket";
import { uploadFileToS3 } from "../../config/AwsConfig";

const Edit_profile = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    window.location.href = "/signin";
    localStorage.clear()
  }
  const handleShow = () => setShow(true);
  const [address, setAddress] = useState("");
  const [currentStep, setCurrentStep] = useState(1);
  const [showHide, setShowHide] = useState(false);
  const [acceptPrivacyTerms, setAcceptPrivacyTerms] = useState(false);
  const [isFormValidate, setIsFormValidate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [signUpType, setSignUpType] = useState("Standard");
  const [fbId, setFbId] = useState("");
  const [isEmailAlreadyExist, setIsEmailAlreadyExist] = useState(false);
  const [country, setCountry] = useState("-1");
  const [selCountry, setSelCountry] = useState("");
  const [userDetail, setUserDetails] = useState({
    "firstName": "", "experience": "", "Specialty": "", "linkedInUrl": "", "websiteUrl": "",
    "profilepic": "", "PhoneNumber": "", "Docs": "", "Docs2": "", "language": "", "city": "", "ZIPCode": "", "country": "", "briefBio": "", "psuedoCode": "", "age": "", "gender": "", "ReceiveHeath": false, "termsAndConditions": "", "acceptPolity": "false", "acceptnewPatient": true
  })
  const [profilePicURL, setProfilePicURL] = useState()
  const KeyDowns2 = ["`", "~", "!", "@", "#", "$", "^", "&", "*", "(", ")", "_", "=", "+", "{", "}", "[", "]", "'", "|", ";", ":", '"', "?", "<", ">", ".", "\\"]

  const KeyDowns = ["`", "~", "!", "@", "#", "$", "^", "&", "*", "(", ")", "_", "-", "=", "+", "{", "}", "[", "]", "'", "|", ";", ":", '"', "?", "/", "<", ">", ".", "\\", "1", "2", "3", "4", "5", "6", "7", "8", "9", "0"]
  const KeyDown = [" ", "`", "~", "!", "@", "#", "$", "^", "&", "*", "(", ")", "_", "-", "=", "+", "{", "}", "[", "]", "'", "|", ";", ":", '"', "?", "/", ",", "<", ">", "\\", "A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z", "a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z"]
  const KeyDownPhone = [" ", "`", "~", "!", "@", "#", "$", "^", "&", "*", "(", ")", "_", "=", "{", "}", "[", "]", "'", "|", ";", ":", '"', "?", "/", ",", "<", ">", "\\", "A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z", "a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z"]

  function inputChange(e) {
    let { name, value } = e.target;
    setUserDetails({ ...userDetail, [name]: value });
  }
  const [userType, setUserType] = useState()

  useEffect(() => {
    getProfileList()
  }, [])
  const [document, setDocument] = useState("")
  const [lisance, setLisance] = useState("")

  function getProfileList() {
    let user_id = localStorage.getItem('user_id');
    let token =localStorage.getItem("token")
    let params = { userId: user_id, token:token }
    // let params = { userId: user_id }
    userService.getProfile(params).then(res => {
      if (res.data.status == 200) {
        let userData = res.data.data;
        localStorage.setItem("qb_user_id", userData?.qb_user_id)
        setUserType(userData?.userType)
        setDocument(userData?.document)
        setLisance(userData?.license)
        if (userData?.profilePicture != null && userData?.profilePicture != "" && userData?.profilePicture !== "undefined") {
          setProfilePicURL(userData?.profilePicture);
        }
        setUserDetails(prevUserDetails => ({
          ...prevUserDetails,
          firstName: userData?.firstName,
          experience: userData?.experience,
          Specialty: userData?.speciality,
          PhoneNumber: userData?.mobileNumber,
          language: userData?.languages,
          city: userData?.city,
          ZIPCode: userData?.zipCode,
          country: userData?.country,
          briefBio: userData?.bio,
          psuedoCode: userData?.pseudoName && userData?.pseudoName != 'undefined' ? userData?.pseudoName : '',
          age: userData?.age,
          gender: userData?.gender,
          ReceiveHeath: userData?.recievePromotion,
          termsAndConditions: userData?.acceptTermsCondition,
          acceptPolity: userData?.acceptPolicy,
          acceptnewPatient: userData?.acceptPatients,
          linkedInUrl: userData?.linkedInUrl,
          websiteUrl: userData?.websiteUrl
        }));
      }
    }).catch((error) => {
    });
  }

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      window.location.href = "/signin";
    }
  }, [])


  function handleSubmit(e) {
    const linkedInUrlPattern = /^https:\/\/(?:www\.|in\.)?linkedin\.com\/?$/;
    const urlPattern = /^(ftp|http|https):\/\/[^ "]+$/;
    e.preventDefault();

    if (!userDetail?.firstName || userDetail?.firstName?.trim() === "") {
      Swal.fire("Warning", "Please enter Full Name.", "warning");
      return
    }
    else if ((!userDetail?.PhoneNumber || userDetail?.PhoneNumber?.trim() === "") && localStorage.getItem("userType") == "Professional") {
      Swal.fire("Warning", "Please enter Phone Number.", "warning");
      return
    }
    else if ((!userDetail?.Specialty || userDetail?.Specialty?.trim() === "") && localStorage.getItem("userType") == "Professional") {
      Swal.fire("Warning", "Please enter Specialty.", "warning");
      return
    }
    else if ((!userDetail?.experience || userDetail?.experience?.trim() === "") && localStorage.getItem("userType") == "Professional") {
      Swal.fire("Warning", "Please enter experience.", "warning");
      return
    }

    else if (!userDetail?.language || userDetail?.language?.trim() === "") {
      Swal.fire("Warning", "Please enter language.", "warning");
      return
    }

    else if (!userDetail?.city || userDetail?.city?.trim() === "") {
      Swal.fire("Warning", "Please enter city.", "warning");
      return
    }

    else if ((!userDetail?.ZIPCode || userDetail?.ZIPCode?.trim() === "")) {
      Swal.fire("Warning", "Please enter zip code.", "warning");
      return
    }
    else if (!userDetail?.country || userDetail?.country?.trim() === "") {
      Swal.fire("Warning", "Please enter country.", "warning");
      return
    }

    else if (userDetail?.websiteUrl && userDetail?.websiteUrl !== "null" && !urlPattern.test(userDetail?.websiteUrl)) {
      Swal.fire("Warning", "Please enter valid Website url.", "warning");
      return
    }
    else if (userDetail?.linkedInUrl && userDetail?.linkedInUrl !== "null" && !urlPattern.test(userDetail?.linkedInUrl)) {
      Swal.fire("Warning", "Please enter a valid LinkedIn URL.", "warning");
      return;
    }

    else if ((!userDetail?.briefBio || userDetail?.briefBio?.trim() === "") && localStorage.getItem("userType") == "Professional") {
      Swal.fire("Warning", "Enter Short Bio.", "warning");
      return
    }

    if ((!userDetail?.age || userDetail?.age === "") && localStorage.getItem("userType") == "Client") {
      Swal.fire("Warning", "Please enter age.", "warning");
      return
    }

    if ((!userDetail?.gender || userDetail?.gender === "") && localStorage.getItem("userType") == "Client") {
      Swal.fire("Warning", "Please enter gender.", "warning");
      return
    }

    else {
      createProfile()
    }
  }

  function handleProfilePicData(e) {
    const imageFile = e.target.files[0];

    if (imageFile) {
      if (imageFile.type.startsWith('image/')) {
        setUserDetails({ ...userDetail, profilepic: imageFile });
        setProfilePicURL(URL.createObjectURL(imageFile));
      } else {
        Swal.fire("Warning", 'Please select a valid image file.', "warning");
      }
    }
  }


  function range(start, end) {
    return Array(end - start + 1)
      .fill()
      .map((_, idx) => start + idx);
  }

  async function createProfile() {
    let profileImageUrl = '';
    if (!(profileImageRef.current) && userDetail?.profilepic && userDetail?.profilepic) {

      let profileImageData = await uploadFileToS3(userDetail?.profilepic, "profile");
      profileImageUrl = profileImageData?.Location;

    }
    var formData = new FormData();
    if (userType == "Professional") {
      formData.append("speciality", userDetail.Specialty);
      formData.append("mobileNumber", userDetail.PhoneNumber);
      formData.append("languages", userDetail.language);
      formData.append("bio", userDetail?.briefBio);
      formData.append("zipCode", userDetail.ZIPCode);
      formData.append("document", userDetail?.Docs);
      formData.append("license", userDetail?.Docs2);
      formData.append("experience", userDetail.experience);
      formData.append("accetpatients", userDetail.acceptnewPatient);
      formData.append("acceptpolicy", userDetail.acceptPolity);
      formData.append("linkedInUrl", userDetail.linkedInUrl);
      formData.append("websiteUrl", userDetail.websiteUrl);
    }
    if (userType == "Client") {
      formData.append("pseudoName", userDetail.psuedoCode);
      formData.append("gender", userDetail.gender);
      formData.append("age", userDetail.age);
      formData.append("recievePromotion", userDetail.ReceiveHeath ? userDetail.ReceiveHeath : "");
      formData.append("termsAndConditions", userDetail.termsAndConditions);
    }
    formData.append("name", userDetail.firstName);
    formData.append("country", userDetail.country);
    formData.append("city", userDetail.city);
    formData.append("profilePicture", profileImageUrl);
    setIsLoading(true)
    userService.create_profile(formData).then((res) => {
      if (res?.data?.status === 200) {
        var email = res?.data?.data[0]?.email;
        var password = 'big4healthapp';
        var params = { login: email, password: password };
        console.log("params-----", params)
        QB.createSession(params, async function (error, result) {
          await QB.login(params, function (error, result) {
            console.log("result----", result)
            console.log("error----", error)
          });
        });

        if (res?.data?.data[0]?.userType == "Professional" && res?.data?.data[0]?.admin_status != "Approved") {
          setShow(true)
          setIsLoading(false)
        }
        else {
          Swal.fire("Success", "Profile Updated Successfully", "success").then((res) => {
            window.location.href = "/profilenew";
          })

        }
        setIsLoading(false)

      }
    })
      .catch((error) => {
        setIsLoading(false)
      })
  }


  const handleSelect = async (valueOfLocation, placeId) => {
    const results = await geocodeByAddress(valueOfLocation);
    const latLng = await getLatLng(results[0]);
    setAddress(valueOfLocation)
    const [place] = await geocodeByPlaceId(placeId);
    const { long_name: countryselect = '' } = place.address_components.find(c => c.types.includes('country')) || {};
    const { long_name: selCity = '' } = place.address_components.find(c => c.types.includes('administrative_area_level_2')) || {};
    Country.getAllCountries().forEach(item => {
      if (countryselect == item.name) {
      }
    })
  }


  function readURL(input) {
    if (input.files && input.files[0]) {
      var filetype = checkfile(input.files[0]);
      if (filetype === true) {
        setUserDetails({ ...userDetail, profilepic: input.files[0] });
        var reader = new FileReader();
        reader.onload = function (e) {
        };
        reader.readAsDataURL(input.files[0]);
      }
    }
  }

  function checkfile(file_img) {
    var get_ext = file_img.name.split(".");

    var exts = ["png", "jpg", "jpeg", "gif"];
    get_ext = get_ext.reverse();

    var a = exts.indexOf(get_ext[0].toLowerCase());

    if (a > -1) {
      return true;
    } else {
      alert("Please select a valid image file");
      return false;
    }
  }

  function validateSize(input) {
    const fileSize = input.size / 1024 / 1024; // in MiB
    if (fileSize > 50) {
      alert("File size exceeds 50 MB");
      return false;
    } else {
      return true;
    }
  }


  const handlePaste = (e) => {
    e.preventDefault(); // Prevent default paste behavior
  };


  const inputChangefile = (e) => {

    const { name, files } = e.target;
    if (files && files.length > 0) {
      setUserDetails({
        ...userDetail,
        [name]: files[0],
      });
    }
  };

  let profileImageRef = useRef(false)

  const handleCancelUpload = () => {
    profileImageRef.current = true
    setProfilePicURL(null);
    const fileInput = document?.getElementById('fileInput');
    if (fileInput) {
      fileInput.value = '';
    }
    setUserDetails({ ...userDetail, profilepic: "" });
  };

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" className="bg-dark  text-light p-2 border-radius-2"{...props}>
      <p>{userDetail?.profilepic?.name}</p>
    </Tooltip>
  );








  return (
    <>
      <Header />
      {isLoading && <Loader />}

      <section className="bg_section signup_section redesign-profile">
        <div className="container-fluid">
          <div className="row">
            <aside className="col-md-12">
              <div className="Signup_box">
                <h4 className="mb-4">EDIT PROFILE</h4>
                <div className="row border-1">
                  <aside className="col-lg-12 pt-4">
                    <div className="Signup_box text-center w-100">
                      <div className="camera_box mt-3" style={{ position: "relative" }}>
                        {!profilePicURL ? (
                          <label htmlFor="fileInput" className="camera_icon">
                            <FontAwesomeIcon icon={faCamera} size="2x" />
                          </label>
                        ) : (
                          <div>
                            <OverlayTrigger
                              placement="top"
                              delay={{ show: 250, hide: 400 }}
                              overlay={renderTooltip}
                            >
                              <img src={profilePicURL} alt="Profile Preview" />
                            </OverlayTrigger>
                            <a className="cancel" onClick={() => handleCancelUpload()}>
                              <FontAwesomeIcon icon={faTimes} />
                            </a>
                          </div>
                        )}
                      </div>
                      <input
                        type="file"
                        accept="image/*"
                        onChange={(e) => handleProfilePicData(e)}
                        onClick={(e) => (e.target.value = null)}
                        id="fileInput"
                      />
                      <a className="upload" onClick={() => document.getElementById('fileInput').click()}>
                        Upload Picture
                      </a>
                      <p className="disclaimer">
                        <b>*Disclaimer:</b> To keep your identity private, please use an avatar image.
                      </p>
                    </div>
                  </aside>
                  <aside className="col-lg-12 pt-4">
                    <div className="Signup_box text-center w-100">
                      <form className="">
                        <div className="row frist_step  contact-form-area">
                          <aside className="col-md-12 col-lg-6 col-xl-3 col-12 mb-3 text-left">
                            <label>
                              Full Name<span className="blue-asterisk">*</span>
                            </label>
                            <div className="input_row">
                              <input
                                type="firstName"
                                name="firstName"
                                placeholder="Enter Full Name"
                                className="input103 w-100"
                                value={userDetail?.firstName}
                                onChange={(e) => inputChange(e)}
                                maxLength={50}
                                onKeyDown={(e) => KeyDowns.includes(e.key) && e.preventDefault()}


                              />
                            </div>
                          </aside>
                          {localStorage.getItem("userType") == "Professional" && <aside className="col-md-12 col-lg-6 col-xl-3 col-12 mb-3 text-left">
                            <label>
                              Phone Number<span className="blue-asterisk">*</span>
                            </label>
                            <div className="input_row">
                              <input
                                type="PhoneNumber"
                                name="PhoneNumber"
                                placeholder="Enter Phone Number"
                                className="input103 w-100"
                                value={userDetail?.PhoneNumber}
                                onKeyDown={(e) => KeyDownPhone.includes(e.key) && e.preventDefault()}
                                maxLength={16}
                                onChange={(e) => inputChange(e)}

                              />
                            </div>
                          </aside>}
                          {localStorage && localStorage?.getItem("userType") == "Professional" && <aside className="col-md-12 col-lg-6 col-xl-3 col-12 mb-3 text-left">
                            <label>
                              Specialty<span className="blue-asterisk">*</span>
                            </label>
                            <div className="input_row">
                              <input
                                type="Specialty"
                                name="Specialty"
                                placeholder="Enter Specialty"
                                className="input103 w-100"
                                value={userDetail?.Specialty}
                                maxLength={50}
                                onKeyDown={(e) => KeyDowns.includes(e.key) && e.preventDefault()}
                                onChange={(e) => inputChange(e)}

                              />
                            </div>
                          </aside>}
                          {localStorage && localStorage?.getItem("userType") == "Client" &&
                            <aside className="col-md-12 col-lg-6 col-xl-3 col-12 mb-3 text-left">
                              <label>
                                Pseudo Name <a href="#" className="tooltip"><i class="fa fa-info-circle" aria-hidden="true"></i> <span class="tooltiptext">Your profile will only display the pseudo name, and no other personal information will be visible to other users.</span></a>
                                <span className="optional">(Optional)</span>
                              </label>
                              <div className="input_row">
                                <input
                                  type="psuedoCode"
                                  name="psuedoCode"
                                  placeholder="Enter Pseudo Name"
                                  className="input103 w-100"
                                  value={userDetail?.psuedoCode}
                                  maxLength={25}
                                  onChange={(e) => inputChange(e)}

                                />
                              </div>
                            </aside>}
                          {localStorage && localStorage?.getItem("userType") == "Professional" &&
                            <aside className="col-md-12 col-lg-6 col-xl-3 col-12 mb-3 text-left">
                              <label>Years of Experience<span className="blue-asterisk">*</span></label>
                              <div className="input_row">
                                <input
                                  type="number"
                                  name="experience"
                                  placeholder="Enter Years of Experience"
                                  className="input103 w-100"
                                  min={0}
                                  value={userDetail?.experience}
                                  maxLength={2}
                                  onKeyDown={(e) => KeyDown.includes(e.key) && e.preventDefault()}
                                  onChange={(e) => inputChange(e)}
                                  onPaste={handlePaste}
                                />
                              </div>
                            </aside>}
                          {localStorage && localStorage?.getItem("userType") == "Client" &&
                            <aside className="col-md-12 col-lg-6 col-xl-4 col-12 mb-3 text-left">
                              <label>
                                Age
                                <span className="optional"></span>
                              </label>
                              <div className="input_row">

                                <Form>
                                  <Form.Group
                                    controlId="exampleForm.ControlSelect1"
                                    className="input103"
                                  >
                                    <Form.Control
                                      as="select"
                                      name="age"
                                      value={userDetail?.age}
                                      onChange={(e) => inputChange(e)}
                                    >
                                      <option default className="dflt">
                                        Select Age
                                      </option>
                                      {range(10, 100).map((val) => {
                                        return <option value={val}>{val}</option>;
                                      })}
                                    </Form.Control>
                                  </Form.Group>
                                </Form>
                              </div>
                            </aside>}
                          {localStorage && localStorage?.getItem("userType") == "Client" &&
                            <aside className="col-md-12 col-lg-6 col-xl-3 col-12 mb-3 text-left">
                              <label>
                                Gender<span className="optional"></span>
                              </label>
                              <div className="input_row">

                                <Form>
                                  <Form.Group
                                    controlId="exampleForm.ControlSelect1"
                                    className="input103"
                                  >
                                    <Form.Control
                                      as="select"
                                      name="gender"
                                      value={userDetail?.gender}
                                      onChange={(e) => inputChange(e)}
                                    >
                                      <option value="">Select Gender</option>
                                      <option value="male">Male</option>
                                      <option value="female">Female</option>
                                      <option value="other">Other</option>
                                    </Form.Control>
                                  </Form.Group>
                                </Form>
                              </div>
                            </aside>}
                          <aside className="col-md-12 col-lg-6 col-xl-3 col-12 mb-3 text-left">
                            <label>Languages<span className="blue-asterisk">*</span></label>
                            <div className="input_row">
                              <input
                                type="Language"
                                name="language"
                                placeholder="Enter Your Preferred Language"
                                className="input103 w-100"
                                value={userDetail?.language}
                                onChange={(e) => inputChange(e)}
                                maxLength={50}
                                onKeyDown={(e) => KeyDowns.includes(e.key) && e.preventDefault()}

                              />
                            </div>
                          </aside>
                          <aside className="col-md-12 col-lg-6 col-xl-3 col-12 mb-3 text-left">
                            <label>
                              City<span className="blue-asterisk">*</span>
                            </label>
                            <div className="input_row">
                              <input
                                type="city"
                                name="city"
                                placeholder="Enter City"
                                className="input103 w-100"
                                value={userDetail.city}
                                onKeyDown={(e) => KeyDowns.includes(e.key) && e.preventDefault()}
                                onChange={(e) => inputChange(e)}
                                maxLength={30}
                              />
                            </div>
                          </aside>
                          <aside className="col-md-12 col-lg-6 col-xl-3 col mb-3 text-left">
                            <label>
                              ZIP Code
                              <span className="blue-asterisk">*</span>
                            </label>
                            <div className="input_row">
                              <input
                                type=" ZIPCode"
                                name="ZIPCode"
                                placeholder="Enter Zip Code"
                                className="input103 w-100"
                                value={userDetail.ZIPCode}
                                onKeyDown={(e) => KeyDowns2.includes(e.key) && e.preventDefault()}
                                maxLength={10}
                                onChange={(e) => inputChange(e)}

                              />
                            </div>
                          </aside>
                        
                          <aside className="col-md-12 col-lg-6 col-xl-3 col-12 mb-3 text-left">
                            <label>Country<span className="optional"></span>
                              <span className="blue-asterisk">*</span></label>
                            <div className="input_row input103">


                              <select
                                className="w-100 select-main"
                                value={userDetail.country}
                                name="country"
                                onChange={(e) => inputChange(e)}
                              >
                                <option value={""}>Select Country</option>
                                {Country.getAllCountries().map((c) => {
                                  return (
                                    <option value={c.name}>{c.name}</option>
                                  )
                                })}

                              </select>
                            </div>
                          </aside>

                          {localStorage && localStorage?.getItem("userType") == "Professional" && <aside className="col-md-12 col-lg-6 col-xl-3 col-12 mb-3 text-left">
                            <label>Website URL</label>
                            <div className="input_row">
                              <input
                                type="text"
                                name="websiteUrl"
                                placeholder="https://www.example.com"
                                className="input103 w-100"
                                value={userDetail?.websiteUrl && userDetail?.websiteUrl !== "null" ? userDetail?.websiteUrl : ""}
                                onChange={(e) => inputChange(e)}
                              />
                            </div>
                          </aside>}

                          {localStorage && localStorage?.getItem("userType") === "Professional" &&
                            <aside className="col-md-12 col-lg-6 col-xl-3 col-12 mb-3 text-left">
                              <label>LinkedIn URL</label>
                              <div className="input_row">
                                <input
                                  type="text"
                                  name="linkedInUrl"
                                  placeholder="https://www.linkedin.com/in/example"
                                  className="input103 w-100"
                                  value={userDetail?.linkedInUrl && userDetail?.linkedInUrl !== "null" ? userDetail?.linkedInUrl : ""}
                                  onChange={(e) => inputChange(e)}
                                />
                              </div>
                            </aside>
                          }




                          {localStorage && localStorage?.getItem("userType") == "Professional" &&
                            <aside className="col-md-6 mb-3 text-left">
                              <div className="input_row">
                                <label>Brief Bio<span className="blue-asterisk">*</span></label>
                                <textarea
                                  className="w-100 select-main text-area-box"
                                  placeholder="Enter Short Bio"
                                  name="briefBio"
                                  maxLength={500}
                                  value={userDetail?.briefBio}
                                  onChange={(e) => inputChange(e)}
                                ></textarea>
                              </div>
                            </aside>
                          }

                        </div>
                      </form>
                    </div>
                    <div className="next_btn mt-2">
                      <Button
                        variant="secondary"
                        type="button"
                        className="btn w-25"
                        onClick={handleSubmit}
                      >
                        Update
                      </Button>
                    </div>
                  </aside>
                </div>
              </div>
            </aside>
          </div>
        </div>
      </section>

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="email-verfication text-center">
            <img
              src={require("../../images/update/check.png").default}
              alt="img"
            />
            <h3 className="mt-5">Successful</h3>
            <p>
              Account created successfully and submitted for admin approval. You
              will be notified by email once your account is verified.
            </p>
          </div>
        </Modal.Body>
      </Modal>
      <Footer />
    </>
  );
};

export default Edit_profile; 